
import Vue from "vue";
import { AlertModal } from "@/types";

export default Vue.extend({
  name: "DownloadingModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          html: "",
        } as any;
      },
    },
  },
  data: () => ({
    visible: true,
    keyEvent: null as any,
  }),
  watch: {
    "properties.visible"(visible) {
      this.visible = visible;
    },
  },
  mounted() {
    // 페이지 로딩 후 호출
    const startDate = new Date();
    const cancel = this.cancel;
    const confirm = this.confirm;
    this.keyEvent = (e: any) => {
      const curDate = new Date();
      const diffTimeMillis = curDate.getTime() - startDate.getTime();
      if (diffTimeMillis < 500) {
        return;
      }
      const code = e.code;
      if (code === "Escape") {
        cancel();
      }
    };
    window.addEventListener("keydown", this.keyEvent);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyEvent);
  },
  methods: {
    confirm() {
      //console.log('confirm');
      const properties = this.properties as AlertModal;
      properties.visible = false;

      if (properties.promise != null) {
        properties.promise.resolve("confirm");
      }
    },

    cancel() {
      //console.log('cancel');
      const properties = this.properties as AlertModal;
      properties.visible = false;
      if (properties.promise != null) {
        properties.promise.resolve("cancel");
      }
    },
  },
});
