
import mixins from "vue-typed-mixins";
import EstimateService from "@/services/work/estimate.service";
import MixinsTable from "@/mixins/single/table";
import MixinsComponent from "@/mixins/component";
import EstimateUtils, { EstimateMoreViewType } from "@/models/estimate";
import core from "@/core";

export default mixins(MixinsComponent, MixinsTable).extend({
  name: "EstimateCardComponent",
  data: () => ({
    viewType: "",
    visible: {
      dDay: false,
      lastPaymentSms: false,
    },
  }),
  created() {
    // 테이블 초기화
    this.initTable({
      service: EstimateService,
      serviceFunctionKey: "getTable",
      itemHeight: 48,
      requestLength: 5,
    });

    this.properties.params.table = this.table;

    const viewType = EstimateUtils.getMoreViewType(this.properties.params.viewType);
    if (viewType == null) {
      console.log("unknown viewType : ", this.properties.params.viewType);
    } else {
      this.viewType = viewType;
      const tableParams = EstimateUtils.getTableParams(viewType);

      this.table.request.order = tableParams.order;
      this.table.request.orderColumnName = tableParams.orderColumnName;
      this.table.request.searchColumns = tableParams.searchColumns;

      if (viewType === EstimateMoreViewType.EXPECTED_REMAINING_DATE) {
        this.visible.dDay = true;
      } else if (
        viewType === EstimateMoreViewType.WAIT_PAYMENT ||
        viewType === EstimateMoreViewType.OLD_WAIT_PAYMENT
      ) {
        this.visible.lastPaymentSms = true;
      }
    }
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable(this.properties.params.searchColumns);
    });
  },
  methods: {
    getRemainingDays(estimate: any) {
      const curDate = core.date.instance();
      const expectedDate = core.date.instance(estimate.expectedDate);
      return expectedDate.diff(curDate, "days");
    },
    showViewPage(estimateId: number) {
      this.$router.push({ path: `/estimate/${estimateId}` });
    },
    showAddPage(phone?: string) {
      const query = {} as any;
      if (phone != null) {
        query.phone = phone;
      }
      this.$router.push({ path: `/estimate/add`, query: query });
    },
    scheduleText(yn: string) {
      if (yn === "Y") {
        return "<span class='green--text'>일정 확정</span>";
      } else {
        return "<span class='orange--text'>일정 미정</span>";
      }
    },
    contractStatusText(value: string) {
      if (value === "WAIT") return "<span class='red--text'>계약 대기</span>";
      else if (value === "COMPLETE") return "<span class='green--text'>계약 완료</span>";
      else if (value === "CANCEL") return "<span class='black--text'>계약 취소</span>";
      return "알 수 없음";
    },
  },
});
