
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { ComponentProperties } from "@/mixins/component";
import core from "@/core";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    event_item: "룰렛 아이템 ",
    event_result: "룰렛 결과 ",
    event_weight: "가중치 ",
    event_max_size: "최대 당첨수 ",
  },
});
export default mixins(MixinsModalForm).extend({
  name: "EventRouletteItemInputModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            item: null as any,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    isEditMode: false,
    form: {
      index: null as any,
      item: "",
      weight: "",
      maxSize: "",
      result: "",
    },
    resultCodeList: [
      { id: "WIN", text: "당첨" },
      { id: "BLANK", text: "꽝" },
    ],
  }),
  mounted() {
    this.$nextTick(async () => {
      const item = this.properties.params?.item;
      if (item != null) {
        this.isEditMode = true;
        //console.log("item : ", item);
        this.form.index = item.index;
        this.form.item = item.item;
        this.form.result = item.result;
        this.form.weight = item.weight;
        this.form.maxSize = item.maxSize;
        //console.log("item : ", item);
      }
    });
  },
  watch: {
    "form.weight"(val, pre) {
      if (val != null && !isNaN(val)) {
        this.form.weight = core.utils.format.onlyNumber(val);
      }
    },
  },
  methods: {
    deleteItem() {
      // console.log("delete item");
      const form = core.utils.deepCopy(this.form);
      this.close(UpdateEventType.DELETE, form);
    },
    async submit() {
      //console.log("submit");
      if (await this.validate()) {
        //console.log("form : ", this.form);
        const form = core.utils.deepCopy(this.form);
        if (this.isBlank(form.maxSize)) {
          form.maxSize = null;
        }
        this.close(UpdateEventType.CONFIRM, form);
      }
    },
  },
});
