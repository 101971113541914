
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import { UpdateEventType } from "@/types";
import core from "@/core";
import PaymentSmsService from "@/services/payment/payment-sms.service";
import PaymentSmsModal from "@/modals/payment/PaymentSmsModal.vue";

export default mixins(MixinsPage, MixinsTable, MixinsScroll).extend({
  name: "MgmtPaymentSms",
  components: { PaymentSmsModal },
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    modal: {
      paymentSms: {
        visible: false,
        params: {
          id: null as any as number,
        },
        updateEvent: null as any as UpdateEventType,
      },
    },
  }),
  mounted() {
    // 테이블 초기화
    this.initTable({
      service: PaymentSmsService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "modal.paymentSms.updateEvent"() {
      const modal = this.modal.paymentSms;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          this.updateTableItem("id", event.item);
        }
      }
    },
  },
  activated() {
    this.$nextTick(() => {
      const event = this.getPageUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (event.path.indexOf("search") > -1) {
            this.updateTableItem("id", event.item);
          } else {
            this.addTableItem("id", event.item);
          }
          this.setScrollPosition(ScrollPosition.TOP);
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown event : ", event);
        }
      }
    });
  },
  methods: {
    getTitle(item: any) {
      let paymentTitle = item.options.cancel ? "입금취소" : "입금";
      return `수신번호: ${core.utils.format.hyphenPhone(
        item.callNumber
      )}, ${paymentTitle}: ${core.utils.format.moneyKor(item.options.payment)}`;
    },
    getSubTitle(item: any) {
      let sender = "";
      if (item.options.sender != null && item.options.sender.length > 0) {
        sender = item.options.sender;
      }
      let dongHo = "";
      if (item.options.dong != null && item.options.ho != null) {
        dongHo = `${sender.length > 0 ? ", " : ""}${item.options.dong}동 ${item.options.ho}호`;
      }
      return `${sender}${dongHo}`;
    },
    showPaymentSmsModal(item) {
      //console.log("item : ", item);
      const modal = this.modal.paymentSms;
      modal.params.id = item.id;
      modal.visible = true;
    },
  },
});
