
import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import ContractStatusService from "@/services/work/contract-status.service";

export default mixins(MixinsComponent).extend({
  name: "ContractStatusCardComponent",
  data: () => ({
    list: [] as any,
    progress: {
      temporary: 100,
      wait: 100,
      complete: 0,
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const placeMap = {} as any;
      const placeList = await this.$store.getters["app/getPlaceList"]();
      placeList.forEach((place) => {
        placeMap[place.id] = place;
      });
      //console.log("placeList : ", placeList);

      const displayList = [] as any;
      const list = (await ContractStatusService.getList()) as any;
      list.forEach((item) => {
        const placeId = item.placeId;
        item.place = placeMap[placeId];
        // console.log("place : ", item.place);

        const totalSize = item.temporaryCount + item.waitCount + item.completeCount;
        item.totalSize = totalSize;
        item.totalSize = 30;
        item.temporaryPer = (item.temporaryCount / item.totalSize) * 100;
        item.waitPer = (item.waitCount / item.totalSize) * 100;
        item.completePer = (item.completeCount / item.totalSize) * 100;
        console.log("item : ", item);
        if (displayList.length < 10) {
          displayList.push(item);
        }
      });
      this.list = displayList;
      // placeId, expositionDate, dDay, temporaryCount, waitCount, completeCount
      // console.log("list : ", list);
    });
  },
  methods: {
    moveEstimatePage(item) {
      this.$router.push({
        path: "/estimate",
        query: {
          placeId: item.placeId,
        },
      });
    },
  },
});
