
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import { UpdateEventType } from "@/types";
import TeamService from "@/services/team/team.service";

export default mixins(MixinsPage, MixinsTable, MixinsScroll).extend({
  name: "MgmtTeam",
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
  }),
  mounted() {
    // 테이블 초기화
    this.initTable({
      service: TeamService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
  },
  activated() {
    this.$nextTick(() => {
      const event = this.getPageUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (event.path.indexOf("search") > -1) {
            this.updateTableItem("id", event.item);
          } else {
            this.addTableItem("id", event.item);
          }
          this.setScrollPosition(ScrollPosition.TOP);
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown event : ", event);
        }
      }
    });
  },
  methods: {
    getEnabledText(item: any) {
      if (item.enabled) return `<span class="green--text">활성</span>`;
      else return `<span class="red--text">비활성</span>`;
    },
    showAddPage() {
      //console.log("showAddPage");
      this.$router.push({
        path: "/mgmt/team/add",
      });
    },
    showViewPage(item: any) {
      //console.log("item : ", item);
      this.$router.push({
        path: `/mgmt/team/${item.id}`,
      });
    },
    getStringTitleViewType(item: any) {
      if (item.titleViewType === "PRICE_ALL") {
        return "제목 팀원 금액 표시중, ";
      } else if (item.titleViewType === "PRICE_LEADER") {
        return "제목 팀장 금액 표시중, ";
      } else if (item.titleViewType === "TYPE") {
        return "제목 아파트 타입 표시중, ";
      }
      return "";
    },
  },
});
