
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EventComponent from "@/components/event/EventComponent.vue";
import UpdateEvent from "@/models";

export default mixins(MixinsPage).extend({
  name: "Event",
  components: { EventComponent },
  data: () => ({
    component: {
      event: {
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  activated() {
    const event = this.getPageUpdateEvent();
    if (event) {
      this.component.event.updateEvent = event;
    }
  },
});
