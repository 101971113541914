
import CompanyAddEditComponent from "@/components/company/CompanyAddEditComponent.vue";
import CompanyViewComponent from "@/components/company/CompanyViewComponent.vue";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { UserModel } from "@/models/user/user.model";
import { CompanyRole } from "@/types";

export default mixins(MixinsPage).extend({
  name: "UserCompany",
  computed: {
    CompanyRole() {
      return CompanyRole;
    },
  },
  components: {
    CompanyAddEditComponent,
    CompanyViewComponent,
  },
  data: () => ({
    formProcessing: false,
    form: {
      name: "",
      ceoName: "",
      phone: "",
      detailList: [],
    },
    user: null as UserModel | null,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.user = await this.$store.getters["auth/user"]();
    });
  },
  methods: {},
});
