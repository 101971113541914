
import mixins from "vue-typed-mixins";
import ScheduleService from "@/services/work/schedule.service";
import MixinsTable from "@/mixins/single/table";
import MixinsComponent from "@/mixins/component";
import ScheduleUtils from "@/models/schedule";
import core from "@/core";
import EventService from "@/services/event/event.service";

export default mixins(MixinsComponent, MixinsTable).extend({
  name: "ScheduleCardComponent",
  data: () => ({
    visible: {},
  }),
  created() {
    // 테이블 초기화
    this.initTable({
      service: ScheduleService,
      serviceFunctionKey: "getTable",
      itemHeight: 48,
      requestLength: 5,
    });

    this.properties.params.table = this.table;

    const viewType = ScheduleUtils.getMoreViewType(this.properties.params.viewType);
    if (viewType == null) {
      console.log("unknown viewType : ", this.properties.params.viewType);
    } else {
      const tableParams = ScheduleUtils.getTableParams(viewType);

      this.table.request.order = tableParams.order;
      this.table.request.orderColumnName = tableParams.orderColumnName;
      this.table.request.searchColumns = tableParams.searchColumns;
    }
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable(this.properties.params.searchColumns);
    });
  },
  methods: {
    scheduleText(item) {
      const date = this.scheduleDateText(item);

      let text = "";
      if (item.category != null) {
        text = `${item.category.name} (${date})`;
      } else {
        text = date;
      }

      if (item.workType === "A") {
        return `A/S ${text}`;
      }
      return text;
    },
    scheduleDateText(item) {
      const startDate = core.date.instance(item.startAt).format("YYYY-MM-DD");
      const endDate = core.date.instance(item.endAt).format("YYYY-MM-DD");
      if (startDate == endDate) {
        return core.date.instance(item.startAt).format("YYYY년 MM월 DD일");
      } else {
        return (
          core.date.instance(item.startAt).format("YYYY년 MM월 DD일") +
          " ~ " +
          core.date.instance(item.endAt).format("YYYY년 MM월 DD일")
        );
      }
    },
    getRemainingDays(estimate: any) {
      const curDate = core.date.instance();
      const expectedDate = core.date.instance(estimate.expectedDate);
      return expectedDate.diff(curDate, "days");
    },
    showViewPage(scheduleId: number) {
      this.$router.push({ path: `/schedule/${scheduleId}` });
    },
    showAddPage(phone?: string) {
      const query = {} as any;
      if (phone != null) {
        query.phone = phone;
      }
      this.$router.push({ path: `/estimate/add`, query: query });
    },
  },
});
