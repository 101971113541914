export default class CoreLocation {
  private readonly alert: any;

  constructor(alert: any) {
    this.alert = alert;
  }

  currentPosition(options?: any) {
    return this._location("current", options);
  }

  watchPosition(options?: any) {
    return this._location("watch", options);
  }

  private _location(type: string, options: any) {
    const vm = this;
    return new Promise((resolve: any, reject) => {
      let count = 0;

      const success = function (position: any) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`position latitude: ${latitude}, longitude: ${longitude}`);
        resolve({
          latitude: latitude,
          longitude: longitude,
        });
      };
      const error = function (error: any) {
        if (type === "watch" && count > 0) {
          return;
        }
        count++;
        reject(error);

        if (error.code == 1) {
          vm.alert.show({
            title: "알림",
            body: "위치 권한이 없어 내 위치 정보를 가져올 수 없습니다",
          });
        } else if (error.code == 2) {
          vm.alert.show({
            title: "알림",
            body: "내 위치 정보 획득에 실패했습니다",
          });
        } else if (error.code == 3) {
          vm.alert.show({
            title: "알림",
            body: "내 위치 정보 획득시간이 초과했습니다",
          });
        } else {
          console.log("unknown error: ", error);
        }
      };

      if (options == null) {
        options = {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 0,
        };
      }

      if (type === "current") {
        navigator.geolocation.getCurrentPosition(success, error, options);
      } else if (type === "watch") {
        const watchId = navigator.geolocation.watchPosition(success, error, options);
      } else {
        console.log("unknown type: ", type);
      }
    });
  }
}
