
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import { UpdateEventType } from "@/types";
import store from "@/store";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import ExpoEventService from "@/services/expo/expo-event.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    eventName: "이벤트명 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "ExpositionAddEditEventModal",
  components: {},
  data: () => ({
    type: "add",
    imageIndex: 0,
    form: {
      id: 0,
      name: "",
      placeId: 0,
      imageList: [] as any,
    },
    style: {
      image: "max-width: 100%",
    },
    app: store.state.app,
  }),
  mounted() {
    this.$nextTick(async () => {
      if (!this.app.isMobile) {
        if (this.app.size.width > 700) {
          this.style.image = `max-width: 50%`;
        }
      }

      const properties = this.properties;
      this.type = properties.params.type;
      if (this.type !== "add" && this.type !== "edit") {
        await this.notFound();
        return;
      }

      if (this.type === "edit") {
        if (properties.params.item == null || properties.params.item.id == null) {
          await this.notFound();
          return;
        }
      }

      if (properties.params.placeId) {
        this.form.placeId = properties.params.placeId;
      } else {
        await this.notFound();
        return;
      }

      if (this.type === "edit") {
        const item = properties.params.item;
        if (item == null) {
          await this.notFound();
          return;
        }

        this.form.id = item.id;
        this.form.name = item.name;
        item.detailList.forEach((detail) => {
          // console.log("detail : ", detail);
          const image = {
            index: this.imageIndex++,
            id: detail.id,
            fileDetailId: detail.fileDetailId,
            dataUri: detail.imageUri,
          };
          this.form.imageList.push(image);
        });
      }
    });
  },
  methods: {
    moveImage(direction, image, imageList) {
      let pos = 0;
      imageList.some((_image, index) => {
        if (image.index === _image.index) {
          pos = index;
          return true;
        }
      });
      if (direction === "down") {
        if (pos + 1 < imageList.length) {
          const fromItem = imageList.splice(pos, 1);
          imageList.splice(pos + 1, 0, fromItem[0]);
        }
      } else if (direction === "up") {
        if (0 < pos) {
          const fromItem = imageList.splice(pos, 1);
          imageList.splice(pos - 1, 0, fromItem[0]);
        }
      }
      // console.log("image : ", image);
      // console.log("imageList : ", imageList);
    },
    showImageSelectView() {
      (this.$refs.imageFile as any).click();
    },

    async onChangeImageFile() {
      console.log("onChangeImageFile");
      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        for (let i = 0; i < length; i++) {
          try {
            const file = files[i];
            const imageFile = (await core.utils.image.getImageBlob(file)) as any;
            imageFile.index = this.imageIndex++;
            imageFile.type = "upload";

            //console.log("new image : ", imageFile);
            this.form.imageList.push(imageFile);
          } catch (e: any) {
            core.http.sendError(e);
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
          }
        }
        (this.$refs.imageFile as any).value = "";
        // this.scrollControl.updatedPosition = ScrollPosition.BOTTOM;
      }
    },

    removeImage(image) {
      const imageList = this.form.imageList;
      for (let i = 0; i < imageList.length; i++) {
        const _image = imageList[i];
        if (image.index === _image.index) {
          imageList.splice(i, 1);
          break;
        }
      }
    },
    confirm() {
      this.submit();
    },
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const orgForm = this.form;
          if (orgForm.imageList.length === 0) {
            await core.alert.show({
              title: "오류",
              body: "이미지를 하나 이상 선택해야 합니다.",
            });
            return;
          }

          const form = {
            id: orgForm.id,
            name: orgForm.name,
            placeId: orgForm.placeId,
            detailList: [] as any,
          };

          // 이미지 파일 업로드
          const imageLength = orgForm.imageList.length;
          for (let i = 0; i < imageLength; i++) {
            const image = orgForm.imageList[i];
            if (image.id == null) {
              const formData = new FormData();
              formData.append("imageFile", image.blob, image.filename);

              const uploadCount = i + 1;

              let uploadComplete = false;
              for (let retry = 0; retry < 4; retry++) {
                try {
                  if (retry === 0) {
                    core.loader.show(`이미지 업로드중... (${uploadCount}/${imageLength})`);
                  } else {
                    core.loader.show(
                      `이미지 업로드중... (${uploadCount}/${imageLength}), 재시도(${retry}/3)`
                    );
                  }
                  const fileDetailId = await ExpoEventService.createImage(formData);
                  const detail = {
                    fileDetailId: fileDetailId,
                  } as any;
                  form.detailList.push(detail);
                  uploadComplete = true;
                  break;
                } catch (e) {
                  console.log(e);
                }
              }
              if (!uploadComplete) {
                await core.alert.show({
                  title: "오류",
                  body: "파일 업로드에 실패했습니다.",
                });
                return;
              }
            } else {
              const detail = {
                id: image.id,
                fileDetailId: image.fileDetailId,
              } as any;
              form.detailList.push(detail);
            }
          }

          const type = this.type;
          if (type === "add") {
            const expoEvent = await ExpoEventService.create(form);
            console.log("create event : ", expoEvent);
          } else if (type === "edit") {
            const expoEvent = await ExpoEventService.update(form.id, form);
            console.log("update event : ", expoEvent);
          } else {
            console.log("invalid type : ", type);
            return;
          }
          core.loader.hide();
          this.close(UpdateEventType.UPDATE);
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
      }
    },
    async deleteItem() {
      const item = this.properties.params.item;
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const result = await ExpoEventService.delete(item.id);
          console.log("result : ", result);
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
        this.close(UpdateEventType.UPDATE);
      }
    },
  },
});
