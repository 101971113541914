
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import TextFieldModal from "@/modals/core/TextFieldModal.vue";
import { Tree } from "vue-tree-list";
import core from "@/core";
import ExpoService from "@/services/expo/expo.service";
import { FileDetailModel } from "@/models/file/file-detail.model";
import SelectImageLinkModal from "@/modals/core/SelectImageLinkModal.vue";

export interface SubMenu {
  name: string;
}

export interface Menu {
  categoryId: number | null;
  name: string;
  subMenuList: SubMenu[];
}

export interface MainTreeReq {
  id: number;
  categoryId: number;
  name: string;
  itemList: RequestItem[];
  childrenList: SubTreeReq[];
}

export interface SubTreeReq {
  id: number;
  name: string;
  itemList: RequestItem[];
}

export interface MainTreeRes {
  id: number;
  categoryId: number;
  name: string;
  itemList: ResponseItem[];
  imageList: FileDetailModel[] | null;
  childrenList: SubTreeRes[];
}

export interface SubTreeRes {
  id: number;
  name: string;
  imageList: FileDetailModel[] | null;
  itemList: ResponseItem[];
}

export enum ImageUploadType {
  UPLOAD = "upload",
  SERVER = "server",
}

export enum ExpoItemType {
  LINK = "LINK",
  IMAGE = "IMAGE",
}

export interface RequestItem {
  type: ExpoItemType;
  address: string | null | undefined;
  fileDetailId: number | null | undefined;
}

export interface ResponseItem {
  type: ExpoItemType;
  address: string | null;
  fileDetail: FileDetailModel | null;
}

export interface ExpoItem {
  index: number;
  type: ExpoItemType;
  address: string | null | undefined;
  image: ImageFile | null | undefined;
}

export interface ImageFile {
  id: number | null;
  extension: string;
  type: ImageUploadType;
  filename: string;
  blob: any;
  dataUri: string;
}

export default mixins(MixinsPage).extend({
  name: "ExpositionEdit",
  components: { SelectImageLinkModal, TextFieldModal },
  data: () => ({
    selectedItem: null as any,
    menuList: [] as Menu[],
    modal: {
      menu: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 50,
          },
          title: "이름",
          type: "edit",
          text: "",
          item: null as any,
          enableDeleteButton: false,
        },
        updateEvent: null as UpdateEvent | null,
      },
      selectImageLink: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      link: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 255,
          },
          title: "링크",
          type: "add",
          text: "",
          item: null as any,
          enableDeleteButton: false,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    tree: {
      data: null as any,
      selectedNode: null as any,
    },
    // imageIndex: 0,
    // imageList: [] as ImageFile[],

    itemIndex: 0,
    itemList: [] as ExpoItem[],
  }),
  computed: {
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  watch: {
    "modal.selectImageLink.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectImageLink);
      if (event) {
        if (event.result === UpdateEventType.CONFIRM) {
          const result = event.item;
          console.log("result : ", result);
          if (result === "image") {
            this.showImageSelectView();
          } else if (result === "link") {
            this.showLinkModal();
          } else {
            console.log("unknown result. ", result);
          }
        }
      }
    },
    async "modal.link.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.link);
      if (event) {
        if (event.result === UpdateEventType.CONFIRM) {
          const link = event.item;
          // console.log("link : ", link);
          let regex =
            /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
          if (!regex.test(link)) {
            await core.alert.show({
              title: "알림",
              body: "올바른 주소가 아닙니다.",
            });
            return;
          }
          this.itemList.push({
            index: this.itemIndex++,
            type: ExpoItemType.LINK,
            address: link,
            image: null,
          });
        }
      }
    },
    selectedItem(val, prev) {
      console.log("change item : ", val);
    },
    "tree.selectedNode"(selectedNode) {
      const treeData = this.tree.data;
      if (selectedNode.itemList != null) {
        this.itemList = selectedNode.itemList;
      } else {
        this.itemList = [];
      }
      selectedNode.itemList = this.itemList;
    },
    "modal.menu.updateEvent"() {
      const modal = this.modal.menu;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const text = event.item;
          // console.log("text : ", text);
          const subMenu = modal.params.item;
          subMenu.name = text;
        }
      }
      // console.log("event : ", event);
    },
  },
  mounted() {
    // console.log("mounted");
    this.$nextTick(async () => {
      let expoList = null as any;
      try {
        const expo = (await ExpoService.get()) as any;
        expoList = expo.list;
      } catch (e) {
        console.log(e);
      }

      try {
        const treeList = [] as any;
        if (expoList == null || expoList.length === 0) {
          treeList.push({
            name: "메인",
            categoryId: 0,
            id: 0,
            pid: 0,
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,
            children: [],
            itemList: [],
          });
          const orgCategoryList = await this.$store.getters["app/getCategoryList"]();
          orgCategoryList.forEach((category) => {
            ["나노코팅", "새집증후군", "줄눈", "청소", "탄성코트"].some((categoryName) => {
              if (category.name === categoryName) {
                treeList.push({
                  name: category.name,
                  id: category.id,
                  categoryId: category.id,
                  pid: 0,
                  dragDisabled: true,
                  addTreeNodeDisabled: true,
                  addLeafNodeDisabled: false,
                  editNodeDisabled: true,
                  delNodeDisabled: true,
                  children: [],
                  itemList: [],
                });
                return true;
              }
            });
            //console.log("category : ", category.name);
          });
        } else {
          expoList.forEach((mainTree: MainTreeRes) => {
            // console.log("mainTree : ", mainTree);
            const childrenList = [] as any;
            mainTree.childrenList.forEach((children) => {
              const itemList = [] as any;
              if (children.imageList) {
                const subTreeItemList = [] as ResponseItem[];
                children.imageList.forEach((image) => {
                  subTreeItemList.push({
                    type: ExpoItemType.IMAGE,
                    address: null,
                    fileDetail: image as FileDetailModel,
                  });
                });
                children.itemList = subTreeItemList;
                children.imageList = null;
                // console.log("children : ", children);
              }
              if (children.itemList == null) children.itemList = [];
              children.itemList.forEach((item) => {
                if (item.type === ExpoItemType.IMAGE) {
                  const fileDetail = item.fileDetail as FileDetailModel;
                  itemList.push({
                    type: ExpoItemType.IMAGE,
                    index: this.itemIndex++,
                    image: {
                      id: fileDetail.id,
                      extension: fileDetail.extension,
                      dataUri: fileDetail.downloadUri,
                      type: ImageUploadType.SERVER,
                    },
                  });
                } else if (item.type === ExpoItemType.LINK) {
                  itemList.push({
                    type: ExpoItemType.LINK,
                    index: this.itemIndex++,
                    address: item.address,
                  });
                }
              });
              childrenList.push({
                id: children.id,
                name: children.name,
                isLeaf: true,
                pid: 1,
                itemList: itemList,
              });
            });
            const itemList = [] as any;
            if (mainTree.imageList) {
              const mainTreeItemList = [] as ResponseItem[];
              mainTree.imageList.forEach((image) => {
                mainTreeItemList.push({
                  type: ExpoItemType.IMAGE,
                  address: null,
                  fileDetail: image as FileDetailModel,
                });
              });
              mainTree.itemList = mainTreeItemList;
              mainTree.imageList = null;
            }
            if (mainTree.itemList == null) mainTree.itemList = [];
            // console.log("mainTree : ", mainTree);
            mainTree.itemList.forEach((item) => {
              if (item.type === ExpoItemType.IMAGE) {
                const fileDetail = item.fileDetail as FileDetailModel;
                itemList.push({
                  type: ExpoItemType.IMAGE,
                  index: this.itemIndex++,
                  image: {
                    id: fileDetail.id,
                    extension: fileDetail.extension,
                    dataUri: fileDetail.downloadUri,
                    type: ImageUploadType.SERVER,
                  },
                });
              } else if (item.type === ExpoItemType.LINK) {
                itemList.push({
                  type: ExpoItemType.LINK,
                  index: this.itemIndex++,
                  address: item.address,
                });
              }
              // imageList.push({
              //   id: imageFileDetail.id,
              //   extension: imageFileDetail.extension,
              //   dataUri: imageFileDetail.downloadUri,
              //   type: ImageUploadType.SERVER,
              //   index: this.imageIndex++,
              // });
            });
            let addLeafNodeDisabled = mainTree.id === 0;
            treeList.push({
              name: mainTree.name,
              id: mainTree.id,
              categoryId: mainTree.categoryId,
              pid: 0,
              dragDisabled: true,
              addTreeNodeDisabled: true,
              addLeafNodeDisabled: addLeafNodeDisabled,
              editNodeDisabled: true,
              delNodeDisabled: true,
              children: childrenList,
              itemList: itemList,
            });
          });
        }
        // this.menuList = menuList;
        // menuList.forEach((menu) => {
        //   console.log("menu : ", menu.name);
        // });
        console.log("treeList : ", treeList);
        this.tree.data = new Tree(treeList);
        // console.log("categoryList : ", this.categoryList);
      } catch (e) {
        console.log(e);
      }
    });
  },
  methods: {
    showPage(item: ExpoItem) {
      if (item.type === ExpoItemType.LINK) {
        window.open(String(item.address), "_blank");
      }
    },
    async submit() {
      const treeData = this.tree.data;
      // console.log("treeData : ", treeData);
      const mainTreeListParams = [] as MainTreeReq[];

      const childrenLength = treeData.children.length;
      for (let i = 0; i < childrenLength; i++) {
        const mainTree = treeData.children[i];
        const expoItemList = [] as RequestItem[];
        if (mainTree.itemList != null && mainTree.itemList.length > 0) {
          const mainTreeItemList = mainTree.itemList;
          const itemLength = mainTreeItemList.length;
          let uploadImageSize = 0;
          for (let j = 0; j < itemLength; j++) {
            const item = mainTreeItemList[j] as ExpoItem;
            if (
              item.type === ExpoItemType.IMAGE &&
              item.image &&
              item.image.type === ImageUploadType.UPLOAD
            ) {
              uploadImageSize++;
            }
          }

          let uploadImageCount = 0;
          for (let j = 0; j < itemLength; j++) {
            const item = mainTreeItemList[j] as ExpoItem;
            if (item.type === ExpoItemType.IMAGE) {
              if (item.image) {
                if (item.image.type === ImageUploadType.UPLOAD) {
                  const image = item.image;
                  const formData = new FormData();
                  formData.append("imageFile", image.blob, image.filename);
                  try {
                    uploadImageCount++;
                    core.loader.show(`이미지 업로드중... (${uploadImageCount}/${uploadImageSize})`);
                    const fileDetailId = await ExpoService.createImage(formData);
                    expoItemList.push({
                      type: ExpoItemType.IMAGE,
                      fileDetailId: Number(fileDetailId),
                    } as RequestItem);
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  const image = item.image;
                  expoItemList.push({
                    type: ExpoItemType.IMAGE,
                    fileDetailId: image.id,
                  } as RequestItem);
                }
              } else {
                console.log("not found item.image", item);
              }
            } else {
              expoItemList.push({
                type: ExpoItemType.LINK,
                address: item.address,
              } as RequestItem);
            }
          }
        }
        const mainTreeParams = {
          id: mainTree.id,
          categoryId: mainTree.categoryId,
          name: mainTree.name,
          childrenList: [],
          itemList: expoItemList,
        } as MainTreeReq;
        // console.log("mainTreeParams : ", mainTreeParams);
        mainTreeListParams.push(mainTreeParams);

        // subtree
        const subTreeList = mainTree.children;
        // console.log("mainTree : ", mainTree);
        if (subTreeList != null) {
          const subTreeLength = subTreeList.length;
          for (let j = 0; j < subTreeLength; j++) {
            const subTree = subTreeList[j];
            // console.log("subTree : ", subTree);
            // const subTreeImageFileDetailIdList = [] as number[];
            const subTreeExpoItemList = [] as RequestItem[];

            const subTreeItemList = subTree.itemList;
            const imageLength = subTreeItemList.length;
            let uploadImageSize = 0;
            for (let l = 0; l < imageLength; l++) {
              const item = subTreeItemList[l] as ExpoItem;
              if (
                item.type === ExpoItemType.IMAGE &&
                item.image &&
                item.image.type === ImageUploadType.UPLOAD
              ) {
                uploadImageSize++;
              }
            }

            let uploadImageCount = 0;
            for (let l = 0; l < imageLength; l++) {
              const item = subTreeItemList[l] as ExpoItem;
              if (item.type === ExpoItemType.IMAGE) {
                if (item.image) {
                  if (item.image.type === ImageUploadType.UPLOAD) {
                    const image = item.image;
                    const formData = new FormData();
                    formData.append("imageFile", image.blob, image.filename);
                    try {
                      uploadImageCount++;
                      core.loader.show(
                        `이미지 업로드중... (${uploadImageCount}/${uploadImageSize})`
                      );
                      const fileDetailId = await ExpoService.createImage(formData);
                      subTreeExpoItemList.push({
                        type: ExpoItemType.IMAGE,
                        fileDetailId: Number(fileDetailId),
                      } as RequestItem);
                    } catch (e) {
                      console.log(e);
                    }
                  } else {
                    const image = item.image;
                    subTreeExpoItemList.push({
                      type: ExpoItemType.IMAGE,
                      fileDetailId: image.id,
                    } as RequestItem);
                  }
                } else {
                  console.log("not found item.image", item);
                }
              } else {
                subTreeExpoItemList.push({
                  type: ExpoItemType.LINK,
                  address: item.address,
                } as RequestItem);
              }
            }

            const subTreeParams = {
              id: subTree.id,
              name: subTree.name,
              itemList: subTreeExpoItemList,
            } as SubTreeReq;
            // subTreeListParams.push(subTreeParams);
            mainTreeParams.childrenList.push(subTreeParams);
          }
        }
      }

      // console.log("mainTreeListParams : ", mainTreeListParams);
      const result = await ExpoService.update({ list: mainTreeListParams });
      // console.log("result : ", result);
      this.goBack();
    },
    removeItem(item: ExpoItem) {
      // console.log("removeImage : ", image);
      const itemList = this.itemList;
      for (let i = 0; i < itemList.length; i++) {
        const _item = itemList[i];
        if (item.index === _item.index) {
          itemList.splice(i, 1);
          break;
        }
      }
    },
    moveItem(direction: string, item: ExpoItem) {
      // console.log("direction : ", direction, ", image : ", image);
      const itemList = this.itemList;

      let pos = 0;
      itemList.some((_item, index) => {
        if (item.index === _item.index) {
          pos = index;
          return true;
        }
      });

      if (direction === "down") {
        if (pos + 1 < itemList.length) {
          const fromItem = itemList.splice(pos, 1);
          const image = fromItem[0];
          const index = pos + 1;
          itemList.splice(index, 0, image);
          // console.log("fromItem : ", image);
          setTimeout(() => {
            const id = `exposition_item_${image.index}`;
            // console.log("move id : ", id);
            const el = document.getElementById(id);
            if (el != null) {
              const elementPosition = el.getBoundingClientRect().top;
              const bodyPosition = document.body.getBoundingClientRect().top;
              const offset = 80;

              window.scrollTo({
                top: elementPosition - bodyPosition - offset,
              });
            }
          }, 50);
        }
      } else if (direction === "up") {
        if (0 < pos) {
          const fromItem = itemList.splice(pos, 1);
          const image = fromItem[0];
          const index = pos - 1;
          itemList.splice(index, 0, image);
          // console.log("fromItem : ", image);
          setTimeout(() => {
            const id = `exposition_item_${image.index}`;
            // console.log("move id : ", id);
            const el = document.getElementById(id);
            if (el != null) {
              const elementPosition = el.getBoundingClientRect().top;
              const bodyPosition = document.body.getBoundingClientRect().top;
              const offset = 80;

              window.scrollTo({
                top: elementPosition - bodyPosition - offset,
              });
            }
          }, 50);
        }
      }
    },
    async onChangeImageFile() {
      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        core.loader.show("이미지 변환중...");
        const itemList = [] as ExpoItem[];
        for (let i = 0; i < length; i++) {
          try {
            const file = files[i];
            if (file.type === "video/mp4") {
              const imageFile = (await core.utils.image.getVideoBlob(file)) as any;
              imageFile.type = ImageUploadType.UPLOAD;
              imageFile.extension = "mp4";

              // console.log("new video : ", imageFile);
              itemList.push({
                index: this.itemIndex++,
                type: ExpoItemType.IMAGE,
                address: null,
                image: imageFile as ImageFile,
              });
            } else {
              const imageFile = (await core.utils.image.getImageBlob(file)) as any;
              imageFile.type = ImageUploadType.UPLOAD;
              imageFile.extension = "image";

              // console.log("new image : ", imageFile);
              itemList.push({
                index: this.itemIndex++,
                type: ExpoItemType.IMAGE,
                address: null,
                image: imageFile as ImageFile,
              });
            }
          } catch (e: any) {
            core.http.sendError(e);
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
          }
        }

        itemList.forEach((imageFile) => {
          this.itemList.push(imageFile);
        });
        //
        // this.changedImage = true;

        // this.scrollUpdatedPosition = ScrollPosition.BOTTOM;
        // console.log("addImage : ", this.$refs.addImage);
        if (this.$refs.addImage != null) {
          let count = 0;
          const moveScroll = () => {
            setTimeout(() => {
              //console.log("scroll");
              (this.$refs.addImage as any).$el.scrollIntoView(true);
              // this.setScrollPosition(ScrollPosition.BOTTOM);
              count++;
              if (count === 1) {
                moveScroll();
              }
            }, 200);
          };
          moveScroll();
        }

        (this.$refs.imageFile as any).value = "";
        core.loader.hide();
      }
    },
    showImageSelectView() {
      (this.$refs.imageFile as any).click();
    },
    showSelectImageLinkModal() {
      const modal = this.modal.selectImageLink;
      modal.visible = true;
    },
    showLinkModal() {
      console.log("show link modal");
      const modal = this.modal.link;
      modal.visible = true;
    },
    onAddNode(node) {
      console.log("add node : ", node);
      node.itemList = [];
      // const node = new TreeNode({ name: "new node", isLeaf: false });
      // console.log("add node : ", node);
      // if (this.tree.data != null) {
      //   if (!this.tree.data.children) this.tree.data.children = [];
      //   this.tree.data.addChildren(node);
      // }
    },
    onDeleteNode(node) {
      console.log("delete node : ", node);
      node.remove();
    },
    onChangeNodeName(node) {
      console.log("change node name : ", node);
    },
    onDrop({ node, src, target }) {
      console.log("onDrop node : ", node, ", src : ", src, ", target : ", target);

      let targetNode = target;
      if (target.isLeaf) {
        targetNode = target.parent;
      }
      console.log("targetNode : ", targetNode);
      if (src.id != targetNode.id) {
        // move into
        console.log("move org position");
        node.moveInto(src);
      }
    },
    onDropBefore({ node, src, target }) {
      console.log("onDropBefore node : ", node, ", src : ", src, ", target : ", target);
      // // 취소
      // node.moveInto(src);
      let targetNode = target;
      if (target.isLeaf) {
        targetNode = target.parent;
      }
      console.log("targetNode : ", targetNode);
      if (src.id != targetNode.id) {
        // move into
        console.log("move org position");
        node.moveInto(src);
      }
    },
    onClickNode(node) {
      console.log("click node : ", node);
      const tree = this.tree;
      if (tree.selectedNode != null) {
        if (tree.selectedNode.id == node.id) {
          console.log("same node");
          return;
        }
        const el = document.getElementById(tree.selectedNode.id);
        if (el != null) {
          el.classList.remove("vtl-selected");
        }
      }
      tree.selectedNode = node;
      const el = document.getElementById(node.id);
      if (el != null) {
        el.classList.add("vtl-selected");
      }
    },
  },
});
