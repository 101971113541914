
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import EstimateAddEditComponent from "@/components/estimate/AddEditComponent.vue";

export default mixins(MixinsPageForm).extend({
  name: "EstimateAddEdit",
  components: { EstimateAddEditComponent },
  data: () => ({}),
});
