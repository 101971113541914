export enum Page {
  NOTIFICATION = "notification",
  DASHBOARD = "dashboard",
  ESTIMATE = "estimate",
  DELETED_ESTIMATE = "deleted-estimate",
  ESTIMATE_ISSUE = "estimate-issue",
  SUPPORT = "support",
  SUPPORT_AS = "support-as",
  PLACE = "place",
  UNIT_PRICE = "unit-price",
  SPECIAL_PRICE = "special-price",
  SCOPE = "scope",
  CATEGORY = "category",
  USER = "user",
  TEAM = "team",
  SCHEDULE = "schedule",
  PAYMENT_SMS = "payment-sms",
  SPECIAL_PRICE_LINK = "special-price-link",
  EVENT = "event",
}
