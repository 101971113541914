
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import EstimateUtils from "@/models/estimate";
import { cloneDeep } from "lodash";

export default mixins(MixinsCommon).extend({
  name: "EstimateListItemComponent",

  props: {
    item: {
      type: Object,
      default: () => {
        return null as any;
      },
    },
    displayTimeColumn: {
      type: String,
      default: "createdAt",
    },
    type: {
      type: String,
      default: "",
    },
  },
  methods: {
    estimateTimeToString(item: any) {
      if (this.displayTimeColumn == "updatedAt") {
        return this.dateElapsedTimeFormat(item.updatedAt);
      } else {
        return this.dateElapsedTimeFormat(item.createdAt);
      }
    },
    showViewPage(estimateId: number) {
      let query = cloneDeep(this.$route.query) as any;
      if (query == null) {
        query = {};
      }
      delete query.stackKey;
      if (this.isNotBlank(this.type)) {
        query.type = this.type;
      }
      this.$router.push({ path: `/estimate/${estimateId}`, query: query });
    },
    titleToHtml(item: any) {
      return EstimateUtils.titleToHtml(item);
    },
    scheduleToHtml(item: any) {
      return EstimateUtils.scheduleToHtml(item);
    },
    contractStatusToHtml(item: any) {
      return EstimateUtils.contractStatusToHtml(item);
    },
    captionToHtml(item: any) {
      if (item.totalPrice > 0) {
        if (item.paymentYn === "Y") {
          return `견적가 ${this.moneyFormat(item.totalPrice)}, 결제 완료`;
        } else {
          return `견적가 ${this.moneyFormat(item.totalPrice)}, 결제 ${this.moneyFormat(
            item.payment
          )}, 잔금 ${this.moneyFormat(item.balance)}`;
        }
      }
      return "";
    },
  },
});
