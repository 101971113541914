
import mixins from "vue-typed-mixins";
import UpdateEvent from "@/models";
import store from "@/store";
import FilerobotImageEditor from "@/assets/js/filerobot-image-editor.min.js";
import MixinsModal from "@/mixins/modal";
import core from "@/core";
import { UpdateEventType } from "@/types";

// modal params
// imageEditor: {
//   visible: false,
//     params: {
//     filename: "",
//       dataUri: "",
//   },
//   updateEvent: null as UpdateEvent | null,
// },

export default mixins(MixinsModal).extend({
  name: "ImageEditorModal",

  components: {},
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            filename: "",
            dataUri: "",
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    isMobileSize: false,
    app: store.state.app,
    backgroundImage: null as any,
    bodySize: {
      width: 500,
      height: 500,
    },
    editMode: false,
    canvasStyle: "",
    options: {
      width: 500,
      height: 500,
      cameraOffset: {
        x: 0,
        y: 0,
      },
      lastZoom: 1,
      cameraZoom: 1,
      maxZoom: 5,
      minZoom: 0.1,
      scrollSensitivity: -0.0005,
      initialPinchDistance: null as number | null,
    },
    filerobotImageEditor: null as FilerobotImageEditor | null,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.windowResize();
      const dataUri = this.properties.params.dataUri;
      // const canvas = this.$refs.canvas as HTMLCanvasElement;
      // this.initCanvas(canvas);

      // const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

      const img = new Image();
      const vm = this;
      img.onload = function () {
        const imageWidth = img.width;
        const imageHeight = img.height;
        // console.log("onload image.. ", imageWidth, imageHeight);

        vm.backgroundImage = img;
        // ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
        // ctx.drawImage(img, 0, 0);
        vm.init(img);
      };
      // console.log("dataUri : ", dataUri);
      img.src = dataUri;
      // this.draw();
    });
  },
  watch: {
    "app.size"() {
      this.windowResize();
    },
  },
  methods: {
    init(image) {
      const TABS = (FilerobotImageEditor as any).TABS;
      const TOOLS = (FilerobotImageEditor as any).TOOLS;
      // console.log("TABS : ", TABS);
      const config = {
        source: image,
        annotationsCommon: {
          fill: "#ff0000",
        },
        Rotate: { angle: 90, componentType: "slider" },
        Crop: {
          presetsItems: [
            {
              titleKey: "classicTv",
              descriptionKey: "4:3",
              ratio: 4 / 3,
              // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
            },
            {
              titleKey: "cinemascope",
              descriptionKey: "21:9",
              ratio: 21 / 9,
              // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
            },
          ],
          presetsFolders: [
            {
              titleKey: "socialMedia", // will be translated into Social Media as backend contains this translation key
              // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
              groups: [
                {
                  titleKey: "facebook",
                  items: [
                    {
                      titleKey: "profile",
                      width: 180,
                      height: 180,
                      descriptionKey: "fbProfileSize",
                    },
                    {
                      titleKey: "coverPhoto",
                      width: 820,
                      height: 312,
                      descriptionKey: "fbCoverPhotoSize",
                    },
                  ],
                },
              ],
            },
          ],
        },
        tabsIds: [TABS.ADJUST, TABS.ANNOTATE], // or ['Adjust', 'Annotate', 'Watermark']
        defaultTabId: TABS.ANNOTATE, // or 'Annotate'
        defaultToolId: TOOLS.PEN, // or 'Text',
        translations: {
          name: "이름",
          save: "저장",
          saveAs: "다른이름으로 저장",
          back: "뒤로가기",
          loading: "Loading...",
          resetOperations: "모든 작업 재설정/삭제",
          changesLoseConfirmation: "모든 변경사항이 삭제됩니다",
          changesLoseConfirmationHint: "계속 진행 하시겠습니까?",
          cancel: "취소",
          continue: "계속",
          undoTitle: "마지막 작업 실행 취소",
          redoTitle: "마지막 작업 다시 실행",
          showImageTitle: "원본 이미지 표시",
          zoomInTitle: "확대",
          zoomOutTitle: "축소",
          toggleZoomMenuTitle: "확대/축소 메뉴 전환",
          adjustTab: "조정",
          finetuneTab: "미세 조정",
          filtersTab: "필터",
          watermarkTab: "워터마크",
          annotateTab: "그리기",
          resize: "크기 조정",
          resizeTab: "크기 조정",
          invalidImageError: "잘못된 이미지가 제공되었습니다.",
          uploadImageError: "이미지를 업로드하는 중 오류가 발생했습니다.",
          areNotImages: "이미지가 아닙니다",
          isNotImage: "이미지가 아닙니다",
          toBeUploaded: "업로드 예정",
          cropTool: "Crop",
          original: "Original",
          custom: "Custom",
          square: "Square",
          landscape: "Landscape",
          portrait: "Portrait",
          ellipse: "원",
          classicTv: "Classic TV",
          cinemascope: "Cinemascope",
          arrowTool: "화살표",
          blurTool: "흐림",
          brightnessTool: "명도",
          contrastTool: "차이",
          ellipseTool: "원",
          unFlipX: "X 뒤집기 해제",
          flipX: "X 뒤집기",
          unFlipY: "Y 뒤집기 해제",
          flipY: "Y 뒤집기",
          hsvTool: "HSV",
          hue: "색조",
          saturation: "포화",
          value: "값",
          imageTool: "이미지",
          importing: "가져오기...",
          addImage: "+ 이미지 추가",
          uploadImage: "이미지 업로드",
          fromGallery: "갤러리에서",
          lineTool: "선",
          penTool: "펜",
          polygonTool: "다각형",
          sides: "측면",
          rectangleTool: "사각형",
          cornerRadius: "코너 반경",
          resizeWidthTitle: "넓이(픽셀)",
          resizeHeightTitle: "높이(픽셀)",
          toggleRatioLockTitle: "토글 비율 잠금",
          reset: "초기화",
          resetSize: "원본 이미지 크기로 재설정",
          rotateTool: "회전",
          textTool: "테스트",
          textSpacings: "텍스트 간격",
          textAlignment: "텍스트 정렬",
          fontFamily: "글꼴",
          size: "크기",
          letterSpacing: "문자 간격",
          lineHeight: "줄 높이",
          warmthTool: "따뜻함",
          addWatermark: "+ 워터마크 추가",
          addTextWatermark: "+ 텍스트 워터마크 추가",
          addWatermarkTitle: "워터마크 유형을 선택하세요",
          uploadWatermark: "워터마크 업로드",
          addWatermarkAsText: "텍스트로 추가",
          padding: "여백",
          shadow: "그림자",
          horizontal: "수평",
          vertical: "수직",
          blur: "흐림",
          opacity: "불투명",
          position: "위치",
          stroke: "선",
          saveAsModalLabel: "이미지를 다른 이름으로 저장하세요",
          extension: "확대",
          nameIsRequired: "이름은 필수입니다.",
          quality: "품질",
          imageDimensionsHoverTitle: "저장된 이미지 크기(가로x세로)",
          cropSizeLowerThanResizedWarning:
            "참고: 선택한 자르기 영역은 적용된 크기 조정보다 작으므로 품질이 저하될 수 있습니다.",
          actualSize: "실제 크기(100%)",
          fitSize: "맞는 사이즈",
          addImageTitle: "추가할 이미지 선택...",
          mutualizedFailedToLoadImg: "이미지를 불러오지 못했습니다.",
        },
      };

      // Assuming we have a div with id="editor_container"
      const filerobotImageEditor = new FilerobotImageEditor(
        this.$refs.filerobot as HTMLElement,
        config
      );
      this.filerobotImageEditor = filerobotImageEditor;

      // filerobotImageEditor.updateState((state) => {
      //   console.log("state : ", state);
      // });
      const vm = this;
      filerobotImageEditor.render({
        onSave: () => {
          console.log("onSave");
          vm.confirm();
        },
        onClose: (closingReason) => {
          console.log("Closing reason", closingReason);
          filerobotImageEditor.terminate();
          this.cancel();
        },
      });
    },
    windowResize() {
      const app = this.app;
      const size = app.size;

      const isMobileSize = (this.isMobileSize = size.width < 1265);
      let bodyHeight = size.height;

      if (!isMobileSize) {
        bodyHeight = size.height / 2;
      }
      this.bodySize.height = bodyHeight;
      if (this.$refs.body) {
        const $el = this.$refs.body as any;
        $el.style.minHeight = `${bodyHeight}px`;
        $el.style.maxHeight = `${bodyHeight}px`;
        this.bodySize.width = $el.clientWidth;
        // console.log("$el.clientWidth : ", $el.clientWidth);
        // console.log("$el.scrollWidth : ", $el.scrollWidth);
      } else {
        console.log("not found body");
      }

      this.options.width = this.bodySize.width;
      this.options.height = this.bodySize.height - 5;

      this.options.cameraOffset.x = this.options.width / 2;
      this.options.cameraOffset.y = this.options.height / 2;
    },
    cancel() {
      const filerobotImageEditor = this.filerobotImageEditor;
      if (filerobotImageEditor != null) {
        filerobotImageEditor.terminate();
      }
      this.close(UpdateEventType.CANCEL);
    },
    confirm() {
      core.loader.show();
      setTimeout(() => {
        this.submit();
      }, 500);
    },
    submit() {
      const filerobotImageEditor = this.filerobotImageEditor;
      if (filerobotImageEditor != null) {
        const imgData = filerobotImageEditor.getCurrentImgData();

        // console.log("filerobotImageEditor : ", filerobotImageEditor);
        // console.log("imgData : ", imgData);

        // const createEl = document.createElement("a");
        // createEl.href = imgData.imageData.imageBase64;
        // createEl.download = "download-this-canvas";
        // createEl.click();
        // createEl.remove();
        const dataUri = imgData.imageData.imageBase64;
        const result = {
          filename: this.properties.params.filename,
          dataUri: dataUri,
          blob: core.utils.image.dataURItoBlob(dataUri),
        };
        filerobotImageEditor.terminate();
        this.close(UpdateEventType.CONFIRM, result);
      }
      core.loader.hide();
    },
  },
});
