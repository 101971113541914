import Vue from "vue";

export default Vue.extend({
  data: () => ({
    viewHintStorageItems: {
      loading: true,
      _promise: {
        loading: {} as any,
        loadingResolve: {} as any,
      },
      items: {} as any,
    },
  }),
  async created() {
    const storageItems = this.viewHintStorageItems;

    storageItems._promise.loading = new Promise((_resolve) => {
      storageItems._promise.loadingResolve = _resolve;
    });

    storageItems.items = await this.$store.dispatch("storage/getStorageItem", "close-view-hint");
    storageItems.loading = false;
    storageItems._promise.loadingResolve();
  },
  methods: {
    viewHintStorageWait() {
      const storageItems = this.viewHintStorageItems;
      if (!storageItems.loading) {
        return new Promise((resolve: any, reject) => {
          resolve();
        });
      }
      return storageItems._promise.loading;
    },
    saveViewHintStorageItems() {
      const vm = this as any;
      vm.$store.dispatch("storage/setStorageItem", {
        type: "close-view-hint",
        items: vm.viewHintStorageItems.items,
      });
    },
    getViewHintStorageItems(key?: string) {
      if (key !== undefined) {
        return this.viewHintStorageItems.items[key];
      }
      return this.viewHintStorageItems.items;
    },
    setViewHintStorageItem(key: string, value: any) {
      // console.log("setStorageItem key : " + key + ", value : ", value);
      const vm = this as any;
      const storageItems = vm.viewHintStorageItems;
      const items = storageItems.items;
      items[key] = value;
      this.saveViewHintStorageItems();
    },
    removeViewHintStorageItems(key: string | null) {
      // console.log("removeStorageItem key : ", key);
      const vm = this as any;
      const storageItems = vm.viewHintStorageItems;
      if (key == null) {
        storageItems.items = {};
      } else {
        delete storageItems.items[key];
      }
      this.saveViewHintStorageItems();
    },
    closeViewHint(key: string) {
      const item = this.getViewHintStorageItems();
      item[key] = true;
      this.saveViewHintStorageItems();
    },
  },
});
