
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import NotificationService from "@/services/notification/notification.service";
import core from "@/core";

export default mixins(MixinsModalForm).extend({
  name: "NotificationViewModal",
  data: () => ({
    page: "",
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const item = this.properties.params.item;
      if (item != null) {
        if (item.confirmedAt == null) {
          const _item = await NotificationService.update(item.id, "");
          core.utils.copy(_item, this.properties.params.item);
        }
        if (item.addData != null && this.isNotBlank(item.addData.path)) {
          this.page = item.addData.path;
        }
      }
    });
  },
  methods: {
    movePage(page) {
      this.$router.push(page);
    },
  },
});
