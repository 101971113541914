
import mixins from "vue-typed-mixins";
import store from "@/store";
import Constant from "@/store/constant";
import MixinsCommon from "@/mixins/single/common";
import MenuListItemComponent from "@/components/core/MenuListItemComponent.vue";
import { TableOrder } from "@/types";
import { NavMenuId, NavMenuType } from "@/models/core/nav-menu.model";
import SupportAsService from "@/services/support/support-as.service";

export default mixins(MixinsCommon).extend({
  name: "NavigationDrawer",
  components: { MenuListItemComponent },
  data: () => ({
    app: store.state.app,
    auth: store.state.auth,
  }),
  computed: {
    NavMenuType() {
      return NavMenuType;
    },
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
    Constant() {
      return Constant;
    },
    title() {
      return this.$store.getters["topToolbar/title"];
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  mounted() {
    this.checkMenu();
    this.changedShowNav();
  },
  watch: {
    $route(to, from) {
      this.loadSupportAsCount();
    },
    "app.showNav"(show) {
      this.changedShowNav();
    },
  },
  methods: {
    changedShowNav() {
      const show = this.app.showNav;
      if (show) {
        if (!this.app.isMobileSize) {
          document.documentElement.style.setProperty("--nav", `300px`);
        } else {
          document.documentElement.style.setProperty("--nav", `0px`);
        }
      } else {
        document.documentElement.style.setProperty("--nav", `0px`);
      }
    },
    checkMenu() {
      setTimeout(() => {
        if (this.app.navMenuList.length > 0) {
          this.loadSupportAsCount();
        } else {
          this.checkMenu();
        }
      }, 500);
    },
    async loadSupportAsCount() {
      const user = await this.$store.getters["auth/user"]();

      const params = {
        draw: 0,
        start: 0,
        length: 1,
        orderColumnName: "createdAt",
        order: TableOrder.DESC,
        searchColumns: {
          activated: true,
          status: "RECEIPT",
          userId: user.id,
        },
      };
      SupportAsService.getTable(params).then((result: any) => {
        //console.log("result : ", result);
        const count = result.recordsTotal;
        this.app.navMenuList.some((menu) => {
          if (menu.id === NavMenuId.supportAs) {
            if (menu.badge) {
              if (count > 0) {
                menu.badge.content = String(count);
              } else {
                menu.badge.content = "";
              }
            }
            return true;
          }
        });
      });
    },
  },
});
