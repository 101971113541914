import core from "@/core";
import { TableRequest } from "@/types";

class EventService {
  get(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/p/event/${id}`, null)
        .then((data: any) => {
          resolve(data.event);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/event/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  existsPlace(placeId) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/event/place/${placeId}/exists`, null)
        .then((data: any) => {
          resolve(data.exists);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/event`, strParams)
        .then((data: any) => {
          if (data.event != null) {
            resolve(data.event);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/event/${id}`, strParams)
        .then((data: any) => {
          if (data.event != null) {
            resolve(data.event);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/event/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateEventStatusActive(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/event/${id}/status/active`)
        .then((data: any) => {
          if (data.event != null) {
            resolve(data.event);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createCategoryRouletteList(params) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/event/category-roulette`, strParams)
        .then((data: any) => {
          if (data.eventCategory != null) {
            resolve(data.eventCategory);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getCategoryRouletteList(id: number) {
    // /category-roulette
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/p/event/${id}/category-roulette`, null)
        .then((data: any) => {
          resolve(data.eventCategoryList);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getCategoryRoulette(id: number, categoryId: number) {
    // /category-roulette
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/p/event/${id}/category-roulette/${categoryId}`, null)
        .then((data: any) => {
          resolve(data.eventCategory);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  rouletteTest(params) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/event/roulette/test`, strParams)
        .then((data: any) => {
          if (data.winner != null) {
            resolve(data.winner);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getRouletteWinnerList(params) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/event/roulette/winner`, { ignoreAlertModal: true }, params)
        .then((data: any) => {
          resolve(data.rouletteWinnerList);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getRouletteWinnerTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/event/roulette/winner/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateRouletteWinner(id, params) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/event/roulette/winner/${id}`, strParams)
        .then((data: any) => {
          if (data.winner != null) {
            resolve(data.winner);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
  deleteRouletteWinner(id) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/event/roulette/winner/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new EventService();
