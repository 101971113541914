
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import EstimateService from "@/services/work/estimate.service";
import core from "@/core";
import UpdateEvent from "@/models";
import DownloadingModal from "@/modals/core/DownloadingModal.vue";
import EstimateUtils from "@/models/estimate";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    placeId: "아파트 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "CounselEstimateDownload",
  components: { DownloadingModal },

  data: () => ({
    title: "",

    dateTimeFormatPattern: "YYYY년 MM월 DD일 HH시 mm분",

    form: {
      placeIdList: [] as any,
      searchDates: [],
    },
    datePicker: {
      visible: false,
    },

    view: {
      placeName: "",
      searchDateText: "",
    },
    placeList: [] as any,

    // 선택된 아파트
    selectedPlace: null as any,

    placeTypeList: [] as any, // 선택된 아파트 타입 목록

    modal: {
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      placeType: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      downloading: {
        visible: false,
        html: "데이터 다운로드를 시작합니다",
        callback: null as any,
      },
    },
    isDestroyed: false,
  }),
  created() {
    this.title = "상담내역 다운로드";

    const searchDates = this.form.searchDates as any;

    searchDates.push(core.date.instance().add(-1, "month").format("YYYY-MM-DD"));
    searchDates.push(core.date.instance().format("YYYY-MM-DD"));
  },
  mounted() {
    this.$nextTick(async () => {
      core.loader.show();
      try {
        this.placeList = await this.$store.getters["app/getPlaceList"]();
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    });
  },
  computed: {
    today() {
      return core.date.instance().format("YYYY-MM-DD");
    },
    searchDateText() {
      const searchDates = this.form.searchDates as any;
      if (searchDates != null && searchDates.length > 1) {
        const moment1 = core.date.instance(searchDates[0]);
        const moment2 = core.date.instance(searchDates[1]);
        if (moment1.isAfter(moment2)) {
          const temp = searchDates[0];
          searchDates[0] = searchDates[1];
          searchDates[1] = temp;
        }
        return this.form.searchDates.join(" ~ ");
      }
      return "";
    },
  },
  watch: {
    "view.placeName"(val) {
      if (!this.isNotBlank(val)) {
        this.form.placeIdList = [];
      }
    },
  },
  destroyed() {
    this.isDestroyed = true;
  },
  methods: {
    isNotBlank(val) {
      return core.utils.validate.isNotBlank(val);
    },
    async submit() {
      // const params = {} as any;
      // core.loader.show("파일 생성중...");
      // console.log("this.form.placeIdList : ", this.form.placeIdList);
      // if (this.form.placeIdList != null && this.form.placeIdList.length > 0) {
      //   params.placeIdList = this.form.placeIdList.join(",");
      // }
      // if (this.form.searchDates != null && this.form.searchDates.length > 1) {
      //   params.createdAtGreaterThanEqual = this.form.searchDates[0];
      //   params.createdAtLessThan = core.date
      //     .instance(this.form.searchDates[1])
      //     .add(1, "days")
      //     .format("YYYY-MM-DD");
      // }
      //
      // try {
      //   console.log("params : ", params);
      //   const response = (await EstimateService.downloadExcel(params)) as any;
      //   // response.downloadUri;
      //   //console.log("response : ", response);
      //   core.loader.show("다운로드중...");
      //   await core.http.downloadFile(response.downloadUri);
      // } catch (e) {
      //   console.log(e);
      // }
      // core.loader.hide();
      this.exportCsv();
    },
    async exportCsv() {
      const items = {
        page: 1,
        perPage: 5000,
        searchColumns: {},
      } as any;

      if (this.form.searchDates != null && this.form.searchDates.length > 1) {
        items.searchColumns.createdAtGreaterThanEqual = this.form.searchDates[0];
        items.searchColumns.createdAtLessThan = core.date
          .instance(this.form.searchDates[1])
          .add(1, "days")
          .format("YYYY-MM-DD");
      } else {
        return;
      }

      const modal = this.modal.downloading;
      modal.visible = true;
      modal.html = "상담내역 데이터 다운로드를 시작합니다.";

      const filename = `상담내역 데이터_${core.date.instance().format("YYYYMMDD_HHMM")}.csv`;
      // const titleList = ["시간", "온도", "습도", "메탄"];
      const csv = [] as any;
      {
        const row = [] as any;
        row.push(
          "상담사",
          "상담시작시간",
          "줄눈시작시간",
          "탄성코트시작시간",
          "청소시작시간",
          "나노코팅시작시간",
          "새집증후군시작시간",
          "상담종료시간",
          "상담시간",
          "상담시간(초)",
          "아파트",
          "동",
          "호",
          "계약상태"
        );
        csv.push(row.join(","));
      }
      const map = new Map();
      let latestTime = "";

      let running = true;
      let done = false;
      modal.callback = () => {
        console.log("cancel ");
        running = false;
      };
      while (running) {
        if (this.isDestroyed) {
          break;
        }
        const params = {
          start: (items.page - 1) * items.perPage,
          length: items.perPage,
          orderColumnName: "createdAt",
          order: "asc",
          searchColumns: items.searchColumns,
        } as any;
        modal.html = `상담내역 데이터를 다운로드 중입니다`;
        if (params.start > 0) {
          modal.html += `<br>${params.start}개 데이터 저장중...(최종 상담시간 ${latestTime})`;
        }

        // const startMoment = core.date.instance(this.startDate.date);
        // startMoment.hours(this.startDate.time);
        // params.startAt = startMoment.format("YYYY-MM-DD HH:00:00");
        //
        // const endMoment = core.date.instance(this.endDate.date);
        // endMoment.hours(this.endDate.time);
        // endMoment.add(1, "minutes");
        // params.endAt = endMoment.format("YYYY-MM-DD HH:00:00");

        const tableResponse = (await EstimateService.getCounselTable(params)) as any;
        const counselEstimateList = tableResponse.data;
        let addCount = 0;
        counselEstimateList.forEach((data) => {
          if (!map.has(data.id)) {
            map.set(data.id, data);
            const row = [] as any;
            let createdUser = "";
            let dong = "";
            let ho = "";
            let placeName = "";
            let contract = "";
            if (data.place) placeName = data.place.name;
            if (data.estimate) dong = data.estimate.dong;
            if (data.estimate) ho = data.estimate.ho;
            if (data.createdUser)
              createdUser = `${data.createdUser.name} (${data.createdUser.username})`;
            if (data.estimate) contract = EstimateUtils.contractStatusToText(data.estimate);
            let category1StartAt = "";
            let category2StartAt = "";
            let category3StartAt = "";
            let category4StartAt = "";
            let category5StartAt = "";
            if (data.more != null) {
              if (data.more.category1StartAt) {
                category1StartAt = core.date
                  .instance(data.more.category1StartAt)
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              if (data.more.category2StartAt) {
                category2StartAt = core.date
                  .instance(data.more.category2StartAt)
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              if (data.more.category3StartAt) {
                category3StartAt = core.date
                  .instance(data.more.category3StartAt)
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              if (data.more.category4StartAt) {
                category4StartAt = core.date
                  .instance(data.more.category4StartAt)
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              if (data.more.category5StartAt) {
                category5StartAt = core.date
                  .instance(data.more.category5StartAt)
                  .format("YYYY-MM-DD HH:mm:ss");
              }
            }

            row.push(
              createdUser,
              core.date.instance(data.startAt).format("YYYY-MM-DD HH:mm:ss"),
              category1StartAt,
              category2StartAt,
              category3StartAt,
              category4StartAt,
              category5StartAt,
              data.endAt != null
                ? core.date.instance(data.endAt).format("YYYY-MM-DD HH:mm:ss")
                : "",
              this.elapsedTimeString(data),
              this.elapsedTimeSec(data),
              placeName,
              dong,
              ho,
              contract
            );
            csv.push(row.join(","));
            addCount++;
          }
        });
        if (counselEstimateList.length > 0) {
          latestTime = core.date
            .instance(counselEstimateList[counselEstimateList.length - 1].createdAt)
            .format("YYYY-MM-DD HH:mm:ss");
        }
        if (addCount === 0) {
          running = false;
          done = true;
          break;
        }
        items.page++;
      }

      if (!done) {
        const confirmResult = await core.alert.show({
          title: "확인",
          body: `지금까지 다운로드된 ${map.size}개 데이터를 저장하시겠습니까?`,
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "저장",
        });
        if (confirmResult === "confirm") {
          done = true;
        }
      }

      if (done && map.size > 0) {
        this.downloadCsv(csv.join("\n"), filename);
      }

      modal.visible = false;
    },
    downloadCsv(csv, filename) {
      // 한글 처리를 해주기 위해 BOM 추가
      const BOM = "\uFEFF";
      csv = BOM + csv;

      const csvFile = new Blob([csv], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },

    elapsedTimeString(item) {
      let text = "";
      // dateTimeFormat(item.startAt) }} ~ {{ dateTimeFormat(item.endAt) }} ({{
      // elapsedTimeString(item)
      if (item.endAt) {
        const endMoment = core.date.instance(item.endAt);
        const startMoment = core.date.instance(item.startAt);
        //console.log("endMoment : ", endMoment.format("YYYY-MM-DD HH:mm:ss"));
        //console.log("startMoment : ", startMoment.format("YYYY-MM-DD HH:mm:ss"));
        const diffSec = endMoment.diff(startMoment, "seconds");
        if (diffSec < 60) {
          return `${diffSec}초`;
        }
        const diffMin = endMoment.diff(startMoment, "minutes");
        console.log("diffMin : ", diffMin);
        if (diffMin < 60) {
          return `${diffMin}분`;
        }

        const diffhour = endMoment.diff(startMoment, "hours");
        console.log("diffhour : ", diffhour);
        return `${diffhour}시간`;
      }
      return text;
    },
    elapsedTimeSec(item) {
      // dateTimeFormat(item.startAt) }} ~ {{ dateTimeFormat(item.endAt) }} ({{
      // elapsedTimeString(item)
      if (item.endAt) {
        const endMoment = core.date.instance(item.endAt);
        const startMoment = core.date.instance(item.startAt);
        //console.log("endMoment : ", endMoment.format("YYYY-MM-DD HH:mm:ss"));
        //console.log("startMoment : ", startMoment.format("YYYY-MM-DD HH:mm:ss"));
        const diffSec = endMoment.diff(startMoment, "seconds");

        return diffSec;
      }
      return "";
    },
  },
});
