
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import ScheduleUtils, { ScheduleViewType } from "@/models/schedule";
import store from "@/store";
import ScheduleAddEditComponent from "@/components/schedule/AddEditComponent.vue";
import ScheduleVacationAddEditComponent from "@/components/schedule/VacationAddEditComponent.vue";
import UpdateEvent from "@/models";

export default mixins(MixinsPageForm).extend({
  name: "ScheduleAddEdit",
  components: {
    ScheduleVacationAddEditComponent,
    ScheduleAddEditComponent,
  },
  data: () => ({
    app: store.state.app,
    viewType: ScheduleViewType.ALL,
    title: "",
    type: "",
    component: {
      updateEvent: null as UpdateEvent | null,
    },
  }),

  created() {
    const params = this.$route.params as any;
    const type = (this.type = params.type as string);

    const query = this.$route.query as any;
    let viewType = ScheduleUtils.getViewType(query.viewType);
    if (viewType == null) {
      if (this.isBlank(query.estimateId)) {
        viewType = ScheduleViewType.ALL;
      } else {
        viewType = ScheduleViewType.ESTIMATE;
      }
    }
    this.viewType = viewType;
    this.changeTitle();
  },
  activated() {
    this.component.updateEvent = this.getPageUpdateEvent();
  },
  methods: {
    changeViewType(viewType) {
      this.viewType = viewType;
      this.changeTitle();
    },
    changeTitle() {
      const type = this.type;
      const viewType = this.viewType;
      if (type === "add") {
        this.title = "일정 추가";
        if (viewType === ScheduleViewType.VACATION) {
          this.title = "휴무 추가";
        }
      } else if (type === "edit") {
        this.title = "일정 수정";
        if (viewType === ScheduleViewType.VACATION) {
          this.title = "휴무 수정";
        }
      } else if (type === "as") {
        this.title = "A/S 추가";
      }
    },
  },
});
