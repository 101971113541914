
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import { UpdateEventType } from "@/types";
import core from "@/core";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    orderColumnName: "정렬",
    order: "정렬 순서",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "ScheduleSortModal",
  data: () => ({
    formProcessing: false,
    form: {
      orderColumnName: "id",
      order: "asc",
    },
    sortItems: [
      { id: "id", text: "기본" },
      { id: "title", text: "제목" },
      { id: "estimate.place.name", text: "아파트" },
    ],
  }),
  mounted() {
    this.$nextTick(async () => {
      if (this.isCompanyAdminRoleHigher) {
        this.sortItems.push({
          id: "totalPrice",
          text: "금액",
        });
      }
      const params = this.properties.params;
      this.form.orderColumnName = params.sort.orderColumnName;
      this.form.order = params.sort.order;
    });
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        this.close(UpdateEventType.CONFIRM, core.utils.deepCopy(this.form));
      }
    },
  },
});
