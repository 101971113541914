class FlowerFall {
  private particleColors = {
    colorOptions: [
      "DodgerBlue",
      "OliveDrab",
      "Gold",
      "pink",
      "SlateBlue",
      "lightblue",
      "Violet",
      "PaleGreen",
      "SteelBlue",
      "SandyBrown",
      "Chocolate",
      "Crimson",
    ],
    colorIndex: 0,
    colorIncrementer: 0,
    colorThreshold: 10,
    getColor: function () {
      if (this.colorIncrementer >= 10) {
        this.colorIncrementer = 0;
        this.colorIndex++;
        if (this.colorIndex >= this.colorOptions.length) {
          this.colorIndex = 0;
        }
      }
      this.colorIncrementer++;
      return this.colorOptions[this.colorIndex];
    },
  };
  private reactivationTimerHandler: number | null = null;
  private animationHandler: any = null;
  private confettiActive = false;
  private animationComplete = false;
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private width: number;
  private height: number;
  private particles: ConfettiParticle[] = [];
  private maxParticles = 150;
  private angle = 0;
  private tiltAngle = 0;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    //console.log("canvas : ", canvas);
    this.width = canvas.width;
    this.height = canvas.height;

    //console.log("width : ", this.width, ", height : ", this.height);
  }

  private initializeConfetti() {
    this.particles = [];
    this.animationComplete = false;
    for (let i = 0; i < this.maxParticles; i++) {
      const particleColor = this.particleColors.getColor();
      this.particles.push(
        new ConfettiParticle(this.width, this.height, this.maxParticles, particleColor, this.ctx)
      );
    }
    this.startConfetti();
  }

  startConfetti() {
    this.animloop(this);
  }

  private animloop(vm) {
    if (vm.animationComplete) return null;
    vm.animationHandler = requestAnimationFrame(() => {
      //console.log("callback");
      vm.animloop(vm);
    });
    return this.draw();
  }

  private draw() {
    this.ctx.clearRect(0, 0, this.width, this.height);
    const results = [] as any;
    for (let i = 0; i < this.maxParticles; i++) {
      ((j) => {
        results.push(this.particles[j].draw());
      })(i);
    }
    this.update();

    return results;
  }

  private update() {
    let remainingFlakes = 0;
    let particle;
    this.angle += 0.01;
    this.tiltAngle += 0.1;

    for (let i = 0; i < this.maxParticles; i++) {
      particle = this.particles[i];
      if (this.animationComplete) return;

      if (!this.confettiActive && particle.y < -15) {
        particle.y = this.height + 100;
        continue;
      }

      this.stepParticle(particle, i);

      if (particle.y <= this.height) {
        remainingFlakes++;
      }
      this.checkForReposition(particle, i);
    }

    if (remainingFlakes === 0) {
      this.stopConfetti();
    }
  }

  private stepParticle(particle, particleIndex) {
    particle.tiltAngle += particle.tiltAngleIncremental;
    particle.y += (Math.cos(this.angle + particle.d) + 3 + particle.r / 2) / 2;
    particle.x += Math.sin(this.angle);
    particle.tilt = Math.sin(particle.tiltAngle - particleIndex / 3) * 15;
  }

  private checkForReposition(particle, index) {
    if (
      (particle.x > this.width + 20 || particle.x < -20 || particle.y > this.height) &&
      this.confettiActive
    ) {
      if (index % 5 > 0 || index % 2 == 0) {
        //66.67% of the flakes
        this.repositionParticle(
          particle,
          Math.random() * this.width,
          -10,
          Math.floor(Math.random() * 10) - 20
        );
      } else {
        if (Math.sin(this.angle) > 0) {
          //Enter from the left
          this.repositionParticle(
            particle,
            -20,
            Math.random() * this.height,
            Math.floor(Math.random() * 10) - 20
          );
        } else {
          //Enter from the right
          this.repositionParticle(
            particle,
            this.width + 20,
            Math.random() * this.height,
            Math.floor(Math.random() * 10) - 20
          );
        }
      }
    }
  }

  private repositionParticle(particle, xCoordinate, yCoordinate, tilt) {
    particle.x = xCoordinate;
    particle.y = yCoordinate;
    particle.tilt = tilt;
  }

  private restartConfetti() {
    this.clearTimers();
    this.stopConfetti();
    const vm = this;
    this.reactivationTimerHandler = setTimeout(function () {
      vm.confettiActive = true;
      vm.animationComplete = false;
      vm.initializeConfetti();
    }, 100);
  }

  private deactivateConfetti() {
    this.confettiActive = false;
    this.clearTimers();
  }

  private clearTimers() {
    if (this.reactivationTimerHandler) {
      clearTimeout(this.reactivationTimerHandler);
    }
    clearTimeout(this.animationHandler);
  }

  private stopConfetti() {
    this.animationComplete = true;
    if (this.ctx == undefined) return;
    this.ctx.clearRect(0, 0, this.width, this.height);
  }

  start() {
    this.restartConfetti();
  }

  stop() {
    this.deactivateConfetti();
  }
}

class ConfettiParticle {
  x: number;
  y: number;
  r: number;
  d: number;
  color: string;
  tilt: number;
  tiltAngleIncremental: number;
  tiltAngle: number;
  ctx: CanvasRenderingContext2D;

  constructor(width, height, maxParticles, color, ctx) {
    this.x = Math.random() * width; // x-coordinate
    this.y = Math.random() * height - height; //y-coordinate
    this.r = this.randomFromTo(10, 15); //radius;
    this.d = Math.random() * maxParticles + 10; //density;
    this.color = color;
    this.tilt = Math.floor(Math.random() * 10) - 10;
    this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
    this.tiltAngle = 0;
    this.ctx = ctx;
  }

  draw() {
    this.ctx.beginPath();
    this.ctx.lineWidth = this.r / 2;
    this.ctx.strokeStyle = this.color;
    this.ctx.moveTo(this.x + this.tilt + this.r / 4, this.y);
    this.ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 4);
    return this.ctx.stroke();
  }

  randomFromTo(from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from);
  }
}

export default FlowerFall;
