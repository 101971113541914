import core from "@/core";
import { TableRequest } from "@/types";
import { HttpRequestCache } from "@/models/http-request-cache";

class CategoryService {
  private categoryCache = new HttpRequestCache();

  getTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/category/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    //{ name: name }
    return new Promise((resolve: any, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .postJson(`/api/work/category`, strJson)
        .then((data: any) => {
          resolve(data.category);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`/api/work/category/${id}`, strJson)
        .then((data: any) => {
          resolve(data.category);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/category/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getList() {
    return new Promise((resolve: any, reject) => {
      const cache = this.categoryCache;
      if (cache.time != null) {
        const curTime = new Date();
        const elapsedTimeMillis = curTime.getTime() - cache.time.getTime();
        if (elapsedTimeMillis < 1000) {
          // 1초 미만 호출 시 캐싱한 데이터 반환
          cache.time = curTime;
          resolve(cache.item);
          console.log("cache data");
          return;
        }
      }

      core.http
        .getJson(`/api/work/category`)
        .then((data: any) => {
          const item = data.categoryList;
          if (item == null) {
            reject({
              message: "not found item",
            });
          } else {
            cache.item = item;
            cache.time = new Date();
            resolve(item);
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new CategoryService();
