
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import store from "@/store";
import core from "@/core";
import EstimateAddEditComponent from "@/components/estimate/AddEditComponent.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import ExpoService from "@/services/expo/expo.service";
import { ExpoItem, ExpoItemType, MainTreeRes } from "@/views/exposition/Edit.vue";
import { cloneDeep } from "lodash";

export interface LinkItem {
  isTitle: boolean;
  id: string;
  text: string;
}

export default mixins(MixinsPage).extend({
  name: "Exposition",
  components: { EstimateAddEditComponent },
  data: () => ({
    scrollTop: 0,
    updated: false,
    curDate: "",
    categoryList: [] as any,
    expoEventList: [] as any,
    component: {
      estimate: {
        type: "exposition",
        updateEvent: null as UpdateEvent | null,
      },
    },
    fullscreen: false,
    app: store.state.app,
    linkList: [] as LinkItem[],
    visibleSlide: true,
    defaultMainTree: null as MainTreeRes | null,
    expositionItems: {},
    mainTreeList: [] as MainTreeRes[],
    selectedLink: null as LinkItem | null,
    ready: false,
  }),
  computed: {
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  mounted() {
    this.$nextTick(async () => {
      let expoList = null as any;
      try {
        const expo = (await ExpoService.get()) as any;
        expoList = expo.list;
      } catch (e) {
        console.log(e);
      }
      const expositionItems = {} as any;
      if (expoList != null) {
        this.mainTreeList = expoList;
        // console.log("expoList : ", expoList);
        expoList.forEach((mainTree: MainTreeRes) => {
          if (mainTree.categoryId === 0) {
            this.defaultMainTree = mainTree;
          } else {
            expositionItems[mainTree.categoryId] = mainTree;
            // console.log("mainTree : ", mainTree);
          }
        });
      }
      // console.log("expositionItems : ", expositionItems);
      this.expositionItems = expositionItems;

      const el = this.$refs.body as any;
      // console.log("el : ", el);
      el.addEventListener("scroll", () => {
        this.scrollTop = el.scrollTop;
      });
      this.ready = true;
    });

    const vm = this as any;
    let count = 0;
    const interval = setInterval(() => {
      // console.log("interval : ", count++);
      if (vm._isDestroyed) {
        clearInterval(interval);
      } else {
        vm.curDate = core.date.instance().format("YYYY년 MM월 DD일(ddd) a h:mm:ss");
      }
    }, 300);
  },
  watch: {
    "component.estimate.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.component.estimate);
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          const item = event.item;
          if (item.type === "changedCategory") {
            // const categoryList = item.categoryList;
            const categoryList = cloneDeep(item.categoryList);
            categoryList.forEach((category) => {
              // console.log("category : ", category);
              const mainTree = this.expositionItems[category.id];
              // console.log("mainTree : ", mainTree);
              category.mainTree = mainTree;
            });
            this.categoryList = categoryList;
            // console.log("categoryList : ", categoryList);
          } else if (item.type === "selectedExpoEventList") {
            // const categoryList = item.categoryList;
            this.expoEventList = item.expoEventList;
            this.changedLinkList();
            // console.log("categoryList : ", categoryList);
          }
        }
      }
    },
    categoryList(categoryList) {
      // console.log("categoryList : ", categoryList);
      this.changedLinkList();
    },
    selectedLink(selectedLink) {
      console.log("selectedLink : ", selectedLink);
      if (selectedLink != null) {
        console.log("changed selected link : ", selectedLink.id, selectedLink);
        const el = document.getElementById(selectedLink.id);
        if (el != null) {
          //console.log("스크롤 이동 : ", selectedLink.key, el);
          el.scrollIntoView(true);
        } else {
          console.log("not found element id : ", selectedLink.id);
        }
      }
    },
  },
  methods: {
    showPage(item: any) {
      if (item.type === ExpoItemType.LINK) {
        window.open(String(item.address), "_blank");
      }
    },
    moveEditPage() {
      console.log("move edit page");
    },
    showSlide() {
      this.visibleSlide = true;
    },
    closeSlide() {
      this.visibleSlide = false;
    },
    changedLinkList() {
      const expoEventList = this.expoEventList;
      const categoryList = this.categoryList;
      const linkList = [] as LinkItem[];
      if (expoEventList != null && expoEventList.length > 0) {
        expoEventList.forEach((event) => {
          linkList.push({
            isTitle: true,
            id: `expo_${event.id}`,
            text: event.name,
          });
        });
      }
      if (categoryList != null && categoryList.length > 0) {
        const expositionItems = this.expositionItems;
        categoryList.forEach((category) => {
          linkList.push({
            isTitle: true,
            id: `link_${category.id}`,
            text: category.name,
          });
          const mainTree = expositionItems[category.id] as MainTreeRes;
          // console.log("mainTree : ", mainTree);
          if (mainTree) {
            const subTreeList = mainTree.childrenList;
            if (subTreeList) {
              // console.log("subTreeList : ", subTreeList);
              subTreeList.forEach((subTree) => {
                linkList.push({
                  isTitle: false,
                  id: `link_${subTree.id}`,
                  text: `- ${subTree.name}`,
                });
              });
            }
          }
        });
      }
      this.linkList = linkList;
      this.selectedLink = null;
    },
    moveTag(selectedLink: LinkItem) {
      this.selectedLink = selectedLink;

      const linkId = "link_" + selectedLink.id;
      const el = document.getElementById(linkId);
      if (el != null) {
        //console.log("스크롤 이동 : ", id, el);
        el.scrollIntoView(true);
      }
    },
    previousTag() {
      this.findLink("previous");
    },
    nextTag() {
      this.findLink("next");
    },
    findLink(type: string) {
      const linkList = this.linkList;
      const selectedLink = this.selectedLink;
      // console.log("linkList : ", linkList);

      if (type === "next") {
        const length = linkList.length;
        for (let i = 0; i < length; i++) {
          const link = linkList[i];
          // console.log("link : ", link);
          if (selectedLink == null || selectedLink.id != link.id) {
            const el = document.getElementById(link.id);
            if (el != null) {
              if (el.offsetTop > this.scrollTop) {
                console.log("find link : ", link.id, el.offsetTop, "scrollTop: ", this.scrollTop);
                this.selectedLink = link;
                break;
              }
            }
          }
        }
      } else {
        const length = linkList.length;
        for (let i = length - 1; i >= 0; i--) {
          const link = linkList[i];
          if (selectedLink == null || selectedLink.id != link.id) {
            const el = document.getElementById(link.id);
            if (el != null) {
              if (el.offsetTop < this.scrollTop) {
                console.log("find link : ", link.id, el.offsetTop, "scrollTop: ", this.scrollTop);
                this.selectedLink = link;
                break;
              }
            }
          }
        }
      }
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        const doc = document.documentElement as any;
        if (doc.requestFullscreen) {
          doc.requestFullscreen();
        } else if (doc.webkitRequestFullscreen) {
          // Chrome, Safari (webkit)
          doc.webkitRequestFullscreen();
        } else if (doc.mozRequestFullScreen) {
          // Firefox
          doc.mozRequestFullScreen();
        } else if (doc.msRequestFullscreen) {
          // IE or Edge
          doc.msRequestFullscreen();
        }
      } else {
        const doc = document as any;
        if (doc.exitFullscreen) {
          doc.exitFullscreen();
        } else if (doc.webkitExitFullscreen) {
          // Chrome, Safari (webkit)
          doc.webkitExitFullscreen();
        } else if (doc.mozCancelFullScreen) {
          // Firefox
          doc.mozCancelFullScreen();
        } else if (doc.msExitFullscreen) {
          // IE or Edge
          doc.msExitFullscreen();
        }
      }
    },
  },
});
