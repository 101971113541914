import core from "@/core";

class ContractStatusService {
  getList() {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/contract-status`)
        .then((data: any) => {
          const item = data.contractStatusList;
          if (item == null) {
            reject({
              message: "not found item",
            });
          } else {
            resolve(item);
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ContractStatusService();
