import core from "@/core";

class ScheduleTempService {
  get(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule/temp/${id}`)
        .then((data: any) => {
          if (data.schedule != null) {
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getBySearchColumns(searchColumns: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule/temp`, null, searchColumns)
        .then(async (data: any) => {
          if (data.scheduleList != null) {
            resolve(data.scheduleList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSimpleBySearchColumns(searchColumns: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule/temp/simple`, null, searchColumns)
        .then(async (data: any) => {
          if (data.scheduleList != null) {
            resolve(data.scheduleList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ScheduleTempService();
