import core from "@/core";
import { TableRequest } from "@/types";

class UnitPriceService {
  getScope(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/scope/${id}`)
        .then((data: any) => {
          //console.log(data);
          const scope = data.scope;
          if (scope != null) {
            resolve(scope);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/scope/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    return new Promise((resolve: any, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .postJson(`/api/work/scope`, strJson)
        .then((data: any) => {
          resolve(data.scope);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`/api/work/scope/${id}`, strJson)
        .then((data: any) => {
          resolve(data.scope);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/scope/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getScopeList() {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/scope`)
        .then((data: any) => {
          if (data.scopeList != null) {
            resolve(data.scopeList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new UnitPriceService();
