
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { ComponentProperties } from "@/mixins/component";
import core from "@/core";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    categoryId: "품목 ",
  },
});
export default mixins(MixinsModalForm).extend({
  name: "EventSelectCategoryModal",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            item: null as any,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  data: () => ({
    categoryList: [] as any,
    form: {
      categoryId: null as any,
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      this.categoryList = this.properties.params.item;
    });
  },
  watch: {},
  methods: {
    async submit() {
      //console.log("submit");
      if (await this.validate()) {
        //console.log("form : ", this.form);
        const form = core.utils.deepCopy(this.form);
        this.close(UpdateEventType.CONFIRM, form);
      }
    },
  },
});
