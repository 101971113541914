
import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";

export default mixins(MixinsPage).extend({
  name: "Settings",
  data: () => ({
    settings: {
      notification: false,
      receiveCall: false,
    },
    processing: false,
    watch: null as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(() => {
      // 모바일 앱으로 접속했을 경우 전화 수신 여부 활성화
      if (this.app.isApp) {
        this.watch = this.$store.watch(
          () => this.$store.state.auth.mobileSetting,
          (mobileSetting) => {
            this.processing = false;
            this.settings.receiveCall = mobileSetting.receiveCall;
            console.log("changed mobileSettings : ", mobileSetting);
          }
        );

        if (this.$store.state.auth.mobileSetting != null) {
          this.settings.receiveCall = this.$store.state.auth.mobileSetting.receiveCall;
        }

        const query = this.$route.query;
        if (query.action === "receiveCall") {
          this.updateReceiveCall();
        }
      }
    });
  },
  destroyed() {
    if (this.watch) {
      this.watch();
    }
  },
  watch: {},
  methods: {
    updateReceiveCall() {
      if (!this.processing) {
        this.processing = true;
        const mobileSetting = this.$store.state.auth.mobileSetting;
        console.log("updateReceiveCall mobileSettings : ", JSON.stringify(mobileSetting));
        if (mobileSetting != null) {
          core.mobile.call({
            cmd: "updateReceiveCall",
            value: !mobileSetting.receiveCall,
          });
        } else {
          this.processing = false;
        }
      }
    },
  },
});
