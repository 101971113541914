
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import EstimateService from "@/services/work/estimate.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    memo: "메모",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "EstimateMemoEditModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            estimate: {
              id: 0,
              memo: "",
            },
          },
        };
      },
    },
  },
  data: () => ({
    formProcessing: false,
    estimate: null as any,
    form: {
      memo: "",
    },
  }),
  async created() {
    // 생성 후 호출
    this.estimate = this.properties.params.estimate;
    this.form.memo = this.estimate.memo;
    console.log("created");
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const estimate = await EstimateService.updateMemo(this.estimate.id, this.form.memo);
          console.log("estimate : ", estimate);
          core.loader.hide();
          this.close(UpdateEventType.UPDATE, estimate);
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
      }
    },
  },
});
