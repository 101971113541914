
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import UpdateEvent from "@/models";
import core from "@/core";
import { UpdateEventType } from "@/types";
import store from "@/store";
import SpecialPriceService from "@/services/work/special-price.service";
import PlaceService from "@/services/work/place.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    placeId: "아파트 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "MgmtSpecialPriceAddEdit",
  data: () => ({
    title: "",
    type: "",
    isReady: false,
    readyPromiseResolve: null as any,
    specialPrice: null as any,
    specialPriceId: 0,
    form: {
      placeId: "" as string | null,
      specialPriceList: [] as any,
    },
    selectedType: "",
    imageIndex: 0,
    selectedPlace: null as any,
    placeList: [] as any,
    view: {
      placeName: "",
    },
    modal: {
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
    app: store.state.app,
    style: {
      image: "max-width: 100%",
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const params = this.$route.params as any;
      this.type = params.type;
      if (this.type === "add") {
        this.title = "특별단가 추가";
      } else if (this.type === "edit") {
        this.title = "특별단가 수정";
        const query = this.$route.query as any;
        this.specialPriceId = Number(query.id);
        if (this.specialPriceId <= 0) {
          this.notFound();
          return;
        }
      }
      if (!this.app.isMobile) {
        if (this.app.size.width > 700) {
          this.style.image = `max-width: 50%`;
        }
      }
      const maxHeight = parseInt(String(this.app.size.height / 2));
      // console.log("maxHeight : ", maxHeight);
      this.style.image += `; max-height: ${maxHeight}px`;
      await this.getPlaceList();

      const type = this.type;
      if (type === "edit") {
        try {
          this.specialPrice = (await SpecialPriceService.get(this.specialPriceId)) as any;
        } catch (e) {
          console.log(e);
          await this.notFound();
          return;
        }
        const item = this.specialPrice;

        const promise = new Promise((resolve) => {
          this.readyPromiseResolve = resolve;
        });

        this.form.placeId = item.place.id;
        this.view.placeName = item.place.name;
        this.placeList.some((place) => {
          if (place.id === item.place.id) {
            this.selectedPlace = place;
            return true;
          }
        });
        await promise;

        //console.log("selected place : ", this.selectedPlace);
        if (item.detailList != null) {
          const typeDetailMap = {};
          item.detailList.forEach((detail) => {
            if (!typeDetailMap[detail.type]) {
              typeDetailMap[detail.type] = { type: detail.type, detailList: [] };
            }
            typeDetailMap[detail.type].detailList.push({
              index: this.imageIndex++,
              id: detail.id,
              fileDetailId: detail.fileDetailId,
              dataUri: detail.imageUri,
              parent: typeDetailMap[detail.type],
            });
          });
          this.form.specialPriceList.forEach((specialPrice) => {
            const type = specialPrice.type;
            if (typeDetailMap[type]) {
              specialPrice.detailList = typeDetailMap[type].detailList;
            }
          });
        }
      }
      this.isReady = true;
    });
  },
  watch: {
    type(val) {
      if (val === "add") {
        this.title = "특별단가 추가";
      } else if (val === "edit") {
        this.title = "특별단가 수정";
      }
    },
    "form.placeId"(val, prev) {
      console.log("changed placeId : ", val);

      let place = this.selectedPlace;
      this.form.specialPriceList = [];
      if (place != null && place.typeList != null) {
        place.typeList.forEach((type) => {
          const item = {
            type: type,
            detailList: [],
          };
          this.form.specialPriceList.push(item);
        });
      }
      if (!this.isReady) {
        this.readyPromiseResolve();
        return;
      }
    },
    title() {
      this.$store.dispatch("topToolbar/changeTitle", { title: this.title, ignoreCheck: true });
    },
  },
  methods: {
    moveImage(direction, image, imageList) {
      let pos = 0;
      imageList.some((_image, index) => {
        if (image.index === _image.index) {
          pos = index;
          return true;
        }
      });
      if (direction === "down") {
        if (pos + 1 < imageList.length) {
          const fromItem = imageList.splice(pos, 1);
          imageList.splice(pos + 1, 0, fromItem[0]);
        }
      } else if (direction === "up") {
        if (0 < pos) {
          const fromItem = imageList.splice(pos, 1);
          imageList.splice(pos - 1, 0, fromItem[0]);
        }
      }
      // console.log("image : ", image);
      // console.log("imageList : ", imageList);
    },
    imageHeight(item) {
      console.log("item : ", item);
      const size = this.app.size;

      return 20;
      // let fileDetail = item.fileDetail;
      // if (fileDetail.imageWidth && fileDetail.imageHeight) {
      //   const size = this.app.size;
      //   if (item.isSelfComment) {
      //     const cal = (size.width - 87) / fileDetail.imageWidth;
      //     return fileDetail.imageHeight * cal;
      //   } else {
      //     const cal = (size.width - 110) / fileDetail.imageWidth;
      //     return fileDetail.imageHeight * cal;
      //   }
      // }
    },
    async onChangeImageFile() {
      console.log("onChangeImageFile");
      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        const type = this.selectedType;
        for (let i = 0; i < length; i++) {
          try {
            const file = files[i];
            const imageFile = (await core.utils.image.getImageBlob(file)) as any;
            imageFile.index = this.imageIndex++;
            imageFile.type = "upload";

            //console.log("new image : ", imageFile);
            this.form.specialPriceList.some((specialPrice) => {
              if (specialPrice.type === this.selectedType) {
                specialPrice.detailList.push(imageFile);
                imageFile.parent = specialPrice;
                return true;
              }
            });
            // this.imageList.push(imageFile);
            // this.changedImage = true;
          } catch (e: any) {
            core.http.sendError(e);
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
          }
        }
        (this.$refs.imageFile as any).value = "";
        // this.scrollControl.updatedPosition = ScrollPosition.BOTTOM;
      }
    },

    removeImage(detail) {
      const specialPrice = detail.parent;
      const detailList = specialPrice.detailList;
      for (let i = 0; i < detailList.length; i++) {
        const _detail = detailList[i];
        if (_detail.index === detail.index) {
          detailList.splice(i, 1);
          break;
        }
      }
    },

    showImageSelectView(type) {
      this.selectedType = type;
      (this.$refs.imageFile as any).click();
    },

    async getPlaceList() {
      try {
        this.placeList = await this.$store.getters["app/getPlaceList"]();
      } catch (e) {
        console.log(e);
      }
    },
    async createPlace(keyword, itemList) {
      try {
        if (itemList != null && itemList.length > 0) {
          let placeName = "";
          itemList.forEach((item) => {
            placeName += item.name + ",";
          });
          placeName = placeName.substr(0, placeName.length - 1);
          const result = await core.alert.show({
            title: "확인",
            body: `비슷한 이름의 [<span class="red--text">${placeName}</span>] 아파트가 존재합니다.<br>새로 생성하시겠습니까?`,
            showCancelButton: true,
            cancelButtonText: "취소",
            confirmButtonText: "생성",
          });
          if (result !== "confirm") return;
        }
        const item: any = await PlaceService.create({ name: keyword });
        await this.$store.dispatch("app/waitForPlaceUpdate", item.id);
        return item;
      } catch (e: any) {
        this.placeList = await this.$store.getters["app/getPlaceList"];
      }
    },
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const type = this.type;
          const specialPriceList = this.form.specialPriceList;
          const params = {
            placeId: this.form.placeId,
            detailList: [] as any,
          };
          for (let i = 0; i < specialPriceList.length; i++) {
            const specialPrice = specialPriceList[i];
            const detailList = specialPrice.detailList;
            if (detailList.length > 0) {
              //console.log("specialPrice : ", specialPrice);
              for (let j = 0; j < detailList.length; j++) {
                const imageFile = detailList[j];
                if (imageFile.id == null) {
                  // 이미지 업로드
                  const formData = new FormData();
                  formData.append("imageFile", imageFile.blob, imageFile.filename);

                  try {
                    const fileDetailId = await SpecialPriceService.createImage(formData);

                    const detail = {
                      type: specialPrice.type,
                      fileDetailId: fileDetailId,
                    } as any;
                    params.detailList.push(detail);
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  const detail = {
                    id: imageFile.id,
                    type: specialPrice.type,
                    fileDetailId: imageFile.fileDetailId,
                  } as any;
                  params.detailList.push(detail);
                }
              }
            }
          }
          const specialPrice =
            type === "add"
              ? await SpecialPriceService.create(params)
              : await SpecialPriceService.update(this.specialPriceId, params);
          console.log("specialPrice : ", specialPrice);
          this.goBack(UpdateEventType.UPDATE, specialPrice);
        } catch (e: any) {
          console.log(e);
          if (e.errorFieldName === "detailList") {
            await core.alert.show({
              title: "알림",
              body: "이미지가 1개 이상 선택되어야합니다.",
              confirmButtonText: "확인",
            });
          } else {
            this.errorSubmit(e);
          }
        } finally {
          core.loader.hide();
        }
      } else {
        console.log("validate error");
      }
    },
  },
});
