
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import UpdateEvent from "@/models";
import core from "@/core";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    percentage: "할인퍼센테이지",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "EstimateDiscountModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {},
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    formProcessing: false,
    form: {
      percentage: "",
    },
  }),
  async created() {
    // 생성 후 호출
    // this.estimate = this.properties.params.estimate;
    // this.form.memo = this.estimate.memo;
    // console.log("created");
  },
  watch: {
    "form.percentage"(val) {
      const percentage = Number(core.utils.format.onlyNumber(val));
      if (!isNaN(percentage)) {
        if (percentage < 0) {
          this.form.percentage = "0";
        } else if (percentage > 100) {
          this.form.percentage = "100";
        }
      }
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        try {
          this.close(UpdateEventType.CONFIRM, this.form.percentage);
        } catch (e) {
          this.errorSubmit(e);
        }
      }
    },
  },
});
