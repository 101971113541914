
import mixins from "vue-typed-mixins";
import EstimateService from "@/services/work/estimate.service";
import MixinsTable from "@/mixins/single/table";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import MixinsComponent from "@/mixins/component";
import EstimateUtils from "@/models/estimate";
import core from "@/core";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import { UserModel } from "@/models/user/user.model";

export default mixins(MixinsComponent, MixinsScroll, MixinsTable, MixinsAppBarMenu).extend({
  name: "CounselEstimateComponent",
  components: {},
  props: {
    type: {
      type: String,
    },
  },
  data: () => ({
    dateTimeFormatPattern: "a hh:mm",
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
      request: {
        orderColumnName: "createdAt",
        searchColumns: {},
      },
    },
    user: {} as UserModel,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.user = await this.$store.getters["auth/user"]();

      this.appBarChangeMenuVisible("download", this.isCompanyAdminRoleHigher);

      // 테이블 초기화
      this.initTable({
        service: EstimateService,
        serviceFunctionKey: "getCounselTable",
        itemHeight: 48,
      });

      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "properties.updateEvent"() {
      const event = this.getPropsUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (this.addTableItem("id", event.item)) {
            this.setScrollPosition(ScrollPosition.TOP);
            // 현재 작성한 견적서 페이지로 이동
            const item = event.item;
            this.$router.push({
              path: `/estimate/${item.id}`,
            });
          }
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
          // if (this.properties.params.type === "deleted") {
          //   // 견적서 복구됨
          //   this.$router.push({
          //     path: `/estimate/${event.item.id}`,
          //   });
          // }
        } else if (event.result === UpdateEventType.PAGE) {
          const item = event.item;
          const estimate = item.estimate;
          delete item.estimate;
          if (estimate != null) {
            if (this.addTableItem("id", estimate)) {
              this.setScrollPosition(ScrollPosition.TOP);
            }
          }
          // path, query
          this.$router.push(item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    subtitleToText(item) {
      let text = "";
      text += this.dateTimeFormat(item.startAt);
      // dateTimeFormat(item.startAt) }} ~ {{ dateTimeFormat(item.endAt) }} ({{
      // elapsedTimeString(item)
      if (item.endAt) {
        text += " ~ ";
        text += this.dateTimeFormat(item.endAt);
        const endMoment = core.date.instance(item.endAt);
        const startMoment = core.date.instance(item.startAt);
        //console.log("endMoment : ", endMoment.format("YYYY-MM-DD HH:mm:ss"));
        //console.log("startMoment : ", startMoment.format("YYYY-MM-DD HH:mm:ss"));

        const diffSec = endMoment.diff(startMoment, "seconds");
        if (diffSec < 60) {
          text += `(${diffSec}초)`;
          return text;
        }

        const diffMin = endMoment.diff(startMoment, "minutes");
        //console.log("diffMin : ", diffMin);
        if (diffMin < 60) {
          text += `(${diffMin}분)`;
          return text;
        }

        const diffhour = endMoment.diff(startMoment, "hours");
        //console.log("diffhour : ", diffhour);
        text += `(${diffhour}시간)`;
        return text;
      }
      return text;
    },
    appBarMenuEvent(menu: AppBarMenuItem) {
      console.log("appBarMenuEvent : ", menu);
      if (menu.id === "download") {
        this.$router.push({
          path: "/estimate-counsel/download",
        });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    replaceState(urlParams?: any) {
      if (urlParams == null) urlParams = {};
      urlParams.stackKey = this.$route.query.stackKey;
      const length = Object.keys(urlParams).length;
      const url = this.$route.path + (length > 0 ? "?" + core.http.objToUrlParams(urlParams) : "");
      history.replaceState("", "", url);
    },
    showViewPage(item: any) {
      // console.log("estimate : ", item.estimate);
      if (item.estimate) {
        this.$router.push({ path: `/estimate/${item.estimate.id}` });
      }
    },

    titleToHtml(item: any) {
      let params: any = item;
      if (item.estimate != null) {
        item.estimate.place = item.place;
        params = item.estimate;
      }
      // console.log("params : ", params);
      let html = EstimateUtils.titleToHtml(params);
      if (item.estimate != null) {
        return "[견적]" + "[" + EstimateUtils.contractStatusToHtml(params) + "]" + html;
      }
      return html;
    },
  },
});
