
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import Constant from "@/store/constant";
import CompanyService from "@/services/company/company.service";
import core from "@/core";

import { localize } from "vee-validate";
import { cloneDeep } from "lodash";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    name: "업체명 ",
    ceoName: "대표명 ",
    phone: "휴대전화 ",
    bankName: "은행명 ",
    bankAccount: "계좌번호 ",
    accountHolder: "예금주 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "CompanyAddEditComponent",
  props: {
    company: {
      type: Object,
      default: () => {
        return {
          id: 0,
          role: "",
          name: "",
          ceoName: "",
          phone: "",
          detailList: [],
          lastApproval: {
            id: 0,
            type: "",
          },
        };
      },
    },
  },
  data: () => ({
    type: "",
    formProcessing: false,
    form: {
      name: "",
      ceoName: "",
      phone: "",
      detailList: [] as any,
      estimateNote: "",
    },
    estimateNodeId: null as any,
  }),
  created() {
    // 생성후 호출
    const company = this.company;
    if (company.id > 0) {
      this.form.name = company.name;
      this.form.ceoName = company.ceoName;
      this.form.phone = company.phone;
      const detailList = this.form.detailList;
      if (company.detailList != null) {
        company.detailList.forEach((detail: any) => {
          if (detail.type == "BANK_ACCOUNT" || detail.type == "DEFAULT_BANK_ACCOUNT") {
            const array = detail.value.split(",");
            if (array.length === 3) {
              const isDefault = detail.type === "DEFAULT_BANK_ACCOUNT";
              detailList.push({
                id: detail.id,
                default: isDefault,
                bankName: array[0],
                bankAccount: array[1],
                accountHolder: array[2],
              });
            } else {
              console.log("unknown value : ", detail.value);
            }
          } else if (detail.type == "ESTIMATE_NOTE") {
            this.estimateNodeId = detail.id;
            this.form.estimateNote = detail.value;
          } else {
            console.log("unknown type : ", detail.type);
          }
        });
      }
      this.type = "edit";
    } else {
      this.type = "add";
    }
  },
  mounted() {
    // 페이지 로딩 후 호출
  },
  watch: {
    "form.phone"() {
      this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
    },
  },
  methods: {
    changeDetailDefault(item: any) {
      this.form.detailList.some((detail) => {
        if (detail.default) {
          detail.default = false;
          return true;
        }
      });
      item.default = true;
    },
    addDetailList() {
      const isDefault = this.form.detailList.length === 0;

      this.form.detailList.push({
        default: isDefault,
        bankName: "",
        bankAccount: "",
        accountHolder: "",
      });
    },
    removeDetailList(i: number) {
      this.form.detailList.splice(i, 1);
    },
    async submit() {
      const type = this.type;
      if (type === "add" || type === "edit") {
        if (await this.validate()) {
          this.formProcessing = true;

          core.loader.show();
          try {
            const params = cloneDeep(this.form) as any;
            const detailList = [] as any;
            for (let i = 0; i < params.detailList.length; i++) {
              const detail = params.detailList[i];
              const isDefault = detail.default;
              const bankName = detail.bankName;
              const bankAccount = detail.bankAccount;
              const accountHolder = detail.accountHolder;
              const type = isDefault ? "DEFAULT_BANK_ACCOUNT" : "BANK_ACCOUNT";

              detailList.push({
                id: detail.id != null ? detail.id : null,
                type: type,
                value: bankName + "," + bankAccount + "," + accountHolder,
              });
            }
            params.detailList = detailList;
            const estimateNote = params.estimateNote;
            delete params.estimateNote;
            if (this.isNotBlank(estimateNote)) {
              params.detailList.push({
                id: this.estimateNodeId,
                type: "ESTIMATE_NOTE",
                value: estimateNote,
              });
            }

            const userCompany =
              type === "add"
                ? await CompanyService.create(params)
                : await CompanyService.update(params);
            console.log("commit user company : ", userCompany);

            const user = await this.$store.getters["auth/user"](true);
            core.loader.hide();
            await core.alert.show({
              title: "알림",
              body: "업체정보가 " + (type === "add" ? "등록" : "수정") + "되었습니다.",
            });
            await this.$router.back();
          } catch (e) {
            this.formProcessing = false;
            core.loader.hide();
            this.errorSubmit(e);
          }
        }
      } else {
        console.log("unknown type : ", type);
      }
    },
  },
});
