
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    type: "종류 ",
    memo: "메모 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "EstimateMemoAddEditModal",
  data: () => ({
    type: "",
    dateVisible: false,
    form: {
      date: "",
      memo: "",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      // 생성 후 호출
      const properties = this.properties;
      this.type = properties.params.type;

      if (this.type !== "add" && this.type !== "edit") {
        await this.notFound();
      }

      if (this.type === "edit") {
        const item = properties.params.item;
        this.form.date = item.date;
        this.form.memo = item.memo;
      } else {
        this.form.date = core.date.instance().format("YYYY-MM-DD");
      }
    });
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        try {
          const params = core.utils.deepCopy(this.form);
          this.close(UpdateEventType.CONFIRM, params);
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
      }
    },
    async deleteItem() {
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        this.close(UpdateEventType.DELETE);
      }
    },
  },
});
