
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import EventService from "@/services/event/event.service";
import core from "@/core";
import UpdateEvent from "@/models";
import { AppBarMenuItem, TableOrder, UpdateEventType } from "@/types";
import EventRouletteItemInputModal from "@/modals/event/RouletteItemInputModal.vue";
import EventSelectCategoryModal from "@/modals/event/SelectCategoryModal.vue";
import draggable from "vuedraggable";
import SelectEventModal from "@/modals/event/SelectEventModal.vue";
import Constant from "@/store/constant";
import TextFieldModal from "@/modals/core/TextFieldModal.vue";
import PlaceService from "@/services/work/place.service";
import SelectSearchModal from "@/modals/core/SelectSearchModal.vue";
import CompanyService from "@/services/company/company.service";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "EventView",

  components: {
    SelectSearchModal,
    TextFieldModal,
    SelectEventModal,
    draggable,
    EventSelectCategoryModal,
    EventRouletteItemInputModal,
  },

  data: () => ({
    title: "이벤트",
    eventId: 0,
    event: null as any,
    eventCategoryList: null as any,
    visible: {
      categoryButton: true,
      linkImage: false,
    },
    modal: {
      selectEvent: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      itemInput: {
        visible: false,
        index: 1,
        params: {
          item: null as any,
          eventCategory: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
      selectCategory: {
        visible: false,
        params: {
          item: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
      placeUser: {
        visible: false,
        selected: null as any,
        list: [] as any,
        updateEvent: null as UpdateEvent | null,
      },
    },
    categoryList: [] as any,
    placeUserList: [] as any,
    userList: [] as any,
    link: "",
    imageSrcUri: "",
  }),
  mounted() {
    const params = this.$route.params as any;
    this.eventId = Number(params.id);
    if (this.eventId <= 0) {
      this.notFound();
    }

    this.$nextTick(async () => {
      try {
        //console.log("eventId : ", this.eventId);
        this.categoryList = await this.$store.getters["app/getCategoryList"]();
        //console.log("categoryList : ", this.categoryList);

        const event = (await EventService.get(this.eventId)) as any;
        //console.log("event : ", event);
        const placeId = event.place.id;
        const urlParams = core.http.objToUrlParams({
          placeId: placeId,
        });
        this.link = Constant.eventUrl + "/home?" + urlParams;

        this.event = event;

        this.imageSrcUri = "/img/roulette/roulette-event-20230223.gif";
        if (event.imageType === "B") {
          this.imageSrcUri = "/img/roulette/roulette-event-20230316.gif";
        }

        // const query = this.$route.query;
        const eventCategoryList = (await EventService.getCategoryRouletteList(this.eventId)) as any;
        eventCategoryList.forEach((eventCategory) => {
          eventCategory.isEditMode = false;
          eventCategory.rouletteList.forEach((roulette) => {
            roulette.index = this.modal.itemInput.index++;
          });
        });
        this.eventCategoryList = eventCategoryList;
        this.changedEventCategoryList();
        //console.log("eventCategoryList : ", eventCategoryList);

        const params = {
          draw: 0,
          start: 0,
          length: 10,
          orderColumnName: "id",
          order: TableOrder.DESC,
          searchColumns: {
            eventId: event.id,
            categoryId: 1,
          },
        };
        const result = await EventService.getRouletteWinnerTable(params);
        // console.log("result : ", result);
        const placeUserList = (this.placeUserList = await PlaceService.getPlaceUserList(placeId));
        // console.log("placeUserList : ", placeUserList);
      } catch (e) {
        console.log(e);
        this.notFound();
      }
    });
  },

  watch: {
    async "modal.selectEvent.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectEvent);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          //console.log("item : ", item);
          const eventCategoryList = (await EventService.getCategoryRouletteList(item.id)) as any;
          eventCategoryList.forEach((eventCategory) => {
            eventCategory.eventId = this.eventId;
            eventCategory.isEditMode = true;
            eventCategory.rouletteList.forEach((roulette) => {
              roulette.id = null;
              roulette.index = this.modal.itemInput.index++;
            });
          });
          this.eventCategoryList = eventCategoryList;
          this.changedEventCategoryList();
          //console.log("eventCategoryList : ", eventCategoryList);
        } else {
          console.log("unknown event. ", event);
        }
      }
    },
    "modal.itemInput.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.itemInput);
      if (event != null) {
        // console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const modal = this.modal.itemInput;
          const roulette = modal.params.item;
          const eventCategory = modal.params.eventCategory;
          eventCategory.isEditMode = true;

          const item = event.item;

          if (item.index != null) {
            eventCategory.rouletteList.forEach((roulette) => {
              if (roulette.index === item.index) {
                roulette.item = item.item;
                roulette.result = item.result;
                roulette.weight = Number(item.weight);
                if (item.maxSize != null) {
                  roulette.maxSize = Number(item.maxSize);
                } else {
                  roulette.maxSize = null;
                }
              }
            });
          } else {
            const params = {
              index: modal.index++,
              item: item.item,
              result: item.result,
              weight: Number(item.weight),
              maxSize: null as number | null,
            };
            if (item.maxSize != null) {
              params.maxSize = Number(item.maxSize);
            }
            eventCategory.rouletteList.push(params);
          }

          this.changedEventCategoryList();
          // console.log("item : ", item);
          // console.log("roulette : ", roulette);
          // console.log("eventCategory : ", eventCategory);
        } else if (event.result === UpdateEventType.DELETE) {
          const modal = this.modal.itemInput;
          const eventCategory = modal.params.eventCategory;
          const item = event.item;
          eventCategory.isEditMode = true;

          const list = eventCategory.rouletteList;
          for (let i = 0; i < list.length; i++) {
            const roulette = list[i];
            if (item.index === roulette.index) {
              list.splice(i, 1);
              break;
            }
          }
        } else {
          console.log("unknown event. ", event);
        }
      }
    },
    "modal.selectCategory.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectCategory);
      if (event != null) {
        // console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const modal = this.modal.selectCategory;
          const item = event.item;
          const categoryId = Number(item.categoryId);
          let category = null as any;
          this.categoryList.some((_category) => {
            if (categoryId === _category.id) {
              category = _category;
              return true;
            }
          });

          if (category != null) {
            const params = {
              category: category,
              eventId: this.eventId,
              idEditMode: false,
              rouletteList: [],
            };
            this.eventCategoryList.push(params);
            this.changedEventCategoryList();
            console.log("categoryId : ", categoryId);
          } else {
            console.log("not found categoryId. ", categoryId);
          }
        } else {
          console.log("unknown event. ", event);
        }
      }
    },
    "modal.placeUser.selected"(selected) {
      if (selected != null) {
        this.modal.placeUser.selected = null;
        this.addPlaceUser(selected.id);
      }
    },
  },
  methods: {
    async showSelectUserModal() {
      if (this.userList.length === 0) {
        this.userList = await CompanyService.getUserList();
      }
      const modal = this.modal.placeUser;
      modal.list = [];
      this.userList.forEach((user) => {
        let exists = false;
        this.placeUserList.some((placeUser) => {
          if (placeUser.user.id === user.id) {
            exists = true;
            return true;
          }
        });
        if (!exists) {
          modal.list.push({
            id: user.id,
            text: `${user.name}(${core.utils.format.hyphenPhone(user.phone)})`,
          });
        }
      });
      modal.visible = true;
    },
    async addPlaceUser(userId) {
      console.log("add place user : ", userId);
      if (this.event != null) {
        const placeId = this.event.place.id;
        const result = await PlaceService.createPlaceUser(placeId, userId);
        this.placeUserList = await PlaceService.getPlaceUserList(placeId);
      }
    },
    async deletePlaceUser(userId) {
      console.log("remove place user : ", userId);
      if (this.event != null) {
        const placeId = this.event.place.id;
        const result = await PlaceService.deletePlaceUser(placeId, userId);
        this.placeUserList = await PlaceService.getPlaceUserList(placeId);
      }
    },
    appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "edit") {
        this.showEditPage();
      } else if (menu.id === "delete") {
        this.deleteItem();
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    async updateEventStatusActive() {
      const event = this.event;
      try {
        core.loader.show();
        const newEvent = (await EventService.updateEventStatusActive(event.id)) as any;
        core.utils.copy(newEvent, event);
        // console.log("newEvent : ", newEvent);
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
    moveRouletteWinnerSearchPage(eventCategory) {
      //console.log("eventCategory : ", eventCategory);
      this.$router.push({
        path: "/event/roulette/winner/search",
        query: {
          eventId: eventCategory.eventId,
          categoryId: eventCategory.category.id,
        },
      });
    },
    async redrawEventCategoryList(eventId, isEditMode) {
      if (isEditMode == null) {
        isEditMode = false;
      }
      const eventCategoryList = (await EventService.getCategoryRouletteList(eventId)) as any;
      eventCategoryList.forEach((eventCategory) => {
        eventCategory.isEditMode = isEditMode;
        eventCategory.rouletteList.forEach((roulette) => {
          roulette.index = this.modal.itemInput.index++;
        });
      });
      this.eventCategoryList = eventCategoryList;
      this.changedEventCategoryList();
      console.log("eventCategoryList : ", eventCategoryList);
    },
    changeOrder(eventCategory) {
      eventCategory.isEditMode = true;
    },
    resultToString(result) {
      if (result === "WIN") {
        return "당첨";
      } else if (result === "BLANK") {
        return "꽝";
      }
      return "";
    },
    copyEventLink() {
      const textArea = document.createElement("textarea");
      textArea.value = this.link;
      // textarea 추가
      document.body.appendChild(textArea);

      textArea.select();
      document.execCommand("copy");

      // textarea 제거
      document.body.removeChild(textArea);
      core.alert.show({
        title: "알림",
        body: "링크가 클립보드로 복사되었습니다",
      });
    },
    showEventPage() {
      if (this.isNotBlank(this.link)) {
        window.open(this.link, "_blank");
      }
    },
    moveRouletteTestPage(eventCategory) {
      this.$router.push({
        path: "/event/roulette",
        query: {
          eventId: eventCategory.eventId,
          categoryId: eventCategory.category.id,
        },
      });
    },
    addCategory() {
      const categoryList = [] as any;
      this.categoryList.forEach((category) => {
        let exists = false;
        this.eventCategoryList.some((eventCategory) => {
          if (eventCategory.category.id === category.id) {
            exists = true;
            return true;
          }
        });
        if (!exists) {
          categoryList.push(category);
        }
      });
      const modal = this.modal.selectCategory;
      modal.params.item = categoryList;
      modal.visible = true;
    },
    changedEventCategoryList() {
      const eventCategoryList = this.eventCategoryList;
      for (let i = 0; i < eventCategoryList.length; i++) {
        const eventCategory = eventCategoryList[i];

        let totalWeight = 0;
        eventCategory.rouletteList.forEach((roulette) => {
          totalWeight += roulette.weight;
        });

        eventCategory.rouletteList.forEach((roulette) => {
          roulette.weightPer = ((roulette.weight / totalWeight) * 100).toFixed(2);
        });
      }
    },
    showSelectEventModal(eventCategory) {
      //console.log("eventCategory : ", eventCategory);
      const modal = this.modal.selectEvent;
      modal.visible = true;
    },
    showItemInputModal(eventCategory, roulette) {
      //console.log("eventCategory : ", eventCategory);
      const modal = this.modal.itemInput;
      modal.params.item = roulette;
      modal.params.eventCategory = eventCategory;
      modal.visible = true;
    },
    statusToHtml(item) {
      if (item.status === "WAIT") {
        return "<span class='orange--text'>대기</span>";
      } else if (item.status === "ACTIVE") {
        return "<span class='green--text'>시작</span>";
      } else if (item.status === "END") {
        return "<span class='grey--text'>종료</span>";
      }
      return "알 수 없음";
    },
    showEditPage() {
      this.$router.push({
        path: "/event/edit",
        query: {
          id: String(this.eventId),
        },
      });
    },
    async deleteItem() {
      const result = await core.alert.show({
        title: "확인",
        body: "이 이벤트를 삭제하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });

      if (result === "confirm") {
        try {
          const result = await EventService.delete(this.eventId);
          // console.log("result : ", result);
          this.goBack(UpdateEventType.DELETE, this.event);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async submit(eventCategoryParam) {
      //console.log("eventCategory : ", eventCategory);
      core.loader.show();
      try {
        const params = {
          eventId: eventCategoryParam.eventId,
          categoryId: eventCategoryParam.category.id,
          itemList: eventCategoryParam.rouletteList,
        };
        // console.log("params : ", params);
        const eventCategoryResult = (await EventService.createCategoryRouletteList(params)) as any;
        this.eventCategoryList.some((_eventCategory, index) => {
          if (_eventCategory.category.id === eventCategoryResult.category.id) {
            _eventCategory.isEditMode = false;
            eventCategoryResult.rouletteList.forEach((roulette) => {
              roulette.index = this.modal.itemInput.index++;
            });
            _eventCategory.rouletteList = eventCategoryResult.rouletteList;
            if (_eventCategory.rouletteList.length === 0) {
              this.eventCategoryList.splice(index, 1);
            }
            return true;
          }
        });
        this.changedEventCategoryList();
        //console.log("eventCategoryResult : ", eventCategoryResult);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
  },
});
