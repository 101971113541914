
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { UpdateEventType } from "@/types";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    expectedDate: "입주예정일 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "EstimateExpectedDateModal",
  data: () => ({
    datePicker: {
      visible: false,
    },
    form: {
      expectedDate: core.date.instance().format("YYYY-MM-DD"),
    },
  }),
  mounted() {
    this.$nextTick(async () => {});
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        this.close(UpdateEventType.CONFIRM, this.form.expectedDate);
      }
    },
  },
});
