
import StatusService from "@/services/work/status.service";
import core from "@/core";
import ScheduleViewInfo from "@/components/schedule/ScheduleViewInfo.vue";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsComponent from "@/mixins/component";
import ScheduleService from "@/services/work/schedule.service";
import ScheduleUtils from "@/models/schedule";
import { UpdateEventType } from "@/types";

export default mixins(MixinsComponent, MixinsPage).extend({
  name: "ScheduleStatusComponent",
  components: {
    ScheduleViewInfo,
  },
  props: {
    schedule: {
      type: Object,
      default: () => {
        return {
          category: {},
        };
      },
    },
  },
  data: () => ({
    formProcessing: false,
    updateViewType: null as string | null,
    step: 1,
    status: {
      before: null,
      after: null,
      defect: null,
      selectColor: null,
    },
    visible: {
      complete: false,
      selectColor: false,
    },
    modal: {
      addStatus: {
        visible: false,
        params: {
          type: "B",
          scheduleId: 0,
          estimateId: 0,
        },
        watchClose: {
          result: "",
        },
      },
      viewStatus: {
        visible: false,
        params: {
          type: "B",
          statusId: 0,
        },
        watchClose: {
          result: "",
        },
      },
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(() => {
      this.getStatusList();
      if (
        this.schedule != null &&
        this.schedule.category != null &&
        this.schedule.category.name === "줄눈"
      ) {
        this.visible.selectColor = true;
      }
      console.log("schedule : ", this.schedule);
    });
  },
  watch: {
    "properties.updateEvent"() {
      // 이벤트 발생
      const event = this.getPropsUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.RELOAD) {
          this.getStatusList();
        }
      }
    },
    "modal.addStatus.watchClose"() {
      const result = this.modal.addStatus.watchClose.result;
      if (result === "confirm") {
        this.getStatusList();
      }
    },
  },
  methods: {
    async updateScheduleComplete(complete: boolean) {
      try {
        if (complete) {
          if (this.status.after == null) {
            const result = await core.alert.show({
              title: "확인",
              body: "시공 사진 없이 완료 처리 하시겠습니까?",
              showCancelButton: true,
              confirmButtonText: "완료",
              cancelButtonText: "취소",
            });
            if (result !== "confirm") {
              return;
            }
          }
        } else if (this.schedule.complete) {
          const result = await core.alert.show({
            title: "확인",
            body: "시공이 <span class='green--text'>완료</span>된 상태입니다.<br>시공 완료를 <span class='red--text'>취소</span>하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "예",
            cancelButtonText: "아니오",
          });
          if (result !== "confirm") {
            return;
          }
        }

        core.loader.show();
        const schedule = await ScheduleService.updateScheduleComplete(this.schedule.id, complete);
        core.utils.copy(schedule, this.schedule);
        // 이전 페이지 이벤트 전송
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async getStatusList() {
      // 일정 상태 정보 조회
      //console.log("schedule : ", this.schedule);
      if (ScheduleUtils.isStartAtBeforeToday(this.schedule)) {
        this.visible.complete = true;
      }

      const statusList: any = await StatusService.getStatusListByScheduleId(this.schedule.id);
      statusList.forEach((status: any) => {
        if (status.type === "B") {
          if (this.step < 2) this.step = 2;
          this.status.before = status;
        } else if (status.type === "A") {
          if (this.step < 3) this.step = 3;
          this.status.after = status;
        } else if (status.type === "F") {
          this.status.defect = status;
        } else if (status.type === "S") {
          this.status.selectColor = status;
        }
      });
      this.updateViewType = "statusList";
      if (this.status.after != null && !this.schedule.complete) {
        const schedule = await ScheduleService.get(this.schedule.id);
        core.utils.copy(schedule, this.schedule);
      }
    },
    showAddModal(type: string) {
      console.log("showAddModal : ", type);
      // this.step++;
      if (type === "B" || type === "A" || type === "F" || type === "S") {
        // 시공전, 시공후 사진 등록
        const query = {
          type: type,
          scheduleId: this.schedule.id,
        };
        this.$router.push({ path: "/status/add", query: query });
        // this.modal.addStatus.params.type = type;
        // this.modal.addStatus.params.scheduleId = this.schedule.id;
        // this.modal.addStatus.params.estimateId = this.schedule.estimate.id;
        // this.modal.addStatus.visible = true;
      } else {
        console.log("Unknown type : ", type);
      }
    },
    showViewPage(type: string) {
      //console.log("showViewPage : ", type);
      if (type === "B" || type === "A" || type === "F" || type === "S") {
        let status: any = null;
        if (type === "F") status = this.status.defect;
        else if (type === "B") status = this.status.before;
        else if (type === "S") status = this.status.selectColor;
        else status = this.status.after;

        this.$router.push({ path: `/status/${status.id}` });

        // // 시공전, 시공후 사진 등록
        // this.modal.viewStatus.params.type = type;
        // this.modal.viewStatus.params.statusId = status.id;
        // this.modal.viewStatus.visible = true;
        // console.log(this.modal.viewStatus);
      } else {
        console.log("Unknown type : ", type);
      }
    },

    fileSizeFormat(value: string) {
      return core.utils.format.filesize(true, parseInt(value), 2);
    },
  },
});
