
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import store from "@/store";

export default mixins(MixinsModalForm).extend({
  name: "ScheduleVisitGuideModal",
  data: () => ({
    ready: false,
    scheduleList: null as any,
    app: store.state.app,
    bodyMinHeight: "100px",
  }),
  mounted() {
    this.$nextTick(async () => {
      const scheduleList = (this.scheduleList = this.properties.params.scheduleList);

      this.ready = true;
      console.log("scheduleList : ", this.scheduleList);
    });
  },

  methods: {
    showViewPage(scheduleId: number) {
      this.$router.push({ path: `/schedule/${scheduleId}` });
    },
    scheduleText(item) {
      const date = this.scheduleDateText(item);

      let text = "";
      if (item.category != null) {
        text = `${item.category.name} (${date})`;
      } else {
        text = date;
      }

      if (item.workType === "A") {
        return `A/S ${text}`;
      }
      return text;
    },
    scheduleDateText(item) {
      const startDate = core.date.instance(item.startAt).format("YYYY-MM-DD");
      const endDate = core.date.instance(item.endAt).format("YYYY-MM-DD");
      if (startDate == endDate) {
        return core.date.instance(item.startAt).format("YYYY년 MM월 DD일");
      } else {
        return (
          core.date.instance(item.startAt).format("YYYY년 MM월 DD일") +
          " ~ " +
          core.date.instance(item.endAt).format("YYYY년 MM월 DD일")
        );
      }
    },
    getRemainingDays(schedule: any) {
      const curDate = core.date.instance(core.date.instance().format("YYYY-MM-DD 00:00:00"));
      const startAtMoment = core.date.instance(
        core.date.instance(schedule.startAt).format("YYYY-MM-DD 00:00:00")
      );
      return startAtMoment.diff(curDate, "days");
    },
  },
});
