
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { AppBarMenuItem, EntityType, TableOrder, UpdateEventType } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import SupportService from "@/services/support/support.service";
import core from "@/core";
import CommentService from "@/services/comment/comment.service";
import SupportAsService from "@/services/support/support-as.service";
import CommentComponent, { Mode } from "@/components/comment/CommentComponent.vue";
import { ScheduleViewType } from "@/models/schedule";
import EstimateService from "@/services/work/estimate.service";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "SupportView",

  components: { CommentComponent },
  data: () => ({
    title: "",
    id: 0,
    width: 0,
    support: null as any,
    supportAsList: [] as any,
    component: {
      commentEntity: {
        visible: false,
        mode: Mode.DEFAULT,
        type: EntityType.ESTIMATE_SUPPORT,
        id: 0,
        idList: [],
        systemMessageList: [],
        reloadFlag: false,
        selectType: "",
      },
    },
    modal: {
      copyComment: {
        visible: false,
        list: [] as any,
        selectedItem: null as any,
      },
      asReceipt: {
        visible: false,
        list: [] as any,
        selectedItem: null as any,
      },
    },
    selectedCommentIdList: null as any,
    selectedCategory: null as any,
    helpBar: {
      visible: true,
      style: "",
      buttonStyle: "",
      emptyElStyle: "",
    },
  }),

  created() {
    // 생성 후 호출
    const params = this.$route.params as any;
    this.id = params.id as number;
  },
  async mounted() {
    this.$nextTick(async () => {
      this.width = this.app.size.width - 20;
      this.component.commentEntity.id = this.id;
      this.component.commentEntity.visible = true;
      this.updateHelpBar();
    });
  },
  activated() {
    const event = this.getPageUpdateEvent() as any;
    if (event?.path.indexOf("/schedule") > -1 && event?.item) {
      const item = event?.item;
      console.log("item : ", item);
    }
    this.init();
  },
  watch: {
    title() {
      this.$store.dispatch("topToolbar/changeTitle", { title: this.title, ignoreCheck: true });
    },
    async "modal.asReceipt.selectedItem"(selectedItem) {
      const modal = this.modal.asReceipt;
      modal.selectedItem = null;
      if (selectedItem != null) {
        await core.alert.show({
          title: "알림",
          body: `${selectedItem.name} 품목 A/S 접수시 필요한 고객 메시지를 선택해주세요`,
        });
        this.selectedCategory = selectedItem;
        const component = this.component.commentEntity;
        component.selectType = "as";
        component.mode = Mode.SELECT;
        console.log("selectedItem : ", selectedItem);
      }
    },

    async "modal.copyComment.selectedItem"(selectedItem) {
      const modal = this.modal.copyComment;
      modal.selectedItem = null;
      if (selectedItem != null) {
        await core.alert.show({
          title: "알림",
          body: `${selectedItem.name} 품목 A/S 현황 으로 복사할 고객 메시지를 선택해주세요`,
        });
        this.selectedCategory = selectedItem;
        const component = this.component.commentEntity;
        component.selectType = "copy";
        component.mode = Mode.SELECT;
        console.log("selectedItem : ", selectedItem);
      }
    },
    async selectedCommentIdList(list) {
      if (list != null && list.length > 0) {
        // console.log("list : ", list);
        const component = this.component.commentEntity;
        if (component.selectType === "as") {
          core.loader.show(`${this.selectedCategory.name} A/S 접수 중입니다`);
          const params = {
            estimateId: this.support.estimateId,
            categoryId: this.selectedCategory.id,
            commentIdList: list,
          };

          const as = (await SupportAsService.create(params)) as any;
          //console.log("as : ", as);

          // 댓글 생성
          for (let i = 0; i < list.length; i++) {
            const commentId = list[i];
            const params = {
              entityType: EntityType.SUPPORT_AS,
              entityId: as.id,
              type: "L",
              linkId: commentId,
            } as any;

            try {
              await CommentService.create(params);
            } catch (e) {
              console.log(e);
            }
          }

          core.loader.hide();

          await this.init();
        } else {
          core.loader.show(`${this.selectedCategory.name} A/S 현황으로 메시지를 복사중입니다.`);
          const categoryId = this.selectedCategory.id;
          let as = null as any;
          this.supportAsList.some((_as) => {
            if (_as.category.id === categoryId) {
              as = _as;
              return true;
            }
          });
          for (let i = 0; i < list.length; i++) {
            const commentId = list[i];
            const params = {
              entityType: EntityType.SUPPORT_AS,
              entityId: as.id,
              type: "L",
              linkId: commentId,
            } as any;

            try {
              await CommentService.create(params);
            } catch (e) {
              console.log(e);
            }
          }
          core.loader.hide();
          await this.init();
        }

        try {
          const support = await SupportService.updateActivated(this.support.estimateId, false);
          this.support = support;
        } catch (e) {
          console.log(e);
        }
      } else {
        const component = this.component.commentEntity;
        if (component.selectType === "as") {
          core.loader.show(`${this.selectedCategory.name} A/S 접수 중입니다`);
          const params = {
            estimateId: this.support.estimateId,
            categoryId: this.selectedCategory.id,
            commentIdList: list,
          };

          const as = (await SupportAsService.create(params)) as any;

          core.loader.hide();

          await this.init();
        }
      }
    },
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      console.log("appBarMenuEvent : ", menu);
      if (menu.id === "estimate") {
        this.showEstimatePage();
      } else if (menu.id === "copyMessage") {
        this.showCopyCommentModal();
      } else if (menu.id === "addSchedule") {
        this.showAddSchedulePage("add");
      } else if (menu.id === "addAsSchedule") {
        this.showAddSchedulePage("as");
      } else if (menu.id === "addAsReceipt") {
        this.showAsReceipt();
      } else if (menu.id === "complete") {
        this.updateSupportActivated(false);
        this.appBarChangeMenuVisible("complete", this.support.activated);
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    async init() {
      try {
        const support = (this.support = (await SupportService.get(this.id)) as any);
        //console.log("support : ", support);
        this.appBarChangeMenuVisible("complete", support.activated);
        const asCategoryMap = {};
        const asList = (this.supportAsList = [] as any);
        {
          const params = {
            draw: 0,
            start: 0,
            length: 50,
            orderColumnName: "createdAt",
            order: TableOrder.ASC,
            searchColumns: {
              activated: true,
              estimateId: support.estimateId,
            },
          };
          const asTableResponse = (await SupportAsService.getTable(params)) as any;
          asTableResponse.data.forEach((data) => {
            asList.push(data);
            asCategoryMap[data.category.id] = data.category;
          });
          const modal = this.modal.copyComment;
          modal.list = [] as any;
          for (const key of Object.keys(asCategoryMap)) {
            const category = asCategoryMap[key];
            modal.list.push(category);
          }
        }
        this.modal.asReceipt.list = [] as any;
        const estimate = support.estimate;
        const scheduleList = (await EstimateService.getScheduleList(estimate.id)) as any;
        console.log("scheduleList : ", scheduleList);
        if (scheduleList.length > 0) {
          const categoryMap = {};
          scheduleList.forEach((schedule) => {
            if (schedule.workType === "D" && !asCategoryMap[schedule.category.id]) {
              categoryMap[schedule.category.id] = schedule.category;
            }
          });
          console.log("categoryMap : ", categoryMap);
          for (const key of Object.keys(categoryMap)) {
            const category = categoryMap[key];

            this.modal.asReceipt.list.push(category);
          }
        }
        this.appBarChangeMenuVisible(
          "addAsReceipt",
          this.modal.asReceipt.list != null && this.modal.asReceipt.list.length > 0
        );
        console.log("this.modal.asReceipt : ", this.modal.asReceipt);

        this.title = `${this.getTitle()}`;
        // this.title = support.category.name + " A/S " + this.statusToText(support);
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } catch (e) {
        console.log(e);
        this.goBack();
      }
    },
    toggleHelpBar() {
      this.helpBar.visible = !this.helpBar.visible;
      this.updateHelpBar();
    },
    updateHelpBar() {
      if (this.helpBar.visible) {
        this.helpBar.buttonStyle = "position: absolute; bottom: -10px; right: 10px";
        this.helpBar.style =
          "position: fixed; width: 100%; top: 50px; z-index: 1; min-height: 40px; background: #fff";
        this.helpBar.emptyElStyle = "padding-top: 100px !important";
      } else {
        this.helpBar.buttonStyle = "position: absolute; bottom: 0px; right: 10px";
        this.helpBar.style =
          "position: fixed; width: 100%; top: 50px; z-index: 1; min-height: 40px;";

        this.helpBar.emptyElStyle = "";
        this.helpBar.style += "background: transparent";
      }
    },
    supportAsStatusToText(item) {
      const status = item.status;
      if (status === "RECEIPT") {
        return "접수";
      } else if (status === "RECEIPT_CONFIRM") {
        return "접수확인";
      } else if (status === "CUSTOMER_ADVICE") {
        return "고객상담";
      } else if (status === "FIXED_SCHEDULE") {
        return "일정확정";
      } else if (status === "COMPLETE") {
        return "완료";
      } else if (status === "CANCEL") {
        return "취소";
      }
    },
    showAsReceipt() {
      const modal = this.modal.asReceipt;
      modal.visible = true;
    },
    showCopyCommentModal() {
      const modal = this.modal.copyComment;
      modal.visible = true;
      // const component = this.component.commentEntity;
      // component.selectComment = true;
    },
    showAddSchedulePage(type) {
      const estimate = this.support.estimate;
      const query = {
        viewType: ScheduleViewType.ESTIMATE,
        estimateId: estimate.id,
      } as any;
      this.$router.push({
        path: `/schedule/${type}`,
        query: query,
      });
    },
    async updateSupportActivated(activated) {
      try {
        const support = await SupportService.updateActivated(this.support.estimateId, activated);
        this.support = support;
        // console.log("support : ", support);
        core.alert.show({
          title: "알림",
          body: "처리 완료로 변경되었습니다.",
        });
      } catch (e) {
        console.log(e);
      }
    },
    getTitle() {
      let title = "";
      if (this.support != null) {
        const estimate = this.support.estimate;
        if (estimate != null) {
          title = `${estimate.place.name}`;
          if (this.isNotBlank(estimate.dong)) {
            title += ` ${estimate.dong}동`;
          }
          if (this.isNotBlank(estimate.ho)) {
            title += ` ${estimate.ho}호`;
          }
        } else {
          title = "";
        }
      }
      return title;
    },
    showEstimatePage() {
      this.$router.push(`/estimate/${this.support.estimate.id}`);
    },
  },
});
