
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    field: "",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "TextAreaModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            length: {
              min: 1,
              max: 100,
            },
            type: "add",
            title: "",
            enableDeleteButton: false,
            text: "",
            item: null as any,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    ready: false,
    length: {
      min: 1,
      max: 100,
    },
    type: "",
    text: "",
    visible: {
      deleteBtn: false,
    },
  }),
  async created() {
    // 생성 후 호출
    const properties = this.properties;
    this.type = properties.params.type;
    if (properties.params.enableDeleteButton) {
      this.visible.deleteBtn = true;
    }

    if (properties.params.length != null) {
      if (properties.params.length.min != null) {
        this.length.min = properties.params.length.min;
      }
      if (properties.params.length.max != null) {
        this.length.max = properties.params.length.max;
      }
    }

    if (this.type !== "add" && this.type !== "edit") {
      await this.notFound();
    }

    if (this.type === "edit") {
      if (properties.params.text == null) {
        await this.notFound();
      } else {
        this.text = properties.params.text;
      }
    }
    this.ready = true;
  },
  methods: {
    rules() {
      return "required|min:" + this.length.min + "|max:" + this.length.max;
    },
    async submit() {
      if (await this.validate()) {
        this.close(UpdateEventType.CONFIRM, this.text);
      }
    },
    async deleteItem() {
      const item = this.properties.params.item;
      this.close(UpdateEventType.DELETE, item);
    },
  },
});
