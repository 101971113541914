
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import { UpdateEventType } from "@/types";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    team: "팀",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "EstimateSelectTeamModal",
  data: () => ({
    categoryId: null as any,
    teamId: null as any,
    list: [],
  }),
  created() {
    // 생성 후 호출
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.list = this.properties.params.list;
      this.categoryId = this.properties.params.categoryId;
      this.teamId = this.properties.params.teamId;
    });
  },
  methods: {
    async submit() {
      this.close(UpdateEventType.CONFIRM, { categoryId: this.categoryId, teamId: this.teamId });
    },
  },
});
