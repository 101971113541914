import {
  getPreStack,
  getPreStackInstance,
  getStack,
  VuePageStack,
} from "./components/VuePageStack";
import mixin from "./mixin";
import config from "./config";
import { RouterBeforeTask } from "./router-before-task";

function textGenerator(length: number): string {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
  let text = "";
  for (let i = 0; i < length; i++) {
    const num = Math.floor(Math.random() * chars.length);
    text += chars.substring(num, num + 1);
  }
  return text;
}

class VuePageStackPlugin {
  install(
    Vue: any = null,
    {
      router = null as any,
      routerBeforeTask: routerBeforeTask = new RouterBeforeTask(),
      name = config.componentName,
      keyName = config.keyName,
    }
  ) {
    if (!router) {
      throw Error("\n vue-router is necessary. \n\n");
    }
    Vue.component("vue-page-stack", VuePageStack(keyName));

    Vue.prototype.$pageStack = {
      getStack,
      getPreStack,
      getPreStackInstance,
    };

    mixin(router);

    function beforeEach(to: any, from: any, next: any) {
      if (to.meta && to.meta.pageStack) {
        if (to.query[keyName] == null) {
          to.query[keyName] = textGenerator(8);
          next({
            hash: to.hash,
            path: to.path,
            name: to.name,
            params: to.params,
            query: to.query,
            meta: to.meta,
            replace: false,
          });
          return;
        }
      }

      const event = (window as any).event;
      if (event != null && event.type === "popstate") {
        // 뒤로가기 눌렀을 경우에만 페이지 이동 처리 확인
        if (!routerBeforeTask.isRouterNext()) {
          //console.log("ignore router event");
          // 20230223 - next(false)시 스크롤 상단으로 가는 현상이 있어서 추가함
          const scrollTop = document.documentElement.scrollTop;
          next(false);
          setTimeout(() => {
            document.documentElement.scrollTop = scrollTop;
          }, 0);
          return;
        }
      }
      routerBeforeTask.clear();

      next();
    }

    // ensure it's the first beforeEach hook
    router.beforeHooks.unshift(beforeEach);
  }
}

export default new VuePageStackPlugin();
