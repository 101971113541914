import core from "@/core";

class ExpoEventService {
  get() {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`/api/expo`, null, null)
        .then((data: any) => {
          if (data.expo != null) {
            resolve(data.expo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
  update(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/expo`, strParams)
        .then((data: any) => {
          if (data.expo != null) {
            resolve(data.expo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createImage(formData: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`/api/expo/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data: any) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ExpoEventService();
