
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimateComponent from "@/components/estimate/EstimateComponent.vue";
import UpdateEvent from "@/models";
import AppBar from "@/components/core/AppBar.vue";
import { cloneDeep } from "lodash";
import { LayoutType } from "@/router";
import CounselEstimateComponent from "@/components/estimate/CounselEstimateComponent.vue";

export default mixins(MixinsPage).extend({
  name: "CounselEstimate",
  components: { CounselEstimateComponent, EstimateComponent, AppBar },
  data: () => ({
    type: "",
    ready: false,
    properties: {
      visible: false,
      updateEvent: null as UpdateEvent | null,
    },
  }),
  mounted() {
    this.$nextTick(() => {
      this.properties.visible = true;
      this.ready = true;
    });
  },
  watch: {},
  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      this.properties.updateEvent = event;
    }
  },
  methods: {},
});
