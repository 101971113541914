
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsStorage from "@/mixins/single/storage";
import MixinsTable from "@/mixins/single/table";
import core from "@/core";
import store from "@/store";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import { UpdateEventType } from "@/types";
import UserListItemComponent from "@/components/user/ListItemComponent.vue";
import MixinsScroll from "@/mixins/single/scroll";
import PlaceService from "@/services/work/place.service";

export default mixins(MixinsPageForm, MixinsStorage, MixinsTable, MixinsScroll).extend({
  name: "MgmtPlaceSearch",
  components: { UserListItemComponent },
  data: () => ({
    history: "search",
    storageItems: {
      type: "mgmt-place-search",
    },
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    historyItems: [] as any,
    historyFilterItems: [] as any,
    submitSearchKeyword: null as string | null,
    searchKeyword: null as string | null,
    searchKeywordFocus: false,
    searchKeywordFocusTime: new Date().getTime(),
    searchType: "keyword", // keyword, detail
    preViewType: "",
    viewType: "history", // history, detail, result
    keywordPromise: {
      wait: null as any,
      resolve: null as any,
    },
  }),

  mounted() {
    this.$nextTick(async () => {
      await this.storageWait();
      const items = this.getStorageItems(this.history);
      if (items != null) {
        this.historyItems = items;
      } else {
        this.setStorageItem(this.history, this.historyItems);
      }

      // 테이블 초기화
      this.initTable({
        service: PlaceService,
        serviceFunctionKey: "getTable",
        itemHeight: 48,
      });

      this.changedSearchKeyword(this.searchKeyword);
      this.keywordPromise.wait = new Promise((resolve, reject) => {
        this.keywordPromise.resolve = resolve;
      });

      const vm = this as any;
      const routerBeforeTask = store.state.app.routerBeforeTask;
      const routerBeforeTaskItem = new RouterBeforeTaskItem(vm.$vnode.tag, () => {
        //console.log("viewType : ", vm.viewType, ", searchType : ", this.searchType);
        if (vm.searchType === "detail") {
          if (vm.preViewType === "result") {
            vm.viewType = "result";
          } else {
            vm.searchType = "keyword";
          }
          return false;
        }
        return true;
      });
      routerBeforeTask.pushPage(routerBeforeTaskItem);

      this.moveFocus("searchKeyword");

      this.focusIn();
    });
  },

  computed: {
    tableDataList() {
      return this.table.response.data;
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },

  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      this.updatePreVmEvent(event.result, event.item);

      if (event.result === UpdateEventType.UPDATE) {
        this.addTableItem("id", event.item);
      } else if (event.result === UpdateEventType.DELETE) {
        //console.log("event : ", event);
        this.deleteTableItem("id", event.item);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },

  watch: {
    "scroll.scrollTop"(scrollTop) {
      // console.log("scrollTop : ", scrollTop);
      this.scrollTop = scrollTop;
    },
    searchKeyword(val) {
      this.changedSearchKeyword(val);
      //console.log("changed searchKeyword");
      this.keywordPromise.resolve();
    },
    searchType(type) {
      if (type === "keyword") {
        this.viewType = "history";
      } else if (type === "detail") {
        this.viewType = "detail";
      } else {
        console.log("Unknown Type : ", type);
      }
    },
    viewType(val, prev) {
      this.preViewType = prev;

      // viewType 변경시, 스크롤바 위로 이동
      const $el = this.$refs.body as any;
      if ($el != null) {
        $el.scrollTop = 0;
      }
    },
  },
  methods: {
    showViewPage(id: string) {
      this.$router.push({ path: `/mgmt/place/edit`, query: { id: id } });
    },
    getHistoryDate(item: any) {
      return this.dateElapsedTimeFormat(
        core.date.instance(new Date(item.date)).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    isSearch() {
      return this.table.request.draw > 0;
    },
    isResultEmpty() {
      return this.table.request.draw > 0 && this.table.response.data.length == 0;
    },
    changedSearchKeyword(searchKeyword) {
      const isBlank = this.isBlank(searchKeyword);
      const filterItems = [] as any;

      this.historyItems.some((item: any) => {
        if (isBlank || item.keyword.indexOf(searchKeyword) > -1) {
          filterItems.push(item);
        }
        if (filterItems.length === 20) {
          return true;
        }
      });
      this.historyFilterItems = filterItems;
      if (filterItems.length > 0 && this.searchKeywordFocus) {
        // TODO : 이력정보 표시?
        //this.visibleHistory = true;
      }
    },
    focusIn() {
      this.searchKeywordFocus = true;
      this.searchKeywordFocusTime = new Date().getTime();
      this.viewType = "history";
    },
    focusOut() {
      if (new Date().getTime() - this.searchKeywordFocusTime > 100) {
        this.searchKeywordFocus = false;
      }
    },
    submit() {
      (this.$refs.searchKeyword as any).blur();
      this.searchKeywordFocus = false;

      const searchKeyword = this.searchKeyword as string;
      if (this.isNotBlank(searchKeyword)) {
        this.viewType = "result";

        this.submitSearchKeyword = searchKeyword;
        //console.log("submit searchKeyword : ", this.searchKeyword);

        // 키워드 스토리지 동일 항목 제외, 최대 100개 저장
        this.historyItems.some((item: any, index) => {
          if (item.keyword === searchKeyword) {
            this.historyItems.splice(index, 1);
            return true;
          }
        });

        const diffLength = this.historyItems.length - 99;
        if (diffLength > 0) {
          for (let i = 0; i < diffLength; i++) {
            this.historyItems.splice(this.historyItems.length - 1, 1);
          }
        }

        this.historyItems.unshift({
          keyword: searchKeyword,
          date: new Date().getTime(),
        });
        this.saveStorageItems();

        const searchColumns = this.keywordToSearchColumns(searchKeyword);
        this.clearTable();
        this.loadTable(searchColumns);
      } else {
        core.alert.show({
          title: "알림",
          body: "키워드를 입력하세요!",
        });
      }
    },
    async selectHistory(item: any) {
      //console.log("selectHistory : ", item);

      const changedSearchKeyword = this.searchKeyword !== item.keyword;

      if (changedSearchKeyword) {
        this.searchKeyword = item.keyword;
        await this.keywordPromise.wait;
      }

      if (this.submitSearchKeyword !== item.keyword) {
        if (!changedSearchKeyword) {
          this.changedSearchKeyword(this.searchKeyword);
        }
        this.submit();
      } else {
        this.changedSearchKeyword(this.searchKeyword);
        if (this.viewType === "history") {
          this.viewType = "result";
        }
      }
    },

    keywordToSearchColumns(searchKeyword: string) {
      return { name: searchKeyword };
    },
  },
});
