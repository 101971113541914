import { TableRequest } from "@/types";
import core from "@/core";

class TeamService {
  getTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/team/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getList() {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/team`)
        .then((data: any) => {
          const item = data["teamList"];
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  // getTeamList() {
  //   return new Promise((resolve: any, reject) => {
  //     core.http
  //       .getJson(`/api/team`)
  //       .then((data: any) => {
  //         const item = data["teamList"];
  //         if (item != null) {
  //           resolve(item);
  //         } else {
  //           reject({
  //             message: "not found item",
  //           });
  //         }
  //       })
  //       .catch((reason: any) => {
  //         reject(reason);
  //       });
  //   });
  // }

  get(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/team/${id}`)
        .then((data: any) => {
          const item = data["team"];
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    const strParams = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/team`, strParams)
        .then((data: any) => {
          const item = data["team"];
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`/api/team/${id}`, strJson)
        .then((data: any) => {
          const item = data["team"];
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/team/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getUserList(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`/api/team/${id}/users`)
        .then((data: any) => {
          const item = data["userList"];
          if (item != null) {
            this.convertUserList(item);
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  convertUserList(userList) {
    userList.forEach((user) => {
      user.text = `${user.name}`;
    });
  }

  addUser(id: number, userId: number, teamRole: string) {
    return new Promise((resolve: any, reject) => {
      const strJson = JSON.stringify({ teamRole: teamRole });
      core.http
        .postJson(`/api/team/${id}/user/${userId}`, strJson)
        .then((data: any) => {
          const item = data["user"];
          if (item != null) {
            resolve(item);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  deleteUser(id: number, userId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/team/${id}/user/${userId}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new TeamService();
