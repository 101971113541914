
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    status: "진행상태 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "SupportAsStatusModal",
  data: () => ({
    status: "",
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // const item = this.properties.params.item;
      // if (item != null) {
      //   if (item.confirmedAt == null) {
      //     const _item = await NotificationService.update(item.id, "");
      //     core.utils.copy(_item, this.properties.params.item);
      //   }
      //   if (item.addData != null && this.isNotBlank(item.addData.path)) {
      //     this.page = item.addData.path;
      //   }
      // }
    });
  },
  methods: {
    movePage(page) {
      this.$router.push(page);
    },
  },
});
