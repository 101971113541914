
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import UserListItemComponent from "@/components/user/ListItemComponent.vue";
import CompanyService from "@/services/company/company.service";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    username: "아이디 ",
    name: "이름 ",
    phone: "휴대전화 ",
    companyRole: "업체권한 ",
  },
});

export default mixins(MixinsPageForm, MixinsTable, MixinsScroll, MixinsAppBarMenu).extend({
  name: "MgmtUser",
  components: { UserListItemComponent },

  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
  }),
  mounted() {
    // 테이블 초기화
    this.initTable({
      service: CompanyService,
      serviceFunctionKey: "getUserTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
  },
  activated() {
    this.$nextTick(() => {
      const event = this.getPageUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (event.path.indexOf("search") > -1) {
            this.updateTableItem("id", event.item);
          } else {
            this.addTableItem("id", event.item);
          }
          this.setScrollPosition(ScrollPosition.TOP);
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown event : ", event);
        }
      }
    });
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      console.log("appBarMenuEvent : ", menu);
      if (menu.id === "search") {
        this.$router.push({
          path: "/mgmt/user/search",
        });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
  },
});
