import core from "@/core";

class SmsService {
  createSms(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/sms`, strParams)
        .then((data: any) => {
          resolve(data.smsPushId);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSmsResult(smsPushId: string) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/sms/${smsPushId}/result`, { ignoreAlertModal: true })
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new SmsService();
