
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import UpdateEvent from "@/models";
import UnitPriceAddEditComponent from "@/components/unit-price/AddEditComponent.vue";

export default mixins(MixinsPageForm).extend({
  name: "MgmtUnitPriceAddEdit",
  components: { UnitPriceAddEditComponent },
  data: () => ({
    ready: false,
    type: "",
    title: "",
    unitPriceId: 0,
    component: {
      updateEvent: null as UpdateEvent | null,
    },
    place: null as any,
    category: null as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const params = this.$route.params as any;
      this.type = params.type;
      if (this.type === "add") {
        this.title = "단가 추가";
      } else if (this.type === "edit") {
        this.title = "단가 수정";
        const query = this.$route.query as any;
        this.unitPriceId = Number(query.id);
        if (this.unitPriceId <= 0) {
          this.notFound();
          return;
        }
      }
      this.ready = true;
    });
  },
  watch: {
    type(val) {
      if (val === "add") {
        this.title = "단가 추가";
      } else if (val === "edit") {
        this.title = "단가 수정";
      }
    },
    title() {
      this.$store.dispatch("topToolbar/changeTitle", { title: this.title, ignoreCheck: true });
    },
    "component.updateEvent"(event) {
      if (event != null) {
        console.log("event : ", event);
      }
    },
  },

  activated() {
    //console.log("activated");
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.path.indexOf("/place") > -1) {
        this.place = event.item;
        console.log("change place : ", this.place);
      } else if (event.path.indexOf("/category/add") > -1) {
        console.log("add category : ", event.item);
        this.category = event.item;
      }
    }
  },
});
