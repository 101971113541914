
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import MixinsTable from "@/mixins/single/table";
import UnitPriceService from "@/services/unit-price/unit-price.service";
import UnitPriceListItemComponent from "@/components/unit-price/ListItemComponent.vue";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModalForm, MixinsTable).extend({
  name: "LoadUnitPriceModal",
  components: { UnitPriceListItemComponent },
  data: () => ({
    viewType: "list",
    searchKeyword: "",
    changedSearchKeywordDate: null as Date | null,
    interval: null as any,
    table: {
      body: document.documentElement,
      defaultColumns: [],
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 초기화
      this.initTable({
        service: UnitPriceService,
        serviceFunctionKey: "getTable",
        itemHeight: 48,
      });
      this.loadTable();
    });
  },
  computed: {
    tableDataList() {
      return this.table.response.data;
    },
  },

  watch: {
    searchKeyword(keyword) {
      this.changedSearchKeywordDate = new Date();

      if (this.isNotBlank(keyword)) {
        if (this.interval == null) {
          this.interval = setInterval(() => {
            const changedSearchKeywordDate = this.changedSearchKeywordDate;
            if (changedSearchKeywordDate == null) {
              clearInterval(this.interval);
              this.interval = null;
              return;
            }
            const date = new Date();

            const elapsedTimeMillis = date.getTime() - changedSearchKeywordDate.getTime();
            if (elapsedTimeMillis > 1000) {
              clearInterval(this.interval);
              this.interval = null;
              this.loadTable({ placeNameLike: this.searchKeyword });
            }
          }, 200);
        }
      } else {
        if (this.interval != null) {
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    },
  },
  methods: {
    async submit() {
      this.changedSearchKeywordDate = null;
      if (this.isNotBlank(this.searchKeyword)) {
        this.loadTable({ placeNameLike: this.searchKeyword });
      }
    },
    onSelect(item) {
      console.log("selected item : ", item);
      this.close(UpdateEventType.CONFIRM, item);
    },
  },
});
