export default class CoreLoader {
  element: HTMLElement;
  textElement: HTMLElement | null;
  visible = false;

  constructor() {
    const style = document.createElement("style");
    style.innerText =
      ".kn-loader{transition:.1s ease opacity,.1s ease transform;display:none;position:fixed;width:100%;height:100%;z-index:9999;top:0;left:0;background:radial-gradient(rgba(20,20,20,.4),rgba(0,0,0,.4));background:-webkit-radial-gradient(rgba(20,20,20,.4),rgba(0,0,0,.4))}.kn-loader-wrap{display:table;width:100%;height:100%;color:#cbcbcb}.kn-loader-content{display:table-cell;vertical-align:middle;text-align:center}.kn-loader-icon{margin:0 auto;width:50px;height:50px;border:solid 3px transparent;border-top-color:#29d;border-left-color:#29d;border-radius:50%;-webkit-animation:kn-loader-spinner 1s linear infinite;-moz-animation:kn-loader-spinner 1s linear infinite;-ms-animation:kn-loader-spinner 1s linear infinite;-o-animation:kn-loader-spinner 1s linear infinite;animation:kn-loader-spinner 1s linear infinite}.kn-loader-text{margin-top:20px;font-weight:600}@-webkit-keyframes kn-loader-spinner{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@-moz-keyframes kn-loader-spinner{0%{-moz-transform:rotate(0);transform:rotate(0)}100%{-moz-transform:rotate(360deg);transform:rotate(360deg)}}@-o-keyframes kn-loader-spinner{0%{-o-transform:rotate(0);transform:rotate(0)}100%{-o-transform:rotate(360deg);transform:rotate(360deg)}}@-ms-keyframes kn-loader-spinner{0%{-ms-transform:rotate(0);transform:rotate(0)}100%{-ms-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes kn-loader-spinner{0%{transform:rotate(0);transform:rotate(0)}100%{transform:rotate(360deg);transform:rotate(360deg)}}";
    document.head.appendChild(style);

    this.element = document.createElement("div");
    this.element.classList.add("kn-loader");
    this.element.innerHTML =
      '<div class="kn-loader-wrap"> <div class="kn-loader-content"> <div class="kn-loader-icon"></div><div class="kn-loader-text">Loading...</div></div></div>';
    document.body.insertBefore(this.element, document.body.firstChild);
    this.textElement = this.element.querySelector(".kn-loader-text");
  }

  show(msg?: string) {
    if (msg == null) msg = "";
    if (this.textElement != null) {
      this.textElement.innerHTML = msg;
    }
    const el = this.element;
    el.style.opacity = "1";
    el.style.display = "block";
  }

  hide() {
    const el = this.element;
    el.style.opacity = "0";
    el.style.display = "none";
  }
}
