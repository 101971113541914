
import mixins from "vue-typed-mixins";
import core from "@/core";
import { NavMenu, NavMenuType } from "@/models/core/nav-menu.model";

export default mixins().extend({
  name: "MenuListItemComponent",
  computed: {
    NavMenuType() {
      return NavMenuType;
    },
  },
  props: {
    item: {
      type: Object as () => NavMenu,
    },
  },
  methods: {
    isNotBlank(content) {
      return core.utils.validate.isNotBlank(content);
    },
  },
});
