
import Vue from "vue";
import core from "@/core";
import CompanyService from "@/services/company/company.service";

export default Vue.extend({
  name: "CompanyViewComponent",
  components: {},
  props: {
    company: {
      type: Object,
      default: () => {
        return {
          id: 0,
          role: "",
          name: "",
          ceoName: "",
          phone: "",
          detailList: [],
          lastApproval: {
            id: 0,
            type: "",
          },
        };
      },
    },
  },
  data: () => ({
    form: {
      name: "",
      ceoName: "",
      phone: "",
      detailList: [] as any,
    },
  }),
  created() {
    // 생성후 호출
    console.log("created");
    const company = this.company;
    this.form.name = company.name;
    this.form.ceoName = company.ceoName;
    this.form.phone = company.phone;
    const detailList = this.form.detailList;
    if (company.detailList != null) {
      company.detailList.forEach((detail: any) => {
        if (detail.type == "BANK_ACCOUNT") {
          const array = detail.value.split(",");
          if (array.length === 2) {
            detailList.push({
              id: detail.id,
              bankName: array[0],
              bankAccount: array[1],
            });
          } else {
            console.log("unknown value : ", detail.value);
          }
        } else {
          console.log("unknown type : ", detail.type);
        }
      });
    }
  },
  mounted() {
    // 페이지 로딩 후 호출
  },
  watch: {
    "form.phone"() {
      this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
    },
  },
  methods: {
    async unregister() {
      {
        const alertResult = await core.alert.show({
          title: "확인",
          body: "업체 멤버 등록을 해제하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });
        console.log(alertResult);
        if (alertResult !== "confirm") {
          return;
        }
      }
      core.loader.show();
      try {
        const result = await CompanyService.unregister();
        //console.log("result : ", result);

        const user = await this.$store.getters["auth/user"](true);
        core.loader.hide();
        const alertResult = await core.alert.show({
          title: "알림",
          body: "업체 멤버 등록이 해제되었습니다.",
        });
        await this.$router.back();
      } catch (e) {
        core.loader.hide();
        console.log(e);
      }
    },
  },
});
