
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { UpdateEventType } from "@/types";

import PaymentSmsService from "@/services/payment/payment-sms.service";
import core from "@/core";

export default mixins(MixinsModalForm).extend({
  name: "PaymentSmsModal",
  data: () => ({
    item: null as any,
    form: {
      memo: "",
      status: "",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const item = (await PaymentSmsService.get(this.properties.params.id)) as any;
      let content = "";
      const array = item.content.split("\n");
      array.forEach((line) => {
        if (line.indexOf("잔액") === -1) {
          content += line + "\n";
        }
      });
      item.content = content;
      item.callNumber = core.utils.format.hyphenPhone(item.callNumber);
      this.form.status = item.status;
      //console.log("item : ", content);
      this.item = item;
      if (item.options.memo) {
        this.form.memo = item.options.memo;
      } else {
        this.form.memo = "";
      }
    });
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        const item = await PaymentSmsService.update(this.item.id, {
          status: "C",
          memo: this.form.memo,
        });
        this.close(UpdateEventType.CONFIRM, item);
      }
    },
  },
});
