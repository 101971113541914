
import store from "@/store";
import core from "@/core";
import UserService from "@/services/user/user.service";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";

export default mixins(MixinsPageForm).extend({
  name: "UserMeWithdraw",
  components: {},
  data: () => ({
    app: store.state.app,
    processing: false,
    form: {
      checkbox: false,
    },
  }),
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    async submit() {
      core.loader.show();
      this.processing = true;
      try {
        const form = this.form;

        await UserService.withdraw();

        this.$store.state.auth.sessionCheckTime = new Date();
        this.$store.state.auth.user = null;
        core.loader.hide();

        await core.alert.show({
          title: "알림",
          body: "회원이 탈퇴되었습니다. 이용해주셔서 감사합니다.",
        });
        await this.$router.push("/logout");
      } catch (e) {
        core.loader.hide();
        this.errorSubmit(e);
      }
      this.processing = false;
    },
  },
});
