
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import { UpdateEventType } from "@/types";
import ScheduleService from "@/services/work/schedule.service";
import ScheduleUtils from "@/models/schedule";
import core from "@/core";

export default mixins(MixinsPage, MixinsTable, MixinsScroll).extend({
  name: "DashboardScheduleMore",
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    title: "",
    visible: {
      dDay: false,
    },
    content: null as any,
  }),
  mounted() {
    this.initTable({
      service: ScheduleService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const viewType = ScheduleUtils.getMoreViewType(this.$route.query.viewType as string);
      if (viewType == null) {
        await this.notFound();
        return;
      }
      const tableParams = ScheduleUtils.getTableParams(viewType);

      this.title = tableParams.title;
      this.table.request.order = tableParams.order;
      this.table.request.orderColumnName = tableParams.orderColumnName;
      this.table.request.searchColumns = tableParams.searchColumns;
      this.content = tableParams.content;

      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
  },
  async activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (
        event.result === UpdateEventType.RELOAD ||
        event.result === UpdateEventType.UPDATE ||
        event.result === UpdateEventType.DELETE
      ) {
        this.reloadTable();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },
  methods: {
    getRemainingDays(estimate: any) {
      const curDate = core.date.instance();
      const expectedDate = core.date.instance(estimate.expectedDate);
      return expectedDate.diff(curDate, "days");
    },
    showViewPage(scheduleId: number) {
      this.$router.push({ path: `/schedule/${scheduleId}` });
    },
    scheduleText(item) {
      const date = this.scheduleDateText(item);

      let text = "";
      if (item.category != null) {
        text = `${item.category.name} (${date})`;
      } else {
        text = date;
      }

      if (item.workType === "A") {
        return `A/S ${text}`;
      }
      return text;
    },
    scheduleDateText(item) {
      const startDate = core.date.instance(item.startAt).format("YYYY-MM-DD");
      const endDate = core.date.instance(item.endAt).format("YYYY-MM-DD");
      if (startDate == endDate) {
        return core.date.instance(item.startAt).format("YYYY년 MM월 DD일");
      } else {
        return (
          core.date.instance(item.startAt).format("YYYY년 MM월 DD일") +
          " ~ " +
          core.date.instance(item.endAt).format("YYYY년 MM월 DD일")
        );
      }
    },
  },
});
