
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import { EntityType, UpdateEventType } from "@/types";
import EventService from "@/services/event/event.service";
import UpdateEvent from "@/models";
import EstimateService from "@/services/work/estimate.service";
import SmsService from "@/services/sms.service";

export default mixins(MixinsModalForm).extend({
  name: "EventRouletteWinnerEditModal",
  data: () => ({
    processing: false,
    item: null as any,
    form: {
      phone: "",
      dong: "",
      ho: "",
    },
    modal: {
      placeType: {
        visible: false,
        selected: null as any,
        list: [] as any,
        updateEvent: null as UpdateEvent | null,
      },
    },
    event: null as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.item = this.properties.params.item;
      this.form.phone = this.item.phone;
      this.form.dong = this.item.dong;
      this.form.ho = this.item.ho;
      //console.log("item : ", this.item);
    });
  },
  watch: {
    "form.phone"(val) {
      this.form.phone = core.utils.format.hyphenPhone(val);
    },
    "modal.placeType.selected"(type) {
      this.modal.placeType.selected = null;
      if (type != null) {
        const placeId = this.event.place.id;
        this.createSpecialUnitPrice(placeId, type);
      }
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        //console.log("submit");
        this.close(UpdateEventType.CONFIRM, this.form);
      }
    },
    deleteItem() {
      this.close(UpdateEventType.DELETE, this.form);
    },
    async sendSpecialUnitPrice() {
      // 특가표 보내기
      // console.log("item : ", this.item);
      const event = (await EventService.get(this.item.eventId)) as any;
      this.event = event;
      // console.log("event : ", event);
      const place = event.place;
      const typeList = [] as any;
      const placeTypeList = place.typeList.split(",");
      placeTypeList.forEach((type) => {
        typeList.push({ id: type, name: type });
      });
      this.modal.placeType.list = typeList;
      this.modal.placeType.visible = true;
    },
    async existsEstimate(placeId: number) {
      const params = {
        entityType: EntityType.ESTIMATE,
        placeId: placeId,
        dong: this.form.dong,
        ho: this.form.ho,
      } as any;

      const apiResult = (await EstimateService.existsEstimate(params)) as any;
      if (apiResult.exists) {
        const result = await core.alert.show({
          title: "확인",
          body: "이미 작성된 견적이 존재합니다.<br>견적서를 불러오시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });
        if (result === "confirm") {
          this.$router.push({
            path: `/estimate/${apiResult.estimateId}`,
          });
          return false;
        }
        return false;
      }
      return true;
    },
    async createSpecialUnitPrice(placeId: number, type: string) {
      try {
        core.loader.show("특가표 전송중...");

        if (!(await this.existsEstimate(placeId))) {
          return false;
        }

        const params = {
          entityType: EntityType.ESTIMATE,
          placeId: placeId,
          type: type,
          customerPhone: this.form.phone,
          customerName: "익명",
          memo: "",
          remark: "",
          detailList: [],
          expectedDate: null,
          dong: this.form.dong,
          ho: this.form.ho,
          contractStatus: "SPECIAL_PRICE",
        } as any;

        const estimate = (await EstimateService.createTemporary(params)) as any;
        await this.sendSms(estimate.id);

        let result = (await core.alert.show({
          title: "확인",
          body: "견적서 정보를 확인하러 이동하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        })) as string;
        if (result === "confirm") {
          this.$router.push(`/estimate/${estimate.id}`);
        }
      } catch (e: any) {
        console.log(e);
        core.alert.show({
          title: "알림",
          body: e.message,
        });
      } finally {
        core.loader.hide();
      }
    },
    async sendSms(estimateId: number) {
      let promiseResolve = null as any;
      let promiseReject = null as any;
      try {
        core.loader.show("문자 전송중...");
        const smsPushId = await EstimateService.sendSms(estimateId, { type: "ESTIMATE" });
        const interval = setInterval(async () => {
          try {
            const apiResult = (await SmsService.getSmsResult(smsPushId as string)) as any;
            if (apiResult.result) {
              clearInterval(interval);
              core.loader.hide();
              await core.alert.show({
                title: "알림",
                body: "문자 전송 완료",
              });
              promiseResolve();
            } else if (apiResult.code < 0) {
              clearInterval(interval);
              core.loader.hide();
              await core.alert.show({
                title: "알림",
                body: apiResult.message,
              });
              promiseReject();
            }
          } catch (e) {
            console.log(e);
            promiseReject();
          }
        }, 2000);
      } catch (e) {
        console.log(e);
      }
      return new Promise((resolve: any, reject) => {
        promiseResolve = resolve;
        promiseReject = reject;
      });
    },
  },
});
