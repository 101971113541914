
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import NotificationViewModal from "@/modals/notification/NotificationViewModal.vue";
import { UpdateEventType } from "@/types";
import NotificationService from "@/services/notification/notification.service";
import MixinsTable from "@/mixins/single/table";
import { cloneDeep } from "lodash";
import core from "@/core";
import MixinsScroll from "@/mixins/single/scroll";

export default mixins(MixinsPageForm, MixinsTable, MixinsScroll).extend({
  name: "Notification",
  components: { NotificationViewModal },
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      request: {
        orderColumnName: "createdAt",
        searchColumns: {
          // confirmedAtIsNull: true,
        },
      },
      firstLoading: true,
      loading: false,
    },
    modal: {
      notification: {
        visible: false,
        params: {
          item: null as any,
        },
        updateEvent: null as any as UpdateEventType,
      },
    },
  }),
  mounted() {
    // 테이블 초기화
    this.initTable({
      service: NotificationService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    this.$nextTick(async () => {
      core.loader.show("알림 처리중...");

      const notificationList = cloneDeep(this.app.data.notificationList);
      try {
        // console.log("list : ", list);
        for (let i = 0; i < notificationList.length; i++) {
          const item = notificationList[i];
          await NotificationService.update(item.id, "");
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }

      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "properties.updateEvent"() {
      this.clearTable();
      this.loadTable();
    },
  },
  methods: {
    showViewModal(item) {
      const modal = this.modal.notification;
      modal.params.item = item;
      modal.visible = true;
      //console.log("item : ", item);
      // this.$router.push(`/support/${id}`);
    },
  },
});
