
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CompanyService from "@/services/company/company.service";
import core from "@/core";
import TeamService from "@/services/team/team.service";
import { UpdateEventType } from "@/types";
import store from "@/store";

export default mixins(MixinsPage).extend({
  name: "MgmtTeamView",
  data: () => ({
    title: "",
    id: 0,
    item: null as any,
    selectedCategory: null as any,
    categoryList: [] as any,
    companyUserList: [] as any,
    user: {
      leader: {
        title: "팀장",
        teamRole: "LEADER",
        list: [] as any,
      },
      member: {
        title: "팀원",
        teamRole: "MEMBER",
        list: [] as any,
      },
    },
    modal: {
      user: {
        visible: false,
      },
    },
    addUserItem: {
      selectedUser: null as any,
      searchText: "",
      teamRole: "",
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.id = Number(this.$route.params.id);
      if (this.id < 1) {
        await this.notFound();
        return;
      }
      this.item = (await TeamService.get(this.id)) as any;
      this.title = `${this.item.category.name} ${this.item.name} 팀 ${
        this.item.enabled ? "" : "[비활성]"
      }`;

      await this.reloadData();
    });
  },
  async activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      // console.log("event : ", event);
      if (event.result === UpdateEventType.UPDATE) {
        this.item = event.item;
        this.title = `${this.item.category.name} ${this.item.name} 팀 ${
          this.item.enabled ? "" : "[비활성]"
        }`;
        this.updatePreVmEvent(event.result, event.item);
      } else if (event.result === UpdateEventType.DELETE) {
        this.goBack(event.result, event.item);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },
  watch: {
    "addUserItem.selectedUser"(user: any) {
      this.addUserItem.selectedUser = null;
      if (user != null) {
        this.addUser(user, this.addUserItem.teamRole);
      }
    },
    title() {
      store.dispatch("topToolbar/changeTitle", { title: this.title, ignoreCheck: true });
    },
  },
  methods: {
    getStringTitleViewType(item: any) {
      if (item.titleViewType === "PRICE_ALL") {
        return "제목 팀원 금액 표시중";
      } else if (item.titleViewType === "PRICE_LEADER") {
        return "제목 팀장 금액 표시중";
      } else if (item.titleViewType === "TYPE") {
        return "제목 아파트 타입 표시중";
      }
      return "";
    },
    showEditPage() {
      this.$router.push({
        path: "/mgmt/team/edit",
        query: {
          id: String(this.id),
        },
      });
    },
    getPhoneNumber(phone: string) {
      return core.utils.format.hyphenPhone(phone);
    },
    async reloadData() {
      // 모든 사용자 조회
      const companyUserList = (await CompanyService.getUserList({
        lastApprovalType: "ALLOW",
      })) as any;
      //console.log("companyUserList : ", companyUserList);

      // 팀원 정보 조회
      const userList = (await TeamService.getUserList(this.id)) as any;

      this.companyUserList = [];
      companyUserList.forEach((user) => {
        user.text = `${user.name}(${user.username})`;
        let exists = false;
        userList.some((_user) => {
          if (_user.id === user.id) {
            exists = true;
            return true;
          }
        });
        if (!exists) {
          this.companyUserList.push(user);
        }
      });

      const leader = this.user.leader;
      const member = this.user.member;
      leader.list = [] as any;
      member.list = [] as any;

      userList.forEach((user) => {
        if (user.team.role === "LEADER") {
          leader.list.push(user);
        } else {
          member.list.push(user);
        }
        // member.list.push(user);
      });
    },
    async addItem(userItem: any) {
      this.addUserItem.searchText = `추가할 ${userItem.title}을 선택 또는 검색하세요`;
      this.addUserItem.teamRole = userItem.teamRole;
      this.modal.user.visible = true;
    },
    async deleteItem(user: any, userItem: any) {
      const result = await core.alert.show({
        title: "확인",
        body: `${user.name} 사용자를 ${userItem.title}에서 삭제하시겠습니까?`,
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        //console.log("deleteUser : ", user);
        core.loader.show();
        try {
          const result = await TeamService.deleteUser(this.id, Number(user.id));
          //console.log("result : ", result);
        } catch (e) {
          console.log(e);
        }
        await this.reloadData();
        core.loader.hide();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      }
    },
    async addUser(user: any, teamRole: string) {
      core.loader.show();
      const result = await TeamService.addUser(this.id, Number(user.id), teamRole);
      //console.log("result : ", result);
      await this.reloadData();
      core.loader.hide();
      this.updatePreVmEvent(UpdateEventType.RELOAD);
    },
  },
});
