export default class CorePreLoader {
  private element: HTMLElement;
  private visible = false;

  constructor(backgroundStyle?: string) {
    if (backgroundStyle == undefined)
      backgroundStyle = "linear-gradient(90deg,#151515 0%,#003a48 50%,#151515 100%)";

    const style = document.createElement("style");
    style.innerText =
      ".kn-preloader{display:none;background:" +
      backgroundStyle +
      ';position:absolute;top:0;left:0;width:100%;height:100%;z-index:2000}.kn-preloader .kn-preloader-loader{display:block;position:relative;left:50%;top:50%;width:150px;height:150px;margin:-75px 0 0 -75px;border-radius:50%;border:3px solid transparent;border-top-color:#9370db;animation:kn-preloader-spin 2s linear infinite;background:transparent}.kn-preloader .kn-preloader-loader:before{content:"";position:absolute;top:5px;left:5px;right:5px;bottom:5px;border-radius:50%;border:3px solid transparent;border-top-color:#ba55d3;animation:kn-preloader-spin 3s linear infinite}.kn-preloader .kn-preloader-loader:after{content:"";position:absolute;top:15px;left:15px;right:15px;bottom:15px;border-radius:50%;border:3px solid transparent;border-top-color:#f0f;animation:kn-preloader-spin 1.5s linear infinite}@keyframes kn-preloader-spin{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}';
    document.head.appendChild(style);

    this.element = document.createElement("div");
    this.element.classList.add("kn-preloader");
    this.element.innerHTML = '<div class="kn-preloader-loader"></div>';
    document.body.insertBefore(this.element, document.body.firstChild);
  }

  isShow() {
    return this.visible;
  }

  show() {
    const el = this.element;
    el.style.opacity = "1";
    el.style.display = "block";
    this.visible = true;
  }

  hide() {
    const el = this.element;
    el.style.opacity = "0";
    el.style.display = "none";
    this.visible = false;
  }
}
