
import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import EventService from "@/services/event/event.service";
import { UpdateEventType } from "@/types";

export default mixins(MixinsComponent, MixinsTable, MixinsScroll).extend({
  name: "EventComponent",
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
      request: {
        orderColumnName: "updatedAt",
        searchColumns: {},
      },
    },
  }),
  mounted() {
    // 테이블 초기화
    this.initTable({
      service: EventService,
      serviceFunctionKey: "getTable",
      itemHeight: 48,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      //console.log("event");
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "properties.updateEvent"() {
      const event = this.getPropsUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (this.addTableItem("id", event.item)) {
            this.setScrollPosition(ScrollPosition.TOP);
            // 현재 작성한 이벤트 페이지로 이동
            const item = event.item;
            this.$router.push({
              path: `/event/${item.id}`,
            });
          }
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.PAGE) {
          const item = event.item;
          const estimate = item.estimate;
          delete item.estimate;
          if (estimate != null) {
            if (this.addTableItem("id", estimate)) {
              this.setScrollPosition(ScrollPosition.TOP);
            }
          }
          // path, query
          this.$router.push(item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    showAddPage() {
      this.$router.push({
        path: "/event/add",
      });
    },
    showViewPage(id) {
      console.log("showViewPage : ", id);
      this.$router.push({
        path: "/event/" + id,
      });
    },
    statusToHtml(item) {
      if (item.status === "WAIT") {
        return "<span class='orange--text'>이벤트 대기</span>";
      } else if (item.status === "ACTIVE") {
        return "<span class='green--text'>이벤트 시작</span>";
      } else if (item.status === "END") {
        return "<span class='grey--text'>이벤트 종료</span>";
      }
      return "알 수 없음";
    },
  },
});
