import core from "@/core";

class ExpoEventService {
  getList(placeId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`/api/expo/event`, null, { placeId: placeId })
        .then((data: any) => {
          if (data.eventList != null) {
            resolve(data.eventList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/expo/event`, strParams)
        .then((data: any) => {
          if (data.event != null) {
            resolve(data.event);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/expo/event/${id}`, strParams)
        .then((data: any) => {
          if (data.event != null) {
            resolve(data.event);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/expo/event/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createImage(formData: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`/api/expo/event/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data: any) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ExpoEventService();
