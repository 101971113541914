
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import core from "@/core";
import PlaceService from "@/services/work/place.service";
import EventService from "@/services/event/event.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    placeId: "아파트",
    startDate: "이벤트 시작",
    endDate: "이벤트 종료",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "EventAddEdit",

  data: () => ({
    title: "",
    type: "",
    view: {
      placeName: "",
    },
    form: {
      placeId: "",
      startDate: "",
      endDate: "",
      imageType: "",
    },

    // 선택된 아파트
    selectedPlace: null as any,
    placeList: [] as any,
    modal: {
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
    startDatePicker: {
      visible: false,
      min: core.date.instance().format("YYYY-MM-DD"),
      disabled: false,
    },
    endDatePicker: {
      visible: false,
    },
    eventId: 0,
    event: null as any,
  }),
  created() {
    const params = this.$route.params as any;
    this.type = params.type;

    const query = this.$route.query as any;
    if (this.type === "add") {
      this.title = "이벤트 추가";
    } else if (this.type === "edit") {
      this.title = "이벤트 수정";
      if (!isNaN(query.id)) {
        this.eventId = Number(query.id);
      }
      if (this.eventId <= 0) {
        this.notFound();
        return;
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      try {
        this.placeList = await this.$store.getters["app/getPlaceList"]();

        if (this.type === "edit") {
          const event = (await EventService.get(this.eventId)) as any;
          this.event = event;
          // console.log("event : ", event);
          this.form.placeId = event.place.id;
          this.view.placeName = event.place.name;
          this.selectedPlace = event.place;
          this.form.startDate = event.startDate;
          this.form.endDate = event.endDate;
          this.form.imageType = event.imageType;
          if (event.status === "ACTIVE") {
            this.startDatePicker.disabled = true;
          }
        }
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
    });
  },
  watch: {
    "form.startDate"(val, pre) {
      //console.log("change startDate : ", val, pre);
      if (this.isNotBlank(val) && this.isNotBlank(pre) && this.isNotBlank(this.form.endDate)) {
        const days = core.date.instance(this.form.endDate).diff(core.date.instance(pre), "days");
        //console.log("diff days : ", days);
        this.form.endDate = core.date.instance(val).add(days, "days").format("YYYY-MM-DD");
      }
    },
    async selectedPlace(place) {
      if (place != null && this.type === "add") {
        console.log("changed place : ", place);
        // event check
        const exists = await EventService.existsPlace(place.id);
        //console.log("exists : ", exists);
        if (exists) {
          this.selectedPlace = null;
          this.form.placeId = "";
          this.view.placeName = "";

          await core.alert.show({
            title: "알림",
            body: "이미 등록된 이벤트가 존재합니다.",
          });
        }
      }
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        await core.loader.show();
        const params = this.form;
        //console.log("params : ", params);

        let event = null as any;
        if (this.type === "add") {
          event = (await EventService.create(params)) as any;
        } else if (this.type === "edit") {
          event = (await EventService.update(this.eventId, params)) as any;
        }
        //console.log("event : ", event);

        core.loader.hide();
        this.goBack(UpdateEventType.UPDATE, event);
      }
    },
    async deleteItem() {
      const result = await core.alert.show({
        title: "확인",
        body: "이 이벤트를 삭제하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });

      if (result === "confirm") {
        try {
          const result = await EventService.delete(this.eventId);
          // console.log("result : ", result);
          this.goBack(UpdateEventType.DELETE, this.event);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async createPlace(keyword, itemList) {
      try {
        if (itemList != null && itemList.length > 0) {
          let placeName = "";
          itemList.forEach((item) => {
            placeName += item.name + ",";
          });
          placeName = placeName.substr(0, placeName.length - 1);
          const result = await core.alert.show({
            title: "확인",
            body: `비슷한 이름의 [<span class="red--text">${placeName}</span>] 아파트가 존재합니다.<br>새로 생성하시겠습니까?`,
            showCancelButton: true,
            cancelButtonText: "취소",
            confirmButtonText: "생성",
          });
          if (result !== "confirm") return;
        }
        const item: any = await PlaceService.create({ name: keyword });
        await this.$store.dispatch("app/waitForPlaceUpdate", item.id);
        return item;
      } catch (e: any) {
        if (e.status === 400) {
          this.placeList = await this.$store.getters["app/getPlaceList"]();
        }
      }
    },
  },
});
