import Vue from "vue";
import { RouteMeta } from "@/router";

/**
 * 스크롤 공통 처리
 */
export enum ScrollPosition {
  TOP = "top",
  BOTTOM = "bottom",
  RELOAD = "reload",
}

const debug = false;

export default Vue.extend({
  data: () => ({
    // 스크롤 상태 저장
    scroll: {
      enable: true,
      first: true,
      eventFunc: null as any,
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
      interval: null as any,
      intervalCount: 0,
      control: {
        scrollTop: 0,
        date: null as any as Date | null,
      },
      pageStack: false,
    },
  }),
  created() {
    if (this.scroll.enable) {
      const scroll = this.scroll;
      const $vnode = this.$vnode;

      const query = this.$route.query;
      scroll.pageStack =
        query.stackKey != null &&
        query.stackKey.length > 0 &&
        (this.$route.meta as RouteMeta).pageStack;

      const setScrollTop = this.setScrollTop;
      scroll.eventFunc = function () {
        // console.log("scroll");
        const $el = document.documentElement;
        if ($el != null) {
          // if (scroll.control.date != null) {
          //   if (scroll.control.scrollTop != $el.scrollTop) {
          //     const curDate = new Date();
          //     const diffMillis = curDate.getTime() - scroll.control.date.getTime();
          //     if (diffMillis < 1000 && $el.scrollTop == 0) {
          //       // 1초내 다른 스크롤 이벤트 발생시 이전 스크롤 제어값으로 다시 제어
          //       $el.scrollTop = scroll.control.scrollTop;
          //       setScrollTop(scroll.control.scrollTop);
          //       if (debug) {
          //         console.log($vnode.tag + ", 스크롤 다시 제어 : ", scroll.control.scrollTop);
          //       }
          //       return;
          //     }
          //   }
          // }
          scroll.scrollTop = $el.scrollTop;
          scroll.scrollHeight = $el.scrollHeight;
          scroll.clientHeight = $el.clientHeight;
          // console.log("scrollTop : ", $el.scrollTop);
          if (debug) {
            console.log($vnode.tag + ", 스크롤 이벤트 : ", scroll.scrollTop);
          }
        }
      };

      if (!scroll.pageStack) {
        if (debug) {
          console.log("scroll activated. ", this.$vnode.tag);
        }
        const scroll = this.scroll;

        if (debug) {
          console.log("스크롤 이벤트 리스너 등록. ", this.$vnode.tag);
        }
        // document.documentElement.addEventListener("scroll", this.scroll.eventFunc);
        window.addEventListener("scroll", this.scroll.eventFunc);
        if (scroll.first) {
          scroll.first = false;
          this.setScrollTop(0);
        } else {
          this.setScrollPosition(ScrollPosition.RELOAD);
        }
      }
    }
  },
  activated() {
    if (this.scroll.pageStack && this.scroll.eventFunc) {
      if (debug) {
        console.log("scroll activated. ", this.$vnode.tag);
      }
      const scroll = this.scroll;

      if (debug) {
        console.log("스크롤 이벤트 리스너 등록. ", this.$vnode.tag);
      }
      // document.documentElement.addEventListener("scroll", this.scroll.eventFunc);
      window.addEventListener("scroll", this.scroll.eventFunc);
      if (scroll.first) {
        scroll.first = false;
        this.setScrollTop(0);
      } else {
        this.setScrollPosition(ScrollPosition.RELOAD);
      }
    }
  },
  deactivated() {
    if (this.scroll.eventFunc) {
      if (debug) {
        console.log("scroll deactivated. ", this.$vnode.tag);
      }
      if (debug) {
        console.log("스크롤 이벤트 리스너 삭제. ", this.$vnode.tag);
      }
      window.removeEventListener("scroll", this.scroll.eventFunc);
    }
  },
  destroyed() {
    if (this.scroll.eventFunc) {
      if (debug) {
        console.log("scroll destroyed. ", this.$vnode.tag);
      }
      if (debug) {
        console.log("destroyed. 스크롤 이벤트 리스너 삭제. ", this.$vnode.tag);
      }
      window.removeEventListener("scroll", this.scroll.eventFunc);
    }
  },
  methods: {
    setScrollTop(scrollTop: number) {
      const $el = document.documentElement;
      if ($el != null) {
        this.scroll.scrollHeight = $el.scrollHeight;
        this.scroll.clientHeight = $el.clientHeight;
        if (this.scroll.eventFunc) {
          if (this.scroll.control != null && this.scroll.control.scrollTop == -1) {
            const curDate = new Date();
            const controlDate = this.scroll.control.date as Date;
            const diffMillis = curDate.getTime() - controlDate.getTime();
            if (diffMillis < 1000) {
              if (debug) {
                console.log("ignore event setScrollTop : ", scrollTop);
              }
              return;
            }
          }
          if (debug) {
            console.log("setScrollTop : ", scrollTop);
          }
          const scroll = this.scroll;

          if (scroll.interval != null) {
            clearInterval(scroll.interval);
            scroll.interval = null;
            if (debug) {
              console.log("스크롤 제어중. ", this.$vnode.tag);
            }
          }

          const intervalFunc = () => {
            const $el = document.documentElement;
            if ($el != null) {
              $el.scrollTop = scrollTop;
              if (debug) {
                console.log(
                  `스크롤제어!!! scrollTop : ${scrollTop}, curScrollTop : ${$el.scrollTop}`
                );
              }
              if (scrollTop <= $el.scrollTop) {
                scroll.intervalCount = 0;
                clearInterval(scroll.interval);
                scroll.interval = null;

                if (scroll.control.scrollTop !== scrollTop) {
                  scroll.control.date = new Date();
                  scroll.control.scrollTop = scrollTop;
                } else if (scroll.control.date != null) {
                  const curDate = new Date();
                  const diffMillis = curDate.getTime() - scroll.control.date.getTime();
                  if (diffMillis > 1000) {
                    scroll.control.date = new Date();
                    scroll.control.scrollTop = scrollTop;
                  }
                }
              }
            }

            if (scroll.intervalCount >= 50) {
              clearInterval(scroll.interval);
              scroll.intervalCount = 0;
              scroll.interval = null;
              if (debug) {
                console.log(
                  `스크롤 변경 실패, 현재 : ${$el.scrollHeight}, ${$el.clientHeight}, ${$el.scrollTop}, 변경요청 : ${scrollTop}`
                );
              }
            }
            scroll.intervalCount++;
          };

          scroll.interval = setInterval(intervalFunc, 10);
          intervalFunc();
        }
      } else {
        console.log("not found body, ", this.$vnode.tag);
      }
    },
    setScrollPosition(scrollPosition: ScrollPosition) {
      if (this.scroll.eventFunc) {
        if (debug) {
          console.log(`setScrollPosition : ${scrollPosition}`);
        }
        if (scrollPosition == ScrollPosition.TOP) {
          setTimeout(() => {
            this.setScrollTop(-1);
          }, 0);
        } else if (scrollPosition == ScrollPosition.BOTTOM) {
          // console.log("scrollHeight : ", $el.scrollHeight, ", clientHeight : ", $el.clientHeight);
          if (document.documentElement.scrollHeight > document.documentElement.clientHeight) {
            const scrollTop =
              document.documentElement.scrollHeight - document.documentElement.clientHeight;
            if (debug) {
              console.log("bottom scrollTop : ", scrollTop);
            }
            setTimeout(() => {
              this.setScrollTop(scrollTop);
            }, 0);
          } else {
            if (debug) {
              console.log(this.$vnode.tag + ", 스크롤 없음");
            }
          }

          // const el = this.$vnode.elm as any;
          // if (el.scrollHeight > $el.clientHeight) {
          //   const scrollTop = $el.scrollHeight - $el.clientHeight;
          //   if (debug) {
          //     console.log("bottom scrollTop : ", scrollTop);
          //   }
          //   const scrollTop = $el.scrollHeight;
          //   this.setScrollTop(scrollTop);
          // } else {
          //   if (debug) {
          //     console.log(this.$vnode.tag + ", 스크롤 없음");
          //   }
          // }
        } else if (scrollPosition == ScrollPosition.RELOAD) {
          if (document.documentElement.scrollTop != this.scroll.scrollTop) {
            setTimeout(() => {
              this.setScrollTop(this.scroll.scrollTop);
            }, 0);
          } else {
            if (debug) {
              console.log(this.$vnode.tag + ", 스크롤 이전 상태와 동일");
            }
          }
        } else {
          console.log("unknown scrollPosition : ", scrollPosition);
        }
      }
    },
  },
});
