
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";

export default mixins(MixinsCommon).extend({
  name: "UnitPriceListItemComponent",

  props: {
    item: {
      type: Object,
      default: () => {
        return null as any;
      },
    },
    editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  methods: {
    onToggle() {
      this.item.selected = !this.item.selected;
    },
  },
});
