import core from "@/core";
import { TableRequest } from "@/types";
import { HttpRequestCache } from "@/models/http-request-cache";

interface NearPlaceRequest {
  latitude: number;
  longitude: number;
  distance: number;
}

interface PlaceRequest {
  placeId?: number;
  name: string;
  address1?: string;
  address2?: string;
  latitude?: number;
  longitude?: number;
  color?: string;
}

class PlaceService {
  private placeCache = new HttpRequestCache();

  getPlace(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/place/${id}`)
        .then((data: any) => {
          //console.log(data);
          const place = data.place;
          if (place != null) {
            resolve(place);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getList() {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/place`, { ignoreAlertModal: true })
        .then((data: any) => {
          //console.log('result: ', data);
          const item = data.placeList;
          if (item == null) {
            reject({
              message: "not found item",
            });
          } else {
            resolve(item);
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  // getPlaceList() {
  //   return new Promise((resolve: any, reject) => {
  //     const cache = this.placeCache;
  //     if (cache.time != null) {
  //       const curTime = new Date();
  //       const elapsedTimeMillis = curTime.getTime() - cache.time.getTime();
  //       if (elapsedTimeMillis < 1000) {
  //         // 1초 미만 호출 시 캐싱한 데이터 반환
  //         cache.time = curTime;
  //         resolve(cache.item);
  //         console.log("cache data");
  //         return;
  //       }
  //     }
  //     core.http
  //       .getJson(`/api/work/place`, { ignoreAlertModal: true })
  //       .then((data: any) => {
  //         //console.log('result: ', data);
  //         const item = data.placeList;
  //         if (item == null) {
  //           reject({
  //             message: "not found item",
  //           });
  //         } else {
  //           cache.item = item;
  //           cache.time = new Date();
  //           resolve(item);
  //         }
  //       })
  //       .catch((reason: any) => {
  //         //console.log('error: ', reason);
  //         reject(reason);
  //       });
  //   });
  // }

  getTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/place/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/place/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getNearPlaceList(params: NearPlaceRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/place/near`, null, params)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.placeList);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  create(params: PlaceRequest) {
    const strParams = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/work/place`, strParams)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.place);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`/api/work/place/${id}`, strJson)
        .then((data: any) => {
          resolve(data.place);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getPlaceUserList(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/place/${id}/user`)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.placeUserList);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
  createPlaceUser(id: number, userId: number) {
    const strParams = JSON.stringify({ userId: userId });
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/work/place/${id}/user`, strParams)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.placeUser);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  deletePlaceUser(id: number, userId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/place/${id}/user/${userId}`)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.placeUser);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new PlaceService();
