
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";

export default mixins(MixinsPage).extend({
  name: "MapLink",
  data: () => ({
    address: "",
    latitude: null as any,
    longitude: null as any,
  }),
  mounted() {
    const query = this.$route.query as any;
    if (this.isNotBlank(query.address)) {
      this.address = query.address;
    }
    if (this.isNotBlank(query.latitude) && this.isNotBlank(query.longitude)) {
      this.latitude = query.latitude;
      this.longitude = query.longitude;
    }

    location.href = core.mobile.getGeoLink({
      latitude: this.latitude,
      longitude: this.longitude,
      address: this.address,
    });
    this.goBack();
  },
});
