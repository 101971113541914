
import core from "@/core";
import CommentService from "@/services/comment/comment.service";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import { ScrollPosition } from "@/mixins/single/scroll";
import { SubscribeType } from "@/core/core-web-socket";
import { CommentType, EntityType, UpdateEventType } from "@/types";
import UpdateEvent from "@/models";
import { UserModel } from "@/models/user/user.model";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import CommentListItemComponent from "@/components/comment/ListItemComponent.vue";
import { CommentDisplayModel, CommentListItemProperties } from "@/models/comment/comment.model";
import { FileDetailModel } from "@/models/file/file-detail.model";
import Constant from "@/store/constant";

export enum Mode {
  DEFAULT = "default",
  SELECT = "select",
}

export interface CommentProps {
  visible: boolean;
  mode: Mode;
  type: EntityType;
  id: number;
  idList: number[];
  systemMessageList: string[];
  reloadFlag: boolean;
}

export default mixins(MixinsPage).extend({
  name: "CommentComponent",
  components: { CommentListItemComponent },
  props: {
    entity: {
      type: Object as () => CommentProps,
      default: () => {
        return {
          visible: false,
          mode: Mode.DEFAULT,
          type: EntityType.SCHEDULE,
          id: 0,
          idList: [],
          systemMessageList: [],
          reloadFlag: false,
        };
      },
    },
  },
  data: () => ({
    scrollPercentage: 0,
    loading: false,
    processing: false,
    comment: "",
    commentList: [] as CommentDisplayModel[],
    requestDate: null as any,
    updated: false,
    isDataReady: false,
    modal: {
      edit: {
        visible: false,
        params: {
          item: {},
        },
        updateEvent: null as any as UpdateEvent | null,
      },
    },
    component: {
      selectComment: false,
      updateEvent: null as UpdateEvent | null,
    } as CommentListItemProperties,

    scrollControl: {
      top: 0,
      position: null as ScrollPosition | null,
      updatedPosition: null as ScrollPosition | null,
    },
    paddingBottom: 60,
    user: {} as UserModel,
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
  }),
  computed: {
    Mode() {
      return Mode;
    },
  },
  mounted() {
    // viewer 뒤로가기시 닫힘 처리
    const routerBeforeTask = this.$store.state.app.routerBeforeTask;
    const routerBeforeTaskItem = new RouterBeforeTaskItem(this.$vnode.tag as string, () => {
      const viewerContainer = document.querySelector(".viewer-container.viewer-in");
      if (viewerContainer != null) {
        const button = viewerContainer.querySelector(".viewer-button") as any;
        if (button != null) {
          button.click();
        }
        return false;
      }
      return true;
    });
    routerBeforeTask.pushPage(routerBeforeTaskItem);

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.user = await this.$store.getters["auth/user"]();
      const entity = this.entity as CommentProps;
      if (entity.id > 0) {
        let exists = false;
        entity.idList.some((id) => {
          if (id === entity.id) {
            exists = true;
            return true;
          }
        });
        if (!exists) {
          entity.idList.push(entity.id);
        }
      }
    });
  },
  watch: {
    "entity.visible"(visible) {
      this.activated();
    },
    async "entity.reloadFlag"(reloadFlag) {
      // console.log("reloadFlag : ", reloadFlag);
      if (reloadFlag) {
        console.log(
          `entityType : ${(this.entity as any).type}, entityId : ${(this.entity as any).id}`
        );
        (this.entity as any).reloadFlag = false;
        this.requestDate = null;
        this.unsubscribe();
        await this.getCommentList();
        this.subscribe(this);
      }
    },
    "entity.mode"(mode) {
      this.component.selectComment = mode == Mode.SELECT;
      if (this.component.selectComment) {
        this.commentList.forEach((comment) => {
          comment.selected = false;
        });
      }
    },
    comment(val, prev) {
      const preLength = (prev.match(/\n/g) || []).length;
      const length = (val.match(/\n/g) || []).length;
      if (preLength != length) {
        const elNodeList = (this.$refs.commentInputWrap as any).getElementsByTagName(
          "textarea"
        ) as any;
        const el = elNodeList[0];

        if (length == 0) {
          el.style.height = "32px";
          this.paddingBottom = 60;
        } else if (length > 0) {
          el.style.height = 32 + 28 * length + "px";
          this.paddingBottom = 60 + 32 + 28 * length;
        }
      }
    },
    "component.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.component);
      if (event) {
        if (event.result == UpdateEventType.EVENT) {
          const item = event.item;
          if (item.type === "delete") {
            this.deleteComment(item.item);
          } else if (item.type === "viewImage") {
            const comment = item.item as CommentDisplayModel;
            if (comment.type === CommentType.IMAGE && comment.fileDetail) {
              this.onImageView(comment.fileDetail.downloadUri);
            } else if (
              comment.type === CommentType.LINK &&
              comment.link &&
              comment.link.fileDetail
            ) {
              this.onImageView(comment.link.fileDetail.downloadUri);
            }
          } else {
            console.log("unknown item : ", item);
          }
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  activated() {
    this.activated();
  },
  deactivated() {
    this.unsubscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    activated() {
      const entity = this.entity as CommentProps;
      if (entity.visible) {
        console.log("!!!!!!!!!! activate ", this.entity?.type);
        this.$nextTick(async () => {
          console.log("scroll");
          this.scrollPercentage = 100;

          if ((this.entity as any).id > 0) {
            await this.getCommentList();
            this.subscribe(this);
          }
        });
      } else {
        console.log("is not visible, ", entity.type);
      }
    },
    getFilename(uri: string) {
      if (uri != null) {
        const pos = uri.lastIndexOf("/");
        if (pos > -1) {
          return uri.substring(pos + 1, uri.length);
        }
      }
      return uri;
    },
    keyEvent(e) {
      if (!this.app.isMobile) {
        if (e) {
          const code = e.code;
          if ((code === "Enter" || code === "NumpadEnter") && e.shiftKey === false) {
            this.submit();
            this.moveFocus("comment");
          }
        }
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    updateImageSize(item: CommentDisplayModel) {
      let fileDetail: FileDetailModel | null | undefined = null;

      if (item.type === CommentType.IMAGE) {
        fileDetail = item.fileDetail;
      } else if (item.type === CommentType.LINK) {
        if (item.link) {
          fileDetail = item.link.fileDetail;
        }
      }

      const bodySize = {
        width: this.app.size.bodyWidth,
        height: this.app.size.height,
      };

      if (this.$refs.componentTemplate != null) {
        const $el = (this.$refs.componentTemplate as any).$el;
        if ($el) {
          bodySize.width = $el.clientWidth;
          bodySize.height = $el.clientHeight;
        }
      }

      if (fileDetail) {
        if (fileDetail.imageWidth && fileDetail.imageHeight) {
          const imageSize = {
            width: 0,
            height: 0,
          };
          if (fileDetail.imageWidth < fileDetail.imageHeight) {
            // 높이가 더 클경우
            let maxHeight = bodySize.height - 200;
            if (fileDetail.imageHeight > maxHeight) {
              imageSize.height = maxHeight;
            } else {
              imageSize.height = fileDetail.imageHeight;
            }
            const cal = imageSize.height / fileDetail.imageHeight;
            imageSize.width = fileDetail.imageWidth * cal;
          } else {
            imageSize.width = fileDetail.imageWidth;
          }

          let maxWidth = bodySize.width - 130;
          if (imageSize.width > maxWidth) {
            imageSize.width = maxWidth;
          }
          const cal = imageSize.width / fileDetail.imageWidth;
          imageSize.height = fileDetail.imageHeight * cal;

          item.imageSize = imageSize;
        } else {
          item.imageSize = bodySize;
        }
      } else {
        item.imageSize = bodySize;
      }
    },
    subscribe(vm: any) {
      if (!this.isDataReady) {
        console.log("is not data ready!!!!!!!!!!!!!!!");
        return;
      }
      const entity = this.entity as CommentProps;
      entity.idList.forEach((entityId) => {
        console.log("subscribe : ", entity.type, entityId);

        core.webSocket.subscribe(
          SubscribeType.COMMENT,
          {
            entityType: this.entity.type,
            entityId: entityId,
            callback: (event) => {
              const crud = event.crud;
              const item = event.item;

              console.log("event : ", JSON.stringify(event));
              if (crud === "C") {
                let exists = false;
                console.log("vm : ", vm);
                console.log("vm.commentList : ", vm.commentList);
                vm.commentList.some((comment, index) => {
                  if (comment.id === item.id) {
                    //core.utils.copy(item, comment);
                    vm.updateCommentList(vm.commentList);
                    exists = true;
                    return true;
                  }
                });
                if (!exists) {
                  item.html = "";
                  if (item.type === CommentType.IMAGE) {
                    this.updateImageSize(item);
                  } else if (
                    item.type === CommentType.LINK &&
                    item.link &&
                    item.link.type === CommentType.IMAGE
                  ) {
                    this.updateImageSize(item);
                  }
                  vm.commentList.push(item);
                  vm.updateCommentList(vm.commentList);
                  // console.log("scrollPercentage : ", vm.scrollPercentage);
                  if (vm.scrollPercentage > 80) {
                    setTimeout(() => {
                      vm.scrollControl.position = ScrollPosition.BOTTOM;
                    }, 0);
                  }
                }
              } else if (crud === "U") {
                vm.commentList.some((comment, index) => {
                  if (comment.id === item.id) {
                    vm.updateCommentList(vm.commentList);
                    return true;
                  }
                });
              } else if (crud === "D") {
                vm.commentList.some((comment, index) => {
                  if (comment.id === item.id) {
                    vm.commentList.splice(index, 1);
                    return true;
                  }
                });
                vm.updateCommentList(vm.commentList);
              } else {
                console.log("invalid crud type ", crud);
              }
            },
          },
          {
            connected: () => {
              //console.log("connected");
              if (!vm.loading) {
                vm.scrollPercentage = 100;
                vm.getCommentList();
              }
            },
            disconnected: () => {
              //console.log("disconnected");
            },
          }
        );
      });
    },
    unsubscribe() {
      const entity = this.entity as CommentProps;

      entity.idList.forEach((entityId) => {
        core.webSocket.unsubscribe(SubscribeType.COMMENT, {
          entityType: entity.type,
          entityId: entityId,
        });
      });
    },
    onImageView(uri: string) {
      const entity = this.entity as CommentProps;
      if (entity.mode !== Mode.SELECT) {
        let selected = null as any;
        this.$viewer.images.some((el) => {
          if (el.src.indexOf(uri) > -1) {
            selected = el;
          }
        });
        if (selected != null) {
          selected.click();
        } else {
          this.$viewer.show();
        }
      }
    },
    async deleteComment(item: any) {
      //console.log("deleteComment", item);
      const result = await core.alert.show({
        title: "확인",
        body: `선택하신 ${item.type === "I" ? "사진" : "댓글"}을 삭제하시겠습니까?`,
        showCancelButton: true,
        cancelButtonText: "취소",
        confirmButtonText: "삭제",
      });
      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const apiResult = await CommentService.delete(item.id);
          //console.log("apiResult : ", apiResult);
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    cancelSelectedComment() {
      const entity = this.entity as CommentProps;
      if (entity.mode == Mode.SELECT) {
        entity.mode = Mode.DEFAULT;
      } else {
        console.log("invalid mode : ", entity.mode);
      }
    },
    // 코멘트 선택 완료 처리
    completeSelectedComment() {
      const entity = this.entity as CommentProps;
      if (entity.mode == Mode.SELECT) {
        const selectedCommentIdList = [] as any;
        this.commentList.forEach((comment) => {
          if (comment.selected) {
            selectedCommentIdList.push(comment.id);
          }
          comment.selected = false;
        });
        this.$emit("update:selectedIdList", selectedCommentIdList);

        entity.mode = Mode.DEFAULT;
      } else {
        console.log("invalid mode : ", entity.mode);
      }
    },
    async submit(fileDetailId?: any) {
      this.processing = true;

      const params = {
        entityType: this.entity.type,
        entityId: this.entity.id,
        type: fileDetailId == null ? CommentType.TEXT : CommentType.IMAGE,
      } as any;

      if (params.type === CommentType.TEXT) {
        params.content = this.comment;

        if (this.isBlank(this.comment)) {
          this.processing = false;
          return;
        }
      } else if (params.type === CommentType.IMAGE) {
        params.fileDetailId = fileDetailId;
      } else {
        console.log("invalid comment type : ", params.type);
        return;
      }

      try {
        const result = await CommentService.create(params);
        //console.log("result : ", result);
      } catch (e) {
        console.log(e);
      }

      this.comment = "";
      this.processing = false;
    },
    showImageSelectView() {
      if (this.$refs.imageFile) {
        (this.$refs.imageFile as any).click();
      }
    },
    async onChangeImageFile() {
      //console.log("onChangeImageFile");
      this.processing = true;

      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        for (let i = 0; i < length; i++) {
          const file = files[i];
          try {
            const imageFile = (await core.utils.image.getImageBlob(file, 1280)) as any;

            const formData = new FormData();
            formData.append("imageFile", imageFile.blob, imageFile.filename);

            const fileDetailId = await CommentService.createImage(formData);
            await this.submit(fileDetailId);
          } catch (e: any) {
            console.log(e);
            core.http.sendError(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
          }
        }
        (this.$refs.imageFile as any).value = "";
      }
      this.processing = false;
    },
    async updateCommentList(commentList) {
      // 동일 시간 같은 사용자 글 확인
      let preComment: CommentDisplayModel | null = null;
      const user = await this.$store.getters["auth/user"]();

      commentList.forEach((comment: CommentDisplayModel) => {
        if (comment.selected == null) {
          comment.selected = false;
        }
        comment.hasParent = false;
        comment.hasChild = false;

        if (
          preComment != null &&
          !(preComment.type === CommentType.SYSTEM || preComment.type === CommentType.ADMIN)
        ) {
          const strCreatedAt = core.date.instance(comment.createdAt).format("YYYYMMDDHHmm");
          const strPreCreatedAt = core.date.instance(preComment.createdAt).format("YYYYMMDDHHmm");
          if (
            comment.user.username == preComment.user.username &&
            strCreatedAt === strPreCreatedAt
          ) {
            comment.hasParent = true;
            preComment.hasChild = true;
          }
        }

        comment.showActionBox =
          !(comment.type === CommentType.SYSTEM || comment.type === CommentType.ADMIN) &&
          user.id == comment.user.id;

        if (
          comment.type === CommentType.TEXT ||
          comment.type === CommentType.SYSTEM ||
          comment.type === CommentType.ADMIN
        ) {
          comment.html = core.utils.format.textToHtml(comment.content);
        } else if (comment.type === CommentType.LINK && comment.link) {
          comment.html = core.utils.format.textToHtml(comment.link.content);
        }
        //console.log("hasParent : ", comment.hasParent);
        preComment = comment;

        // comment.chatWrapClass = this.getChatWrapClass(comment);

        if (comment.type === CommentType.IMAGE) {
          this.updateImageSize(comment);
        } else if (
          comment.type === CommentType.LINK &&
          comment.link &&
          comment.link.type === CommentType.IMAGE
        ) {
          this.updateImageSize(comment);
        }

        comment.isSelfComment = comment.user.id === user.id;
      });
      // this.commentList = commentList;
    },
    async getCommentList() {
      // core.loader.show();
      this.loading = true;
      try {
        const entity = this.entity as CommentProps;
        if (this.requestDate != null) {
          const curDate = new Date();
          const elapsedTimeMillis = curDate.getTime() - this.requestDate.getTime();
          if (elapsedTimeMillis < 1000) {
            return;
          }
        }
        this.requestDate = new Date();
        // console.log("get comment list");

        this.isDataReady = false;

        let commentList: CommentDisplayModel[] | null = null;
        if (entity.idList.length > 1) {
          commentList = (await CommentService.getList(
            entity.type,
            entity.idList
          )) as CommentDisplayModel[];
        } else {
          commentList = (await CommentService.getList(
            entity.type,
            entity.id
          )) as CommentDisplayModel[];
        }

        await this.updateCommentList(commentList);

        this.commentList = commentList as CommentDisplayModel[];
        setTimeout(() => {
          this.scrollControl.position = ScrollPosition.BOTTOM;
        }, 0);
        setTimeout(() => {
          this.scrollControl.position = ScrollPosition.BOTTOM;
        }, 100);
        this.isDataReady = true;
      } catch (e: any) {
        console.log(e);
        if (e.status === 404) {
          core.alert.hide();
          this.goBack(UpdateEventType.RELOAD);
        }
      } finally {
        this.processing = false;
        // core.loader.hide();
        this.loading = false;
      }
    },
    dateTimeFormat(strDate: string) {
      return core.date.format.comment(strDate);
    },
  },
});
