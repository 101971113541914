export default [
  { color: "teal", hex: "#4CAF50", text: "그린" },
  { color: "cyan", hex: "#00BCD4", text: "사이언" },
  { color: "light-blue", hex: "#03A9F4", text: "스카이블루" },
  { color: "indigo", hex: "#3F51B5", text: "인디고" },
  { color: "brown", hex: "#795548", text: "브라운" },
  { color: "black", hex: "#000000", text: "블랙" },
  { color: "red", hex: "#F44336", text: "레드" },
  { color: "pink", hex: "#E91E63", text: "핑크" },
  { color: "purple", hex: "#9C27B0", text: "퍼플" },
  { color: "deep-purple", hex: "#673AB7", text: "딥 퍼플" },
  { color: "orange", hex: "#FF9800", text: "오렌지" },
  { color: "deep-orange", hex: "#FF5722", text: "딥 오렌지" },
  { color: "blue-grey", hex: "#607D8B", text: "블루 그레이" },
];
