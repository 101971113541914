
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import CommentComponent, { CommentProps, Mode } from "@/components/comment/CommentComponent.vue";
import core from "@/core";
import EstimateService from "@/services/work/estimate.service";
import ScheduleUtils from "@/models/schedule";
import { EntityType } from "@/types";
import UpdateEvent from "@/models";

export default mixins(MixinsPage).extend({
  name: "EstimateCommentView",

  components: {
    CommentComponent,
  },

  data: () => ({
    tab: 0,
    toolbarHeight: 0,
    title: "",
    estimate: null as any,
    scheduleList: null as any,
    id: 0,
    component: {
      estimateEntity: {
        visible: false,
        mode: Mode.DEFAULT,
        type: EntityType.ESTIMATE,
        id: 0,
        idList: [],
        systemMessageList: [],
        reloadFlag: false,
      },
      scheduleEntityList: [] as CommentProps[],
    },
    updateEvent: null as any as UpdateEvent | null,
  }),

  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params as any;
      this.id = params.id as number;
      this.component.estimateEntity.id = this.id;

      await this.getEstimate();
      this.changedTab();
    });
  },
  watch: {
    tab(tab) {
      this.changedTab();
    },
  },
  methods: {
    tabTitle(schedule: any) {
      const strDate = ScheduleUtils.getScheduleDate(schedule);

      return `${schedule.category.name}(${strDate})`;
    },
    changedTab() {
      const tab = this.tab;
      const list = [] as CommentProps[];
      list.push(this.component.estimateEntity);
      this.component.estimateEntity.visible = false;
      this.component.scheduleEntityList.forEach((entity) => {
        list.push(entity);
        entity.visible = false;
      });

      const entity = list[tab];
      if (entity != null) {
        entity.visible = true;
      } else {
        console.log("not found entity tab ", tab);
      }
    },
    async getEstimate() {
      core.loader.show();
      try {
        this.estimate = (await EstimateService.get(this.id)) as any;
        // console.log("workStatus : ", this.estimate.workStatus);

        this.updateTitle();

        this.scheduleList = (await EstimateService.getScheduleList(this.id)) as any;
        this.scheduleList.forEach((schedule: any) => {
          //console.log(schedule);
          this.component.scheduleEntityList.push({
            visible: false,
            mode: Mode.DEFAULT,
            type: EntityType.SCHEDULE,
            id: schedule.id,
            idList: [],
            systemMessageList: [],
            reloadFlag: false,
          } as any);
        });
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
      core.loader.hide();
    },
    updateTitle() {
      if (this.estimate != null) {
        this.title = `${this.estimate.place.name} ${this.estimate.dong}동 ${this.estimate.ho}호`;
      } else {
        this.title = "견적 공통댓글";
      }
    },
  },
});
