
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import { UpdateEventType } from "@/types";
import CategoryService from "@/services/work/category.service";
import UpdateEvent from "@/models";
import CategoryAddEditModal from "@/modals/category/CategoryAddEditModal.vue";

export default mixins(MixinsPage, MixinsTable, MixinsScroll).extend({
  name: "MgmtCategory",
  components: { CategoryAddEditModal },
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    modal: {
      addEdit: {
        visible: false,
        params: {
          type: "add",
          item: {},
        },
        // 업데이트 이벤트 처리용 변수
        updateEvent: null as any as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    this.initTable({
      service: CategoryService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "modal.addEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.addEdit);
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (this.addTableItem("id", event.item)) {
            this.setScrollPosition(ScrollPosition.TOP);
          }
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  activated() {
    this.$nextTick(() => {
      const event = this.getPageUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (event.path.indexOf("search") > -1) {
            this.updateTableItem("id", event.item);
          } else {
            this.addTableItem("id", event.item);
          }
          this.setScrollPosition(ScrollPosition.TOP);
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown event : ", event);
        }
      }
    });
  },
  methods: {
    showEditModal(item: any) {
      //console.log("showEditModal : ", item);
      const modal = this.modal.addEdit;
      modal.params.type = "edit";
      modal.params.item = item;
      modal.visible = true;
    },
    showAddModal() {
      //console.log("showAddModal");
      const modal = this.modal.addEdit;
      modal.params.type = "add";
      modal.visible = true;
    },
  },
});
