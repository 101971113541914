import axios, { AxiosError } from "axios";
import core from "@/core/index";

interface Work {
  method: string;
  url: string;
  config: any;
  params: any;
  resolve: any;
  reject: any;
}

export default class CoreHttp {
  headers: any = null;
  store: any;
  router: any;
  alert: any;
  mobile: any;

  // 5분
  SESSION_TIMEOUT = 300000;
  // SESSION_TIMEOUT = 1000;

  checkSession: boolean;

  authWorkItem = {
    checkSession: true,
    id: null as any,
    list: [] as Work[],
  };
  noAuthWorkItem = {
    checkSession: false,
    id: null as any,
    list: [] as Work[],
  };

  constructor(defaultHeaders: any, checkSession: boolean, store, router, alert, mobile) {
    this.headers = {
      default: {},
      form: {
        "content-type": "application/x-www-form-urlencoded",
      },
      json: {
        "content-type": "application/json",
      },
    };
    this.store = store;
    this.router = router;
    this.alert = alert;
    this.mobile = mobile;
    this.checkSession = checkSession;

    if (defaultHeaders != null) {
      for (const key of Object.keys(this.headers)) {
        const value = this.headers[key];
        defaultHeaders.forEach((data: any) => {
          value[data.key] = data.value;
        });
      }
    }
  }

  objToUrlParams(obj: any, prefix?: string): string {
    if (typeof obj === "object") {
      const strList = [] as string[];
      for (const p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
          const k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
          const value =
            v !== null && typeof v === "object"
              ? this.objToUrlParams(v, k)
              : encodeURIComponent(k) + "=" + encodeURIComponent(String(v));
          strList.push(value);
        }
      }
      return strList.join("&");
    }
    return "";
  }

  getJson(url: string, config?: any, params?: any): Promise<any> {
    if (config != null) {
      config.headers = this.headers.json;
    } else {
      config = { headers: this.headers.json };
    }
    return this.get(url, config, params);
  }

  get(url: string, config?: any, params?: any): Promise<any> {
    if (typeof params === "object") {
      url += "?" + this.objToUrlParams(params);
    }

    if (config == null || config.headers == null) {
      config = {
        headers: this.headers.default,
      };
    }

    return new Promise((resolve: any, reject) => {
      let item = this.authWorkItem;
      if (url === "/api/user/me" || url === "/api/v1/user/me") {
        item = this.noAuthWorkItem;
      }
      this.pushWork(item, {
        method: "get",
        url: url,
        config: config,
        params: params,
        resolve: resolve,
        reject: reject,
      });
    });
  }

  putJson(url: string, params: string): Promise<any> {
    return this.put(url, params, { headers: this.headers.json });
  }

  put(url: string, params: any, config?: any): Promise<any> {
    return new Promise((resolve: any, reject) => {
      this.pushWork(this.authWorkItem, {
        method: "put",
        url: url,
        config: config,
        params: params,
        resolve: resolve,
        reject: reject,
      });
    });
  }

  patchJson(url: string, params: string): Promise<any> {
    return this.patch(url, params, { headers: this.headers.json });
  }

  patch(url: string, params: any, config?: any): Promise<any> {
    return new Promise((resolve: any, reject) => {
      this.pushWork(this.authWorkItem, {
        method: "patch",
        url: url,
        config: config,
        params: params,
        resolve: resolve,
        reject: reject,
      } as Work);
    });
  }

  postJson(url: string, params: string): Promise<any> {
    return this.post(url, params, { headers: this.headers.json });
  }

  post(url: string, params: any, config?: any): Promise<any> {
    return new Promise((resolve: any, reject) => {
      this.pushWork(this.authWorkItem, {
        method: "post",
        url: url,
        config: config,
        params: params,
        resolve: resolve,
        reject: reject,
      } as Work);
    });
  }

  delete(url: string, config?: any): Promise<any> {
    if (config == null) config = { headers: this.headers.default };
    else if (config.headers == null) config.headers = this.headers.default;

    return new Promise((resolve: any, reject) => {
      this.pushWork(this.authWorkItem, {
        method: "delete",
        url: url,
        config: config,
        resolve: resolve,
        reject: reject,
      } as Work);
    });
  }

  private async _work(checkSession, list) {
    //console.log("_work : ", JSON.stringify(list));
    const state = this.store.state as any;

    const item = list.shift() as Work;

    if (checkSession && state.auth.sessionCheckTime != null) {
      const curTime = new Date();
      if (curTime.getTime() - state.auth.sessionCheckTime.getTime() > this.SESSION_TIMEOUT) {
        // 세션확인시간 5분 이상 경과됬을 경우 회원정보 갱신
        const user = await this.store.getters["auth/user"](true);
        if (user == null) {
          console.log("login session 만료");
        }
      }
    }

    if (item.method === "get") {
      // try {
      //   const resp = await axios.get(item.url, item.config);
      //   state.auth.sessionCheckTime = new Date();
      //   item.resolve(resp.data);
      // } catch (reason) {
      //   this.axiosError(reason, item.reject, item.config);
      // }
      axios
        .get(item.url, item.config)
        .then((resp) => {
          if (checkSession) {
            state.auth.sessionCheckTime = new Date();
          }
          item.resolve(resp.data);
        })
        .catch((reason) => {
          this.axiosError(reason, item.reject, item.config);
        });
    } else if (item.method === "put") {
      axios
        .put(item.url, item.params, item.config)
        .then((resp) => {
          if (checkSession) {
            state.auth.sessionCheckTime = new Date();
          }
          item.resolve(resp.data);
        })
        .catch((reason) => {
          this.axiosError(reason, item.reject, item.config);
        });
    } else if (item.method === "patch") {
      axios
        .patch(item.url, item.params, item.config)
        .then((resp) => {
          if (checkSession) {
            state.auth.sessionCheckTime = new Date();
          }
          item.resolve(resp.data);
        })
        .catch((reason) => {
          this.axiosError(reason, item.reject, item.config);
        });
    } else if (item.method === "post") {
      axios
        .post(item.url, item.params, item.config)
        .then((resp) => {
          if (checkSession) {
            state.auth.sessionCheckTime = new Date();
          }
          item.resolve(resp.data);
        })
        .catch((reason) => {
          this.axiosError(reason, item.reject, item.config);
        });
    } else if (item.method === "delete") {
      axios
        .delete(item.url, item.config)
        .then((resp) => {
          if (checkSession) {
            state.auth.sessionCheckTime = new Date();
          }
          item.resolve(resp.data);
        })
        .catch((reason) => {
          this.axiosError(reason, item.reject, item.config);
        });
    }

    //console.log("list.length : ", list.length);
    if (list.length > 0) {
      await this._work(checkSession, list);
    }
  }

  pushWork(item: any, params: Work) {
    // 서버단 RememberMe 처리 오류 때문에 작업 목록 따로 처리하면서 세션 확인함
    if (!this.checkSession) {
      item = this.noAuthWorkItem;
    }
    item.list.push(params);
    if (item.id == null) {
      item.id = setTimeout(async () => {
        try {
          await this._work(item.checkSession, item.list);
        } catch (e) {
          console.log(e);
        }
        item.id = null;
      }, 1);
    }
  }

  async axiosError(error: AxiosError, reject: any, config?: any) {
    let ignoreAlertModal = false;
    if (config != null && config.ignoreAlertModal) {
      ignoreAlertModal = config.ignoreAlertModal;
    }
    // console.log("error : ", error);
    if (error.response != null) {
      // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
      //console.log(error.response.data);
      // console.log(error.response);
      if (!ignoreAlertModal && Number(error.response.status) >= 500) {
        this.alert.show({
          title: "알림",
          body: error.response.statusText,
          confirmButtonText: "확인",
          allowBackCloseEvent: false,
        });
        reject({
          code: error.response.status,
          message: error.response.statusText,
        });
      } else {
        const errorData = error.response.data;
        // if (!error.config.ignoreAlert && errorData.errorFieldName == null) {

        if (errorData.errorFieldName == null) {
          let message = errorData.message;
          if (message == null || message.length === 0) {
            if (Number(error.response.status) !== 401) {
              message = errorData.error;
            }
            // if (Number(error.response.status) == 401) {
            //   const requestUrl = error.response.config.url;
            //   if (requestUrl != undefined && requestUrl.indexOf("/api/v1/user/me") > -1) {
            //     isLoginCheck = true;
            //     const webSocketConnected = await this.store.getters["app/webSocketConnected"];
            //     if (webSocketConnected) {
            //       await this.store.dispatch("app/webSocketDisconnect");
            //       return;
            //     } else {
            //       message = "로그인 세션이 만료되었습니다";
            //     }
            //   }
            // } else {
            //   message = errorData.error;
            // }
          }
          if (!ignoreAlertModal && core.utils.validate.isNotBlank(message)) {
            this.alert.show({
              title: "알림",
              body: message,
              confirmButtonText: "확인",
              allowBackCloseEvent: false,
            });
          }
        }

        if (Number(error.response.status) == 401) {
          const requestUrl = error.response.config.url;
          if (requestUrl != undefined && requestUrl.indexOf("/api/v1/user/me") > -1) {
            const state: any = this.store.state;
            const user = state.auth.user;
            if (user != null) {
              this.store.commit("auth/logout");
              this.router.push("/login");
            }
            const webSocketConnected = await this.store.getters["app/webSocketConnected"];
            if (webSocketConnected) {
              await this.store.dispatch("app/webSocketDisconnect");
            }
          }
        }
        reject(errorData);
      }
    } else if (error.request) {
      // 요청이 이루어 졌으나 응답을 받지 못했습니다.
      // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
      // Node.js의 http.ClientRequest 인스턴스입니다.
      console.log("Error : ", error.message);

      if (!ignoreAlertModal) {
        this.alert.show({
          title: "알림",
          body: error.message,
          confirmButtonText: "확인",
          allowBackCloseEvent: false,
        });
      }
      reject({
        code: 0,
        message: error.message,
      });
    } else {
      // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
      console.log("Error : ", error.message);
      if (!ignoreAlertModal) {
        this.alert.show({
          title: "알림",
          body: error.message,
          confirmButtonText: "확인",
          allowBackCloseEvent: false,
        });
      }
      reject({
        code: -1,
        message: error.message,
      });
    }
  }

  downloadFile(url: string) {
    const isApp = this.mobile.isApp();
    if (isApp) {
      let urlLink = url;
      if (!(url.indexOf("http") > -1)) {
        urlLink = window.location.origin + url;
      }
      this.mobile.call({
        cmd: "download",
        value: urlLink,
      });
    } else {
      return axios({
        url: url,
        responseType: "arraybuffer",
      })
        .then((res) => {
          try {
            const blob = new Blob([res.data], { type: res.headers["content-type"] });
            let fileName = this.getFileName(res);
            fileName = decodeURI(fileName); // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)

            if ((window.navigator as any).msSaveOrOpenBlob) {
              // IE 10+
              (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
            } else {
              // not IE
              const link = document.createElement("a");
              link.href = (window.URL || window.webkitURL || window || {}).createObjectURL(blob);
              link.target = "_self";
              if (fileName) link.download = fileName;
              link.click();
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((res) => {
          // status 200이 아닌경우에도 콜백호출 (프로젝트에 맞게 수정필요)
          console.log(res);
        });
    }
  }

  getFileName(res: any): string {
    const contentDisposition = res.headers["content-disposition"];
    if (contentDisposition != null && contentDisposition.length > 0) {
      const fileName = contentDisposition
        .split(";")
        .filter((ele: string) => {
          return ele.indexOf("filename") > -1;
        })
        .map((ele: string) => {
          return ele.replace(/"/g, "").split("=")[1];
        });
      return fileName[0] ? fileName[0] : String(new Date().getTime());
    } else {
      const url = res.config.url;

      let filename = "";
      {
        const pos = url.lastIndexOf("/");
        if (pos > -1) {
          filename = url.substr(pos + 1, url.length);
        } else {
          filename = pos;
        }
      }
      return filename;
    }
  }

  sendError(obj) {
    const params = JSON.stringify(obj);
    const config = { headers: this.headers.json };
    axios
      .post("/api/log", params, config)
      .then((resp) => {
        console.log("resp : ", resp);
      })
      .catch((reason) => {
        console.log("reason : ", reason);
      });
  }
}
