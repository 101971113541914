
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import ImageEditorModal from "@/modals/core/ImageEditorModal.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPage).extend({
  name: "Test",
  components: {
    ImageEditorModal,
  },
  data: () => ({
    imageIndex: 0,
    defaultImage: null as any,
    imageList: [] as any,
    changedImage: false,
    modal: {
      imageEditor: {
        visible: false,
        params: {
          filename: "",
          dataUri: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      // const imageFile = await core.utils.image.convertURLtoFile("/img/exposition/main_2.gif");
      // const reader = new FileReader();
      // const vm = this;
      // reader.onload = function (e: any) {
      //   const img = new Image();
      //   img.onload = function () {
      //     // console.log("img : ", img);
      //     vm.defaultImage = img;
      //   };
      //   // console.log("e.target.result : ", e.target.result);
      //   img.src = e.target.result;
      // };
      // reader.readAsDataURL(imageFile);
      // const TABS = FilerobotImageEditor;
      // const TOOLS = anyWindow.FilerobotImageEditor.TOOLS;
    });
  },
  watch: {
    "modal.imageEditor.updateEvent"() {
      const modal = this.modal.imageEditor;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const result = event.item;
          if (result != null) {
            console.log("result : ", result);
            // this.image.item = result;
            // this.image.src = result.dataUri;
          }
        }
      }
    },
  },
  methods: {
    async showImageCropperModal(file) {
      core.loader.show("이미지 변환중...");
      const item = (await core.utils.image.getImageBlob(file)) as any;
      this.modal.imageEditor.params.filename = item.filename;
      this.modal.imageEditor.params.dataUri = item.dataUri;
      this.modal.imageEditor.visible = true;
      core.loader.hide();
    },
    startDraw() {
      // const fabricCanvas = this.fabricCanvas as Canvas;
      // fabricCanvas.freeDrawingBrush = new fabric.PencilBrush(fabricCanvas);
      // fabricCanvas.freeDrawingBrush.width = 10;
      // fabricCanvas.isDrawingMode = true;
    },
    showImageSelectView() {
      (this.$refs.imageFile as any).click();
    },
    async onChangeImageFile() {
      //console.log("onChangeImageFile");
      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        core.loader.show("이미지 변환중...");
        const imageFileList = [] as any;
        for (let i = 0; i < length; i++) {
          try {
            const file = files[i];
            this.showImageCropperModal(file);

            // console.log("file : ", file);
            // const fabricCanvas = this.fabricCanvas;
            // const canvas = this.$refs.canvas as HTMLCanvasElement;
            // if (fabricCanvas != null && canvas != null) {
            //   const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
            //
            //   const reader = new FileReader();
            //   reader.onload = function (e: any) {
            //     const img = new Image();
            //     img.onload = function () {
            //       fabricCanvas.width = img.width;
            //       fabricCanvas.height = img.height;
            //       ctx.drawImage(img, 0, 0);
            //     };
            //     img.src = e.target.result;
            //   };
            //   reader.readAsDataURL(file);
            // }

            // const imageFile = (await core.utils.image.getImageBlob(file, 1280)) as any;
            // imageFile.index = this.imageIndex++;
            // imageFile.type = "upload";
            //
            // //console.log("new image : ", imageFile);
            // imageFileList.push(imageFile);
          } catch (e: any) {
            core.http.sendError(e);
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
          }
        }

        imageFileList.forEach((imageFile) => {
          this.imageList.push(imageFile);
        });

        this.changedImage = true;
        // this.scrollUpdatedPosition = ScrollPosition.BOTTOM;
        // console.log("addImage : ", this.$refs.addImage);
        if (this.$refs.addImage != null) {
          let count = 0;
          const moveScroll = () => {
            setTimeout(() => {
              //console.log("scroll");
              (this.$refs.addImage as any).$el.scrollIntoView(true);
              // this.setScrollPosition(ScrollPosition.BOTTOM);
              count++;
              if (count === 1) {
                moveScroll();
              }
            }, 200);
          };
          moveScroll();
        }

        (this.$refs.imageFile as any).value = "";
        core.loader.hide();
      }
    },
  },
});
