
import mixins from "vue-typed-mixins";
import store from "@/store";

export default mixins().extend({
  name: "TopToolbar",
  props: {
    color: {
      type: String,
      default: "white",
    },
    clazz: {
      type: String,
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({
    app: store.state.app,
  }),
  mounted() {
    this.$nextTick(() => {
      const vue = this.$refs.toolbar as any;
      if (vue != null) {
        // console.log(vue.$el.clientHeight);
        this.$emit("update:height", vue.$el.clientHeight);
      }
      document.documentElement.style.setProperty("--toolbar-height", `50px`);
    });
  },
  activated() {
    document.documentElement.style.setProperty("--toolbar-height", `50px`);
  },
  destroyed() {
    document.documentElement.style.setProperty("--toolbar-height", `0px`);
  },
  deactivated() {
    document.documentElement.style.setProperty("--toolbar-height", `0px`);
  },
});
