
import core from "@/core";
import UserService from "@/services/user/user.service";

import Constant from "@/store/constant";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";

import { localize } from "vee-validate";
import { UserModel } from "@/models/user/user.model";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    username: "아이디 ",
    email: "이메일 ",
    name: "이름 ",
    phone: "휴대전화 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "UserMeInfo",
  data: () => ({
    username: "",
    form: {
      email: "",
      name: "",
      phone: "",
    },
    formProcessing: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      const user = (await this.$store.getters["auth/user"]()) as UserModel;
      // 생성 후 호출
      this.username = user.username;
      this.form.email = user.email;
      this.form.name = user.name;
      this.form.phone = user.phone;
    });
  },
  watch: {
    "form.phone"() {
      this.form.phone = core.utils.format.hyphenPhone(this.form.phone);
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        core.loader.show();
        this.formProcessing = true;
        try {
          await UserService.update(this.form);
          await this.$store.getters["auth/user"](true);
          core.loader.hide();

          await core.alert.show({
            title: "알림",
            body: "회원정보가 수정되었습니다.",
          });
          await this.$router.back();
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
        this.formProcessing = false;
      }
    },
  },
});
