import core from "@/core";
import { TableRequest } from "@/types";

class CompanyService {
  register(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/company/me/register`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.userCompany);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  unregister() {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/company/me/unregister`)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  create(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/company/me`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.company);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`/api/company/me`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.company);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  // getMyCompany() {
  //   return new Promise((resolve: any, reject) => {
  //     core.http
  //       .getJson(`/api/company/me`, { ignoreAlertModal: true })
  //       .then((data: any) => {
  //         //console.log('result: ', data);
  //         resolve(data.company);
  //       })
  //       .catch((reason: any) => {
  //         //console.log('error: ', reason);
  //         reject(reason);
  //       });
  //   });
  // }

  getCompanyList() {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/company`, { ignoreAlertModal: true })
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.companyList);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getUserList(searchColumns?: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/company/me/users`, null, searchColumns)
        .then((data: any) => {
          //console.log('result: ', data);
          this.convertUserList(data.companyUserList);
          resolve(data.companyUserList);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  deleteUser(userId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/company/me/users/${userId}`, null)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  convertUserList(userList) {
    userList.forEach((user) => {
      user.text = `${user.name}`;
      if (
        user.company != null &&
        user.company.teamList != null &&
        user.company.teamList.length > 0
      ) {
        let more = "";
        user.company.teamList.forEach((team) => {
          more += team.category.name + ",";
        });
        more = more.substr(0, more.length - 1);

        user.text = `${user.name}(${more})`;
      }
    });
  }

  getUserTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/company/me/users/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  changeApproval(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`/api/company/me/approval`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.companyUser);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateUserCompanyRole(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .patchJson(`/api/company/me/role`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.companyUser);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateUserCompanyScheduleRole(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .patchJson(`/api/company/me/schedule-role`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.companyUser);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateUserCompanyAttendanceYn(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .patchJson(`/api/company/me/attendance-yn`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.companyUser);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
  updateUserCompanyCategory(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .patchJson(`/api/company/me/category`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.companyUser);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateUserCompanyOptions(params: object) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`/api/company/me/options`, strParam)
        .then((data: any) => {
          //console.log('result: ', data);
          resolve(data.companyUser);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new CompanyService();
