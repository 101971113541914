
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import { UpdateEventType } from "@/types";
import core from "@/core";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    listViewType: "목록 보기 종류",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "ScheduleListViewTypeModal",
  data: () => ({
    formProcessing: false,
    form: {
      listViewType: "",
    },
    items: [
      { id: "default", text: "기본" },
      { id: "user", text: "담당자" },
    ],
  }),
  created() {},
  mounted() {
    this.$nextTick(() => {
      const params = this.properties.params;
      this.form.listViewType = params.listViewType;
    });
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        this.close(UpdateEventType.CONFIRM, core.utils.deepCopy(this.form));
      }
    },
  },
});
