
import CompanyService from "@/services/company/company.service";
import core from "@/core";
import { ApprovalType } from "@/types";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";

export default mixins(MixinsPage).extend({
  name: "WaitingApproval",
  data: () => ({
    companyName: "",
    approvalType: "",
    auth: store.state.auth,
  }),
  mounted() {
    this.$nextTick(async () => {
      const user = await this.$store.getters["auth/user"]();

      if (user != null) {
        this.changedUser(user);
      }
    });
  },
  watch: {
    "auth.user"(user) {
      //console.log("changed user : ", user);
      if (user != null) {
        this.changedUser(user);
      } else {
        this.$router.push("/logout");
      }
    },
  },
  methods: {
    changedUser(user: UserModel) {
      if (user.company) {
        this.companyName = user.company.name;

        if (user.company.lastApproval) {
          const approvalType = user.company.lastApproval.type;
          this.approvalType = String(approvalType);
          if (approvalType == ApprovalType.ALLOW) {
            this.$router.push("/");
          }
        }
      }
    },
    async unregister() {
      const store = this.$store;
      const router = this.$router;
      const result = await core.alert.show({
        title: "확인",
        body: "업체 멤버 등록을 해제하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        core.loader.show();
        try {
          const result = await CompanyService.unregister();
          const user = await store.dispatch("auth/getUser", true);
          await router.push("/company");
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
  },
});
