
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import SupportAsService from "@/services/support/support-as.service";
import EstimateUtils from "@/models/estimate";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPage, MixinsTable, MixinsScroll, MixinsAppBarMenu).extend({
  name: "SupportAs",
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
  }),

  mounted() {
    this.initTable({
      service: SupportAsService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const query = this.$route.query;
      //console.log("query : ", query);
      if (this.isNotBlank(query.userId)) {
        this.table.request.searchColumns.userId = query.userId;
      }
      if (this.isNotBlank(query.status)) {
        this.table.request.searchColumns.status = query.status;
      }
      //console.log("searchColumns : ", this.table.request.searchColumns);
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
  },
  activated() {
    this.$nextTick(() => {
      const event = this.getPageUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (event.path.indexOf("search") > -1) {
            this.updateTableItem("id", event.item);
          } else {
            this.addTableItem("id", event.item);
          }
          this.setScrollPosition(ScrollPosition.TOP);
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown event : ", event);
        }
      }
    });
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "search") {
        this.$router.push({
          path: "/support/as/search",
        });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    statusToHtml(item) {
      const status = item.status;
      if (status === "RECEIPT") {
        return "<span class='red--text'>A/S 접수</span>";
      } else if (status === "RECEIPT_CONFIRM") {
        return "<span class='orange--text'>접수확인</span>";
      } else if (status === "CUSTOMER_ADVICE") {
        return "<span class='blue--text'>고객상담</span>";
      } else if (status === "FIXED_SCHEDULE") {
        return "<span class='blue--text'>일정확정</span>";
      } else if (status === "COMPLETE") {
        return "<span class='green--text'>완료</span>";
      } else if (status === "CANCEL") {
        return "취소";
      }
      return "알 수 없음";
    },
    showViewPage(id) {
      this.$router.push(`/support/as/${id}`);
    },
    titleToText(item) {
      return `[${item.category.name}] ${EstimateUtils.titleToHtml(item.estimate)}`;
    },
  },
});
