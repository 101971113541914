export class RouterBeforeTaskItem {
  readonly tag: string;
  private readonly _routerNext: any;
  private isDestroyed = false;

  constructor(tag: string, _routerNext?: any) {
    this.tag = tag;
    if (_routerNext != null) {
      this._routerNext = _routerNext;
    } else {
      this._routerNext = null;
    }
  }

  routerNext() {
    if (!this.isDestroyed && this._routerNext) {
      return this._routerNext();
    }
    return true;
  }

  destroyed() {
    this.isDestroyed = true;
  }
}

export class RouterBeforeTask {
  private pageList: RouterBeforeTaskItem[] = [];
  private modalList: RouterBeforeTaskItem[] = [];

  pushPage(task: RouterBeforeTaskItem) {
    this.pageList.push(task);
  }

  pushModal(task: RouterBeforeTaskItem) {
    this.pageList.push(task);
  }

  clear() {
    this.pageList = [];
    this.modalList = [];
  }

  // 라우터 진행 가능 여부 검사
  public isRouterNext() {
    if (!RouterBeforeTask.isRouterNextFromList(this.modalList)) {
      return false;
    }

    return RouterBeforeTask.isRouterNextFromList(this.pageList);
  }

  // 라우터 진행 가능 여부 검사
  private static isRouterNextFromList(list: RouterBeforeTaskItem[]) {
    const length = list.length;
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        const task = list.pop() as RouterBeforeTaskItem;
        const isRouterNext = task.routerNext();
        if (!isRouterNext) {
          console.log("진행불가 : ", task);
          list.push(task);
          return false;
        }
      }
    }
    return true;
  }
}
