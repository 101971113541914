import core from "@/core";

class ScheduleUserMemoService {
  create(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/schedule-user-memo`, strParams)
        .then((data: any) => {
          if (data.scheduleUserMemo != null) {
            resolve(data.scheduleUserMemo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/work/schedule-user-memo/${id}`, strParams)
        .then((data: any) => {
          if (data.scheduleUserMemo != null) {
            resolve(data.scheduleUserMemo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/schedule-user-memo/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  get(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule-user-memo/${id}`)
        .then((data: any) => {
          if (data.scheduleUserMemo != null) {
            resolve(data.scheduleUserMemo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getByDate(date: string) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/schedule-user-memo/date/${date}`, null)
        .then(async (data: any) => {
          if (data.scheduleUserMemoList != null) {
            resolve(data.scheduleUserMemoList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ScheduleUserMemoService();
