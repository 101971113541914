
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import SupportAsService from "@/services/support/support-as.service";
import EstimateService from "@/services/work/estimate.service";
import { UserModel } from "@/models/user/user.model";
import { AppBarMenuItem, EntityType, UpdateEventType } from "@/types";
import SupportAsStatusModal from "@/modals/support/SupportAsStatusModal.vue";
import CommentComponent, { Mode } from "@/components/comment/CommentComponent.vue";
import { ScheduleViewType } from "@/models/schedule";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import core from "@/core";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "SupportAsView",
  components: { SupportAsStatusModal, CommentComponent },
  data: () => ({
    title: "",
    id: 0,
    as: null as any,
    component: {
      commentEntity: {
        visible: false,
        mode: Mode.DEFAULT,
        type: EntityType.SUPPORT_AS,
        id: 0,
        idList: [],
        systemMessageList: [],
        reloadFlag: false,
        selectType: "",
      },
    },
    modal: {
      supportAsStatus: {
        visible: false,
      },
    },
    visible: {
      addAs: false,
    },
    myUser: {} as UserModel,
  }),

  created() {
    // 생성 후 호출
    const params = this.$route.params as any;
    this.id = params.id as number;
  },
  async mounted() {
    this.$nextTick(async () => {
      this.myUser = await this.$store.getters["auth/user"]();
      this.component.commentEntity.id = this.id;
      this.component.commentEntity.visible = true;
    });
  },
  activated() {
    this.$nextTick(async () => {
      this.init();
    });
  },
  watch: {
    title() {
      this.$store.dispatch("topToolbar/changeTitle", { title: this.title, ignoreCheck: true });
    },
    as(as) {
      if (as != null) {
        this.appBarChangeMenuVisible("receipt", as.status === "RECEIPT");
        this.appBarChangeMenuVisible("addAs", this.visible.addAs && as.status !== "CANCEL");
        this.appBarChangeMenuVisible(
          "cancelAs",
          as.status !== "CANCEL" && as.status !== "COMPLETE"
        );
      }
    },
  },
  methods: {
    async appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "receipt") {
        this.updateStatus("RECEIPT_CONFIRM");
      } else if (menu.id === "addAs") {
        this.showAddSchedulePage("as");
      } else if (menu.id === "cancelAs") {
        const result = await core.alert.show({
          title: "확인",
          body: "A/S 접수를 취소하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });

        if (result === "confirm") {
          this.updateStatus("CANCEL");
        }
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    async init() {
      try {
        const as = (this.as = (await SupportAsService.get(this.id)) as any);
        if (
          as.status === "RECEIPT" ||
          as.status === "RECEIPT_CONFIRM" ||
          as.status === "CUSTOMER_ADVICE"
        ) {
          this.visible.addAs = true;
        } else {
          this.visible.addAs = false;
        }
        //console.log("as : ", as);

        this.title = `${this.getTitle()}`;
        // this.title = support.category.name + " A/S " + this.statusToText(support);

        if (as.status === "RECEIPT" && this.isCompanyUserRole) {
          const scheduleList = (await EstimateService.getScheduleList(as.estimateId)) as any;
          const leaderTeamIdList = [] as any;
          if (this.myUser.company.teamList != null) {
            this.myUser.company.teamList.forEach((team) => {
              // console.log("team : ", team);
              if (team.role === "LEADER") {
                leaderTeamIdList.push(team.id);
              }
            });
          }
          let showAlert = false;
          //console.log("scheduleList : ", scheduleList);
          scheduleList.some((schedule) => {
            if (schedule.category.id === as.category.id) {
              if (schedule.userList != null) {
                schedule.userList.some((user) => {
                  if (user.id === this.myUser.id) {
                    showAlert = true;
                    return true;
                  }
                });
                if (showAlert) return showAlert;
              }

              if (schedule.teamId != null) {
                if (leaderTeamIdList.indexOf(schedule.teamId) > -1) {
                  showAlert = true;
                  return true;
                }
              }
            }
          });
          if (showAlert) {
            console.log("확인처리");
            await this.updateStatus("RECEIPT_CONFIRM");
          }
        }

        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } catch (e) {
        console.log(e);
        this.goBack();
      }
    },
    async updateStatus(status) {
      try {
        this.as = await SupportAsService.update(this.as.id, { status: status });
        console.log("as : ", this.as);

        if (
          this.as.status === "RECEIPT" ||
          this.as.status === "RECEIPT_CONFIRM" ||
          this.as.status === "CUSTOMER_ADVICE"
        ) {
          this.visible.addAs = true;
        } else {
          this.visible.addAs = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async showAddSchedulePage(type) {
      const estimate = this.as.estimate;
      const scheduleList = (await EstimateService.getScheduleList(estimate.id)) as any;
      let teamId = null as any;
      console.log("as : ", this.as);
      scheduleList.forEach((schedule) => {
        if (schedule.workType === "D" && schedule.category.id == this.as.category.id) {
          teamId = schedule.teamId;
        }
      });
      console.log("teamId : ", teamId);
      const query = {
        viewType: ScheduleViewType.ESTIMATE,
        estimateId: estimate.id,
        categoryId: this.as.category.id,
        teamId: teamId,
      } as any;
      this.$router.push({
        path: `/schedule/${type}`,
        query: query,
      });
    },
    showCopyCommentModal() {
      const component = this.component.commentEntity;
      component.mode = Mode.SELECT;
    },
    getTitle() {
      let title = "";
      if (this.as != null) {
        const estimate = this.as.estimate;
        if (estimate != null) {
          title = `${estimate.place.name}`;
          if (this.isNotBlank(estimate.dong)) {
            title += ` ${estimate.dong}동`;
          }
          if (this.isNotBlank(estimate.ho)) {
            title += ` ${estimate.ho}호`;
          }
        } else {
          title = "";
        }
      }
      return title;
    },
    showEstimatePage() {
      this.$router.push(`/estimate/${this.as.estimate.id}`);
    },
  },
});
