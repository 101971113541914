import core from "@/core";
import AbstractCoreService from "./abstract-core.service";
import { TableRequest } from "@/types";

abstract class AbstractTableService extends AbstractCoreService {
  getTable(params: TableRequest) {
    //console.log("this.url : ", this.url);
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`${this.url}/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default AbstractTableService;
