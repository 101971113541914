import _history from "../history";
import config from "../config";

function isDef(v: any) {
  return v !== undefined && v !== null;
}

function isAsyncPlaceholder(node: any) {
  return node.isComment && node.asyncFactory;
}

function getFirstComponentChild(children: any) {
  if (Array.isArray(children)) {
    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (isDef(c) && (isDef(c.componentOptions) || isAsyncPlaceholder(c))) {
        return c;
      }
    }
  }
}

const stack: any = [];

function getIndexByKey(key: any) {
  for (let index = 0; index < stack.length; index++) {
    if (stack[index].key === key) {
      return index;
    }
  }
  return -1;
}

const VuePageStack = (keyName: string) => {
  return {
    name: config.componentName,
    abstract: true,
    data() {
      return {};
    },
    props: {
      max: {
        type: [String, Number],
        default() {
          return "";
        },
      },
    },
    render() {
      //console.log("render");
      const $this = this as any;
      const slot = $this.$slots.default;
      const vnode = getFirstComponentChild(slot);
      if (!$this.$route.meta.pageStack) {
        return vnode;
      }

      if (!vnode) {
        //console.log("first component child");
        return vnode;
      }
      const key = $this.$route.query[keyName];
      if (key == null || key.length === 0) {
        console.log(`not found stackKey`);
        return vnode;
      }
      const index = getIndexByKey(key);
      if (index !== -1) {
        const lastIndex = stack.length - 1;
        if (index === lastIndex) {
          //console.log("last index");
          const _vnode = stack[index].vnode;
          if (_vnode.componentInstance.$route.meta.pageStack) {
            vnode.componentInstance = _vnode.componentInstance;
            stack[index] = null;
            stack.splice(index);
            stack.push({ key, vnode });
            // return vnode;
          }
        } else {
          const _vnode = stack[index].vnode;
          if (_vnode.componentInstance.$route.meta.pageStack) {
            vnode.componentInstance = stack[index].vnode.componentInstance;
          }
          // destroy the instances that will be spliced
          for (let i = index + 1; i < stack.length; i++) {
            stack[i].vnode.componentInstance.$destroy();
            stack[i] = null;
          }
          stack.splice(index + 1);
        }
      } else {
        if (_history.action === config.replaceName && stack.length > 0) {
          // destroy the instance
          stack[stack.length - 1].vnode.componentInstance.$destroy();
          stack[stack.length - 1] = null;
          stack.splice(stack.length - 1);
        } else {
          // 동일 페이지 존재할경우 destroy 시킴..
          for (let i = 0; i < stack.length; i++) {
            const _stack = stack[i];
            if (vnode.tag === _stack.vnode.tag) {
              //console.log("destroy tag : " + vnode.tag);
              _stack.vnode.componentInstance.$destroy();
              stack[i] = null;
              stack.splice(i, 1);
              i--;
            }
          }
        }
        stack.push({ key, vnode });
      }
      vnode.data.keepAlive = true;
      return vnode;
    },
  };
};

function getStack() {
  return stack;
}

function getPreStack(vm) {
  if (vm.$route.meta.pageStack) {
    if (stack.length > 1) {
      return stack[stack.length - 2];
    }
  } else {
    if (stack.length > 0) {
      return stack[stack.length - 1];
    }
  }
  return null;
}

function getPreStackInstance(vm) {
  const stack = getPreStack(vm);
  if (stack != null) return stack.vnode.componentInstance;
  return null;
}

export { VuePageStack, getIndexByKey, getStack, getPreStack, getPreStackInstance };
