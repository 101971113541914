export default [
  {
    textFillStyle: "#000",
    fillStyle: "#F99F98",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#F9D34B",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#9FC2E7",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#ABCE65",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#F7F7F7",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#B4E0E8",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#F9B55A",
    text: "",
  },
  {
    textFillStyle: "#000",
    fillStyle: "#C6A3DF",
    text: "",
  },
];
