
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import CategoryService from "@/services/work/category.service";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { cloneDeep } from "lodash";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    name: "품목명",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "CategoryAddEditModal",
  data: () => ({
    type: "",
    form: {
      name: "",
    },
  }),
  async created() {
    // 생성 후 호출
    const properties = this.properties;
    this.type = properties.params.type;
    if (this.type !== "add" && this.type !== "edit") {
      await this.notFound();
    }

    if (this.type === "edit") {
      if (properties.params.item == null || properties.params.item.id == null) {
        await this.notFound();
      }
    }
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      if (this.type === "edit") {
        const properties = this.properties;
        const item = properties.params.item;
        this.form.name = item.name;
      }
    });
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const form = cloneDeep(this.form);
          const params = this.properties.params;
          const type = this.type;
          let category = null as any;
          if (type === "add") {
            category = await CategoryService.create(form);
            console.log("create category : ", category);
          } else if (type === "edit") {
            category = await CategoryService.update(params.item.id, form);
            console.log("update category : ", category);
          } else {
            console.log("invalid type : ", type);
            return;
          }
          core.loader.hide();
          this.close(UpdateEventType.UPDATE, category);
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
      }
    },
    async deleteItem() {
      const item = this.properties.params.item;
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const result = await CategoryService.delete(item.id);
          console.log("result : ", result);
          core.loader.hide();
          this.close(UpdateEventType.DELETE, item);
        } catch (e) {
          console.log(e);
          this.cancel();
        }
      }
    },
  },
});
