import { CompanyRole } from "@/types";

export interface NavSubMenu {
  path: string;
  title: string;
  companyRoles: CompanyRole[];
  divider: boolean | undefined;
}

export interface NavMenuBadge {
  icon: string; // badge content 존재할때 icon
  color: string; // badge color
  content: string; // badge content
  emptyIcon: string; // badge content 비었을때 icon
}

export enum NavMenuType {
  DIVIDER = "divider",
  MENU = "menu",
}

export enum NavMenuId {
  schedule = "schedule",
  logout = "logout",
  notification = "notification",
  support = "support",
  supportAs = "supportAs",
}

// 네비게이션 메뉴
export interface NavMenu {
  id: NavMenuId | undefined;
  type: NavMenuType;

  title: string | undefined;

  // click function 존재할경우 path 무시함
  path: string | undefined;
  click: any;

  // badge 정보 있을 경우 무시함
  icon: string | undefined;
  badge: NavMenuBadge | undefined;

  // subMenuList 존재할경우 companyRoles 무시함
  companyRoles: CompanyRole[] | undefined;

  subMenuList: NavSubMenu[] | undefined;
}
