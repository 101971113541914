
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import EventService from "@/services/event/event.service";
import RouletteItems from "@/data/roulette-items";
import { cloneDeep } from "lodash";
import UpdateEvent from "@/models";
import RouletteComponent from "@/components/roulette/RouletteComponent.vue";

export default mixins(MixinsPageForm).extend({
  name: "EventRoulette",
  components: { RouletteComponent },

  data: () => ({
    loadingPrize: false,
    theWheel: null as any,
    modalPrize: false,
    wheelPower: 1,
    wheelSpinning: false,
    prizeName: "",
    WinWheelOptions: {
      textFontSize: 14,
      outterRadius: 410,
      innerRadius: 25,
      lineWidth: 8,
      animation: {
        type: "spinOngoing",
        duration: 0.5,
      },
    },
    segments: [] as any,
    processing: false,
    eventId: 0,
    categoryId: 0,
    event: null as any,
    eventCategory: null as any,
    component: {
      roulette: null as any,
    },
  }),
  mounted() {
    //console.log("mounted");
    this.$nextTick(async () => {
      // eventId : 14, categoryId : 1
      try {
        const query = this.$route.query;
        if (this.isBlank(query.eventId) || this.isBlank(query.categoryId)) {
          await this.notFound();
          return;
        }
        const eventId = Number(query.eventId);
        const categoryId = Number(query.categoryId);
        this.eventId = eventId;
        this.categoryId = categoryId;

        this.event = await EventService.get(eventId);
        //console.log("event : ", event);

        const eventCategory = (await EventService.getCategoryRoulette(eventId, categoryId)) as any;
        this.eventCategory = eventCategory;

        // console.log("eventCategory : ", eventCategory);
        const rouletteList = eventCategory.rouletteList;
        const segments = [] as any;
        rouletteList.forEach((roulette, index) => {
          const item = RouletteItems[index % RouletteItems.length];
          const params = cloneDeep(item) as any;
          params.text = roulette.item;
          params.data = roulette;
          segments.push(params);
        });
        this.segments = segments;

        const properties = {
          params: {
            showCategory: true,
            rouletteList: segments,
            eventCategory: eventCategory,
            eventId: eventCategory.eventId,
            categoryId: eventCategory.category.id,
            winner: null,
          },
          updateEvent: null as UpdateEvent | null,
        };
        this.component.roulette = properties;
      } catch (e) {
        console.log(e);
      }

      // this.initSpin();
    });
  },
});
