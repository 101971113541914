import { render, staticRenderFns } from "./ListItemParentComponent.vue?vue&type=template&id=0b7e2b44&"
import script from "./ListItemParentComponent.vue?vue&type=script&lang=ts&"
export * from "./ListItemParentComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports