import { IdModel, IdUpdateModel } from "@/models/core/type.model";
import { ImageFileModel } from "@/models/file/image-file.model";
import { UserCompanyModel } from "@/models/company/userCompanyModel";

export enum Role {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  USER = "USER",
}

export interface SimpleUserModel extends IdModel {
  username: string;
  email: string;
  name: string;
  phone: string;

  profile: ImageFileModel | null;
}

export interface UserModel extends IdUpdateModel {
  username: string;
  email: string;
  name: string;
  phone: string;
  roles: Role[];
  profile: ImageFileModel | null;
  // socialList: UserSocialModel[];
  company: UserCompanyModel;
}
