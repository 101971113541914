
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import { UpdateEventType } from "@/types";
import { FileDetailModel } from "@/models/file/file-detail.model";
import { cloneDeep } from "lodash";

export default mixins(MixinsModal).extend({
  name: "SelectFileDetailModal",
  props: {
    fileDetailList: {
      type: Array as () => FileDetailModel[],
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    imageMaxWidth: 0,
    imageMaxHeight: 0,
    itemList: [] as any,
    selectedItemList: [] as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const itemList = cloneDeep(this.fileDetailList) as any;
      itemList.forEach((item) => {
        item.selected = false;
      });
      this.itemList = itemList;
      this.windowResize();
    });
  },
  watch: {
    "app.size"() {
      this.windowResize();
    },
  },
  methods: {
    selectImage(item) {
      item.selected = !item.selected;
      if (item.selected) {
        this.selectedItemList.push(item);
      } else {
        const length = this.selectedItemList.length;
        for (let i = 0; i < length; i++) {
          const selectedItem = this.selectedItemList[i];
          if (selectedItem.id === item.id) {
            this.selectedItemList.splice(i, 1);
            break;
          }
        }
      }
    },
    async submit() {
      this.close(UpdateEventType.CONFIRM, this.selectedItemList);
    },
    windowResize() {
      const $el = (this.$refs.dialog as any).$el;
      if ($el != null) {
        this.imageMaxWidth = $el.clientWidth - 60;
        this.imageMaxHeight = this.app.size.height - 400;
      }
    },
  },
});
