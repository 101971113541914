
import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import PaymentNumberAddEditModal from "@/modals/settings/PaymentNumberAddEditModal.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "Payment",
  components: { PaymentNumberAddEditModal },
  data: () => ({
    itemList: [] as any,
    formProcessing: false,
    modal: {
      paymentNumber: {
        visible: false,
        params: {
          type: "",
          itemList: [] as any,
          item: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    watch: null as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(() => {
      this.watch = this.$store.watch(
        () => this.$store.state.auth.paymentSmsSettings,
        (paymentSmsSettings) => {
          core.loader.hide();
          this.itemList = paymentSmsSettings;
        }
      );
      core.loader.show();
      core.mobile.call({
        cmd: "checkSmsPermission",
      });
      core.mobile.call({
        cmd: "getPaymentSmsSettings",
      });
    });
  },
  destroyed() {
    if (this.watch) {
      this.watch();
    }
  },
  watch: {
    "modal.paymentNumber.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.paymentNumber);
      if (event?.result == UpdateEventType.CONFIRM) {
        this.saveItems();
      }
    },
  },
  methods: {
    getPhone(phone) {
      return core.utils.format.hyphenPhone(phone);
    },
    showAddModal() {
      const modal = this.modal.paymentNumber;
      modal.params.type = "add";
      modal.params.itemList = this.itemList;
      modal.visible = true;
    },
    showEditModal(item) {
      const modal = this.modal.paymentNumber;
      modal.params.type = "edit";
      modal.params.itemList = this.itemList;
      modal.params.item = item;
      modal.visible = true;
    },
    deleteItem(item) {
      for (let i = 0; i < this.itemList.length; i++) {
        const _item = this.itemList[i];
        if (_item.id === item.id) {
          this.itemList.splice(i, 1);
          break;
        }
      }
      this.saveItems();
    },
    saveItems() {
      core.mobile.call({
        cmd: "updatePaymentSmsSettings",
        value: this.itemList,
      });
    },
  },
});
