
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import CategoryService from "@/services/work/category.service";
import core from "@/core";
import { UpdateEventType } from "@/types";
import ScopeService from "@/services/work/scope.service";
import UpdateEvent from "@/models";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    category: "품목 ",
    workScope: "작업범위 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "MgmtWorkScopeAddEdit",
  data: () => ({
    title: "",
    type: "",
    id: 0,
    item: null as any,
    formProcessing: false,
    form: {
      categoryId: "",
      workScope: "",
    },
    selectedCategory: null as any,
    categoryList: [] as any,
    view: {
      categoryName: "",
    },
    modal: {
      category: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const params = this.$route.params as any;
      this.type = params.type;
      if (this.type === "add") {
        this.title = "작업범위 추가";
      } else if (this.type === "edit") {
        this.title = "작업범위 수정";
        const query = this.$route.query as any;
        this.id = Number(query.id);
        if (this.id <= 0) {
          this.notFound();
          return;
        }
      }
      await this.getCategoryList();

      const type = this.type;
      if (type === "edit") {
        const item = (await ScopeService.getScope(this.id)) as any;
        this.item = item;

        if (item.category != null) {
          this.form.categoryId = item.category.id;
          this.view.categoryName = item.category.name;
        }
        this.form.workScope = this.item.workScope;
      }
    });
  },
  watch: {
    type(val) {
      if (this.type === "add") {
        this.title = "작업범위 추가";
      } else if (this.type === "edit") {
        this.title = "작업범위 수정";
      }
    },
    title() {
      this.$store.dispatch("topToolbar/changeTitle", { title: this.title, ignoreCheck: true });
    },
  },
  methods: {
    async getCategoryList() {
      try {
        this.categoryList = await this.$store.getters["app/getCategoryList"]();
      } catch (e) {
        console.log(e);
      }
    },
    async deleteItem() {
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const result = await ScopeService.delete(this.id);
          console.log("result : ", result);
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
        this.goBack(UpdateEventType.DELETE, this.item);
      }
    },
    async submit() {
      console.log("submit");
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const type = this.type;

          const form = core.utils.deepCopy(this.form);
          let item = null as any;
          // const params = this.properties.params;
          if (type === "add") {
            item = await ScopeService.create(form);
          } else if (type === "edit") {
            item = await ScopeService.update(this.id, form);
          } else {
            console.log("invalid type : ", type);
            return;
          }
          core.loader.hide();
          this.goBack(UpdateEventType.UPDATE, item);
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
      } else {
        const errors = this.getValidateErrors();
        console.log("errors : ", errors);
      }
    },
  },
});
