
import mixins from "vue-typed-mixins";
import UpdateEvent from "@/models";
import MixinsPageForm from "@/mixins/page-form";
import PlaceService from "@/services/work/place.service";
import core from "@/core";
import SpecialPriceService from "@/services/work/special-price.service";
import { TableOrder } from "@/types";
import Constant from "@/store/constant";
import CompanyService from "@/services/company/company.service";
import SelectSearchModal from "@/modals/core/SelectSearchModal.vue";

export default mixins(MixinsPageForm).extend({
  name: "SpecialPriceLink",
  components: { SelectSearchModal },
  data: () => ({
    view: {
      placeName: "",
    },
    placeList: [] as any,
    // 선택된 아파트
    selectedPlace: null as any,
    placeTypeList: [] as any, // 선택된 아파트 타입 목록

    form: {
      placeId: "",
      type: "",
    },
    link: "",
    modal: {
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      placeType: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      placeUser: {
        visible: false,
        selected: null as any,
        list: [] as any,
        updateEvent: null as UpdateEvent | null,
      },
    },
    placeUserList: [] as any,
    userList: [] as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.placeList = await this.$store.getters["app/getPlaceList"]();
      // console.log("placeList : ", placeList);
      // this.placeList = await PlaceService.getPlaceList();
    });
  },
  watch: {
    "modal.placeUser.selected"(selected) {
      if (selected != null) {
        this.modal.placeUser.selected = null;
        this.addPlaceUser(selected.id);
      }
    },
    selectedPlace(val) {
      this.changedPlace(val);
    },
    "form.type"() {
      this.link = "";
    },
  },
  methods: {
    copyLink() {
      const textArea = document.createElement("textarea");
      textArea.value = this.link;
      // textarea 추가
      document.body.appendChild(textArea);

      textArea.select();
      document.execCommand("copy");

      // textarea 제거
      document.body.removeChild(textArea);
      core.alert.show({
        title: "알림",
        body: "링크가 클립보드로 복사되었습니다",
      });
    },
    async showSelectUserModal() {
      if (this.userList.length === 0) {
        this.userList = await CompanyService.getUserList();
      }
      const modal = this.modal.placeUser;
      modal.list = [];
      this.userList.forEach((user) => {
        let exists = false;
        this.placeUserList.some((placeUser) => {
          if (placeUser.user.id === user.id) {
            exists = true;
            return true;
          }
        });
        if (!exists) {
          modal.list.push({
            id: user.id,
            text: `${user.name}(${core.utils.format.hyphenPhone(user.phone)})`,
          });
        }
      });
      modal.visible = true;
    },
    async addPlaceUser(userId) {
      console.log("add place user : ", userId);
      const placeId = Number(this.form.placeId);
      const result = await PlaceService.createPlaceUser(placeId, userId);
      this.placeUserList = await PlaceService.getPlaceUserList(placeId);
    },
    async deletePlaceUser(userId) {
      console.log("remove place user : ", userId);
      const placeId = Number(this.form.placeId);
      const result = await PlaceService.deletePlaceUser(placeId, userId);
      this.placeUserList = await PlaceService.getPlaceUserList(placeId);
    },
    async changedPlace(place) {
      console.log("place : ", place);
      this.form.type = "";
      this.placeTypeList = [];

      if (place != null && typeof place === "object") {
        // if (place.typeList != null) {
        //   const typeList = [] as any;
        //   place.typeList.forEach((type) => {
        //     typeList.push({ id: type, name: type });
        //   });
        //   this.placeTypeList = typeList;
        // } else {
        //   this.placeTypeList = [];
        // }

        try {
          const placeTypeList = [] as any;
          const placeTypeMap = {};
          let specialPrice = null as any;
          const specialPriceTable = (await SpecialPriceService.getTable({
            draw: 0,
            start: 0,
            length: 100,
            orderColumnName: "id",
            order: TableOrder.DESC,
            searchColumns: { placeId: place.id },
          })) as any;
          if (specialPriceTable.recordsTotal === 1) {
            specialPrice = await SpecialPriceService.get(specialPriceTable.data[0].id);
          }

          if (specialPrice != null) {
            specialPrice.detailList.forEach((detail) => {
              // console.log("detail : ", detail);
              placeTypeMap[detail.type] = true;
            });
            Object.keys(placeTypeMap).forEach((key) => {
              placeTypeList.push({ id: key, name: key });
            });
          }
          //console.log("placeTypeList : ", placeTypeList);
          this.placeTypeList = placeTypeList;
        } catch (e) {
          console.log(e);
        }
        this.placeUserList = await PlaceService.getPlaceUserList(place.id);
      }
    },
    async submit() {
      //console.log("submit");
      if (await this.validate()) {
        this.link = core.utils.getUrlParams();

        const urlParams = core.http.objToUrlParams({
          placeId: this.form.placeId,
          type: this.form.type,
        });

        this.link = Constant.eventUrl + "/home?" + urlParams;
        //console.log("link : ", this.link);
      }
    },
  },
});
