import core from "@/core";

class StatusService {
  getStatusList() {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/status`)
        .then((data: any) => {
          const workStatusList = data.workStatusList;
          if (workStatusList != null) {
            resolve(workStatusList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getStatusListByScheduleId(scheduleId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/status`, null, { scheduleId: scheduleId })
        .then((data: any) => {
          const statusList = data.statusList;
          if (statusList != null) {
            resolve(statusList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getStatus(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/status/${id}`)
        .then((data: any) => {
          //console.log(data);
          const status = data.status;
          if (status != null) {
            resolve(status);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getStatusImageFile(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/status/${id}/image-file`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/status`, strParams)
        .then((data: any) => {
          if (data.status != null) {
            resolve(data.status);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/work/status/${id}`, strParams)
        .then((data: any) => {
          if (data.status != null) {
            resolve(data.status);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createImage(formData: FormData) {
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`/api/work/status/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data: any) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  sendSms(statusId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/work/status/${statusId}/sms`, "")
        .then((data: any) => {
          if (data.smsPushId != null) {
            resolve(data.smsPushId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new StatusService();
