
import Vue from "vue";
import { AppBarMenu } from "@/types";

export default Vue.extend({
  name: "AppBarProps",
  props: {
    prop: {
      type: Object,
      default: () => {
        return {
          closeNavigation: false,
          menuType: {
            type: String,
            default: "back", // back, navigation
          },
          appBarMenu: {
            type: Object as () => AppBarMenu,
          },
        };
      },
    },
  },
});
