
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import ScheduleTempService from "@/services/work/schedule.temp.service";
import core from "@/core";

export default mixins(MixinsPage).extend({
  name: "ScheduleTempView",
  data: () => ({
    fab: null,
    title: "",
    scroll: {
      enable: false,
    },
    menu: "",
    id: 0,
    schedule: {
      ready: false,
      category: {},
    } as any,
    view: {
      diffDays: 0,
    },
    updateEvent: null as any as UpdateEvent | null,
    viewEnable: {
      estimateButton: false,
    },
  }),

  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const params = this.$route.params as any;
      this.id = params.id as number;
      await this.redraw();
    });
  },
  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.result === UpdateEventType.UPDATE) {
        if (event.path.indexOf("/estimate") !== -1) {
          this.redraw(true);
        }
      }
    }
  },
  methods: {
    async redraw(ignoreAlertModal?: boolean) {
      try {
        if (ignoreAlertModal == null) ignoreAlertModal = false;
        core.loader.show();
        const schedule = (this.schedule = (await ScheduleTempService.get(this.id)) as any);
        this.schedule.ready = true;
        if (schedule.category != null) {
          this.title = `[${schedule.category.name}] [입주예정] ${schedule.title}`;
        } else {
          this.title = `${schedule.title}`;
        }

        this.viewEnable.estimateButton = schedule.estimateId != null;

        this.view.diffDays = core.date
          .instance(this.schedule.startAt)
          .diff(core.date.instance(), "days");
        if (this.view.diffDays === 0) {
          const diffHours = core.date
            .instance(this.schedule.startAt)
            .diff(core.date.instance(), "hours");
          if (diffHours > 0) {
            this.view.diffDays = 1;
          }
        }
      } catch (e) {
        core.loader.hide();
        if (!ignoreAlertModal) {
          await this.notFound();
        } else {
          this.goBack(UpdateEventType.RELOAD);
        }
      } finally {
        core.loader.hide();
      }
    },
    showEstimateViewPage(action?: string) {
      const params = {
        path: `/estimate/${this.schedule.estimateId}`,
      } as any;
      // console.log("this.schedule : ", this.schedule);
      if (action != null) {
        params.query = {
          action: action,
          categoryId: this.schedule.category.id,
        };
      }
      //console.log("params : ", params);
      this.$router.push(params);
    },
  },
});
