import { TableRequest } from "@/types";
import core from "@/core";

class PaymentSmsService {
  getTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/payment/sms/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
  get(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/payment/sms/${id}`, null)
        .then((data: any) => {
          if (data.paymentSms) {
            resolve(data.paymentSms);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
  updateStatus(id: number, status: string) {
    return new Promise((resolve: any, reject) => {
      const params = { status: status };
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/payment/sms/${id}/status`, strParams)
        .then((data: any) => {
          if (data.paymentSms) {
            resolve(data.paymentSms);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/payment/sms/${id}`, strParams)
        .then((data: any) => {
          if (data.paymentSms) {
            resolve(data.paymentSms);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new PaymentSmsService();
