interface MobileCall {
  cmd: string;
  value?: any;
}

export default class CoreMobile {
  call(params: MobileCall) {
    if (this.isApp()) {
      const platform = this.platform();
      if (platform === "android") {
        const strParam = JSON.stringify(params);
        //console.log("android call : ", strParam);
        (window as any).android.call(strParam);
      } else if (platform === "ios") {
        const strParam = JSON.stringify(params);
        //console.log("android call : ", strParam);
        (window as any).webkit.messageHandlers.call.postMessage(strParam);
      } else {
        console.log("not support platform. ", platform);
      }
    } else {
      console.log("is not mobile app");
    }
  }

  platform(): string {
    const userAgent = navigator.userAgent.toLowerCase();
    let platform = "";
    if (userAgent.indexOf("android") > -1) {
      platform = "android";
    } else if (
      userAgent.indexOf("iphone") > -1 ||
      userAgent.indexOf("ipad") > -1 ||
      userAgent.indexOf("ipod") > -1
    ) {
      // IOS
      platform = "ios";
    } else if (userAgent.indexOf("windows") > -1) {
      platform = "windows";
    } else {
      // 윈도우, 아이폰, 안드로이드 외
      platform = "other";
    }
    return platform;
  }

  isApp(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf("scc") > -1;
  }

  getSmsLink(phoneNumber: string, content: string): string {
    const platform = this.platform();
    if (platform === "android") {
      return "sms:" + phoneNumber + "?body=" + encodeURIComponent(content);
    } else if (platform === "ios") {
      return "sms:" + phoneNumber + "&body=" + encodeURIComponent(content);
    } else {
      return "";
    }
  }

  getGeoLink(params: {
    latitude: null | number;
    longitude: null | number;
    address: string;
  }): string {
    const platform = this.platform();
    if (platform === "android") {
      let location = "";
      if (params.latitude != null && params.longitude != null) {
        location = params.latitude + "," + params.longitude;
      }
      if (location != null) {
        return "geo:" + location + "?q=" + params.address;
      }
      return "geo:?q=" + params.address;
    } else if (platform === "ios") {
      let location = "";
      if (params.latitude != null && params.longitude != null) {
        location = `&rGoX=${params.longitude}&rGoY=${params.latitude}`;
      }
      return `tmap://search?name=${params.address}${location}`;
      //
      // if (location != null) {
      //   return "geo:" + location + "?q=" + params.address;
      // }
      // // if (location != null) {
      // //   return "maps:" + location + "?q=" + query;
      // // }
      // // return "maps:?q=" + query;
      // return "tmap:?q=" + query;
    } else {
      return "";
    }
  }

  getTelLink(phone) {
    const phoneNumber = phone.toString().replace(/[^0-9]/g, "");
    return "tel:" + phoneNumber;
  }

  getMailLink(email) {
    return "mailto:" + email;
  }
}
