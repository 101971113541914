
import core from "@/core";
import UserService from "@/services/user/user.service";

import Constant from "@/store/constant";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { localize } from "vee-validate";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    username: "아이디 ",
    password: "비밀번호 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "UserMePassword",
  data: () => ({
    form: {
      currentPassword: "",
      newPassword: "",
      newConfirmPassword: "",
    },
    formProcessing: false,
  }),
  created() {
    // 생성 후 호출
  },
  mounted() {
    // 페이지 로딩 후 호출
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        core.loader.show();
        this.formProcessing = true;
        try {
          await UserService.updatePassword(this.form);
          core.loader.hide();

          await core.alert.show({
            title: "알림",
            body: "비밀번호가 변경되었습니다.",
          });
          await this.$router.back();
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
        this.formProcessing = false;
      }
    },
  },
});
