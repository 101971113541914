
import mixins from "vue-typed-mixins";
import AppBarProps from "@/components/core/AppBarProps.vue";
import core from "@/core";
import store from "@/store";
import { LayoutType } from "@/router";
import Constant from "@/store/constant";
import MixinsCommon from "@/mixins/single/common";
import MenuListItemComponent from "@/components/core/MenuListItemComponent.vue";
import { NavMenuType } from "@/models/core/nav-menu.model";
import { AppBarMenuItem } from "@/types";

export default mixins(AppBarProps, MixinsCommon).extend({
  name: "AppBar",
  components: { MenuListItemComponent },
  data: () => ({
    app: store.state.app,
    auth: store.state.auth,
  }),
  computed: {
    LayoutType() {
      return LayoutType;
    },
    NavMenuType() {
      return NavMenuType;
    },
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
    Constant() {
      return Constant;
    },
    title() {
      return this.$store.getters["topToolbar/title"];
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  methods: {
    hasLayout(...paramLayoutTypes) {
      if (this.prop.layoutList != null) {
        let exists = false;
        paramLayoutTypes.some((paramLayoutType) => {
          (this.prop.layoutList as LayoutType[]).some((layoutType) => {
            if (paramLayoutType === layoutType) {
              if (layoutType === LayoutType.APP_BAR_NAV) {
                if (this.app.isMobileSize) {
                  exists = true;
                  return exists;
                }
              } else {
                exists = true;
                return exists;
              }
            }
          });
          if (exists) {
            return exists;
          }
        });
        return exists;
      }
      return false;
    },
    toggleMenu() {
      if (this.app.showNav == null) this.app.showNav = true;
      this.app.showNav = !this.app.showNav;
    },
    toggleAppBarMenu(menu: AppBarMenuItem) {
      menu.clickEvent = !menu.clickEvent;
    },
    goBack() {
      this.$router.back();
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});
