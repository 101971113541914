
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import core from "@/core";
import { UpdateEventType } from "@/types";
import MixinsTable from "@/mixins/single/table";
import store from "@/store";
import EventService from "@/services/event/event.service";

export default mixins(MixinsModal, MixinsTable).extend({
  name: "SelectEventModal",
  components: {},
  data: () => ({
    title: "이벤트 ",
    bodyMinHeight: "100px",
    app: store.state.app,
    table: {
      body: null as any,
      defaultColumns: [],
    },
    search: {
      keyword: "",
      submitKeyword: "",
      loading: false,
      readonly: false,
    },
    scroll: {
      enable: true,
      first: true,
      eventFunc: null as any,
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
      interval: null as any,
      intervalCount: 0,
      control: {
        scrollTop: 0,
        date: null as any as Date | null,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const scroll = this.scroll;
      const $el = this.$refs.body as any;
      this.table.body = $el;

      // 테이블 초기화
      this.initTable({
        service: EventService,
        serviceFunctionKey: "getTable",
        itemHeight: 110,
      });

      scroll.eventFunc = function () {
        scroll.scrollTop = $el.scrollTop;
        scroll.scrollHeight = $el.scrollHeight;
        scroll.clientHeight = $el.clientHeight;
        //console.log("scrollTop : ", $el.scrollTop);
      };

      $el.addEventListener("scroll", scroll.eventFunc);

      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
      //console.log("change scrollTop : ", scrollTop);
    },
  },
  destroyed() {
    //console.log("destroyed");
    const $el = this.$refs.body as any;
    if ($el != null) {
      $el.removeEventListener("scroll", this.scroll.eventFunc);
    }
  },
  methods: {
    statusToHtml(item) {
      if (item.status === "WAIT") {
        return "<span class='orange--text'>이벤트 대기</span>";
      } else if (item.status === "ACTIVE") {
        return "<span class='green--text'>이벤트 시작</span>";
      } else if (item.status === "END") {
        return "<span class='grey--text'>이벤트 종료</span>";
      }
      return "알 수 없음";
    },
    selectItem(item) {
      this.close(UpdateEventType.CONFIRM, item);
    },
    async submit() {
      //console.log("submit");
      const keyword = this.search.keyword.trim();
      this.search.submitKeyword = keyword;
      if (keyword.length > 0) {
        this.loadTable({ placeNameLike: keyword });
        //console.log("keyword : ", keyword);
      } else {
        this.loadTable({});
      }
    },
  },
});
