import core from "@/core";

class SpecialUnitPriceService {
  uploadImage(formData: FormData) {
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`/api/special-unit-price/upload-image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data: any) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSrcImageList(placeId: number) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/special-unit-price/${placeId}/src-images`)
        .then((data: any) => {
          if (typeof data === "object") {
            const item = data["srcImageList"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  addSrcImage(placeId: number, fileDetailId: number) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ fileDetailId: fileDetailId });
      core.http
        .postJson(`/api/special-unit-price/${placeId}/src-image`, strParams)
        .then((data: any) => {
          if (data.srcImage != null) {
            resolve(data.srcImage);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  deleteSrcImage(placeId: number, fileDetailId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/special-unit-price/${placeId}/src-image/${fileDetailId}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateImages(placeId: number, categoryId: number, type: string, srcImageIdList: number[]) {
    return new Promise((resolve: any, reject) => {
      const params = {
        placeId: placeId,
        categoryId: categoryId,
        type: type,
        srcImageIdList: srcImageIdList,
      };
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/special-unit-price/images`, strParams)
        .then((data: any) => {
          if (typeof data === "object") {
            const item = data["imageList"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getImageList(placeId: number, categoryId?: number, type?: string) {
    return new Promise((resolve, reject) => {
      const params = {
        placeId: placeId,
      } as any;

      if (categoryId != null) {
        params.categoryId = categoryId;
      }
      if (type != null) {
        params.type = type;
      }
      core.http
        .getJson(`/api/special-unit-price/images`, null, params)
        .then((data: any) => {
          if (typeof data === "object") {
            const item = data["imageList"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new SpecialUnitPriceService();
