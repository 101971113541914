
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    phone: "전화번호",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "PaymentNumberAddEditModal",
  data: () => ({
    type: "",
    form: {
      phone: "",
    },
  }),
  async created() {
    // 생성 후 호출
    const properties = this.properties;
    this.type = properties.params.type;
    if (this.type !== "add" && this.type !== "edit") {
      await this.notFound();
    }

    if (this.type === "edit") {
      if (properties.params.item == null || properties.params.item.id == null) {
        await this.notFound();
      }
    }
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      if (this.type === "edit") {
        const properties = this.properties;
        const item = properties.params.item;
        this.form.phone = item.phone;
      }
    });
  },
  watch: {
    "form.phone"(val) {
      this.form.phone = core.utils.format.hyphenPhone(val);
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const form = core.utils.deepCopy(this.form);
          const params = this.properties.params;
          const type = this.type;

          if (type === "add") {
            const itemList = params.itemList;
            let exists = false;
            const phone = form.phone.replaceAll("-", "");
            let latestIndex = 0;
            itemList.forEach((item) => {
              if (item.phone === phone) {
                exists = true;
              }
              if (latestIndex < item.id) {
                latestIndex = item.id;
              }
            });
            if (exists) {
              const observer = this.$refs.observer as any;
              const errors = observer.errors.phone;
              if (errors != null) {
                errors.push("중복된 번호가 존재합니다.");
              }
              return;
            }
            itemList.push({
              id: latestIndex + 1,
              phone: phone,
              date: core.date.instance().format("YYYY-MM-DD HH:mm:ss"),
            });
            //console.log("itemList: ", itemList);
          } else if (type === "edit") {
            const itemList = params.itemList;
            const orgItem = params.item;
            let exists = false;
            const phone = form.phone.replaceAll("-", "");
            itemList.forEach((item) => {
              if (item.phone === phone && item.id !== orgItem.id) {
                exists = true;
              }
            });
            if (exists) {
              const observer = this.$refs.observer as any;
              const errors = observer.errors.phone;
              if (errors != null) {
                errors.push("중복된 번호가 존재합니다.");
              }
              return;
            }
            orgItem.phone = phone;
            orgItem.date = core.date.instance().format("YYYY-MM-DD HH:mm:ss");
            //console.log("itemList: ", itemList);
          } else {
            console.log("invalid type : ", type);
            return;
          }
          this.close(UpdateEventType.CONFIRM);
        } catch (e) {
          this.errorSubmit(e);
        } finally {
          core.loader.hide();
        }
      }
    },
  },
});
