
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import EventAccessLogService from "@/services/event-access-log.service";
import { TableOrder } from "@/types";

export default mixins(MixinsPage).extend({
  name: "AccessLog",
  data: () => ({
    dataList: [] as any,
    placeMap: new Map(),
    date: "",
  }),
  mounted() {
    //console.log("mounted");
    this.$nextTick(async () => {
      const query = this.$route.query;
      if (this.isBlank(query.date)) {
        this.date = core.date.instance().format("YYYY-MM-DD");
      } else {
        this.date = core.date.instance(String(query.date)).format("YYYY-MM-DD");
      }

      const placeList = await this.$store.getters["app/getPlaceList"]();
      placeList.forEach((place) => {
        this.placeMap.set(place.id, place);
      });
      //console.log("placeList : ", placeList);
      // console.log("placeMap : ", this.placeMap);
    });
  },
  watch: {
    date(date) {
      //console.log("change date : ", date);
      if (this.isNotBlank(date)) {
        this.reloadEventAccessLog();
      }
    },
  },
  methods: {
    isNextDisabled() {
      const moment = core.date.instance(this.date);
      // console.log("moment : ", moment);
      const todayMoment = core.date.instance(core.date.instance().format("YYYY-MM-DD"));
      // console.log("todayMoment : ", todayMoment);
      const diffDay = todayMoment.diff(moment, "days");
      if (diffDay <= 0) return true;
      // console.log("diffDay : ", diffDay);
      return false;
    },
    async reloadEventAccessLog() {
      core.loader.show();
      const params = {
        draw: 0,
        start: 0,
        length: 10000,
        orderColumnName: "createdAt",
        order: TableOrder.DESC,
        searchColumns: {
          createdAtGreaterThanEqual: core.date.instance(this.date).format("YYYY-MM-DD"),
          createdAtLessThan: core.date.instance(this.date).add(1, "days").format("YYYY-MM-DD"),
        },
      };
      const response = (await EventAccessLogService.getTable(params)) as any;
      const map = new Map();
      response.data.forEach((data) => {
        const key = data.ipAddress + "_" + data.browserInfo;
        //console.log("key : ", key);
        map.set(key, data);
      });
      const dataList = [] as any;
      map.forEach((value, key) => {
        // console.log("key : ", key + ", value : ", value);
        const params = this.getUrlParams(value.referer);
        value.params = params;
        dataList.push(value);
      });
      this.dataList = dataList;
      core.loader.hide();
    },
    setToday() {
      this.date = core.date.instance().format("YYYY-MM-DD");
      this.replaceState();
    },
    prev() {
      this.date = core.date.instance(this.date).add(-1, "days").format("YYYY-MM-DD");
      this.replaceState();
    },
    next() {
      this.date = core.date.instance(this.date).add(1, "days").format("YYYY-MM-DD");
      this.replaceState();
    },
    replaceState() {
      const params = core.utils.getUrlParams();
      params.date = core.date.instance(this.date).format("YYYY-MM-DD");
      const url = this.$route.path + "?" + core.http.objToUrlParams(params);
      history.replaceState("", "", url);
    },
    getInfo(item) {
      let text = "";
      if (item.params) {
        const params = item.params;
        if (params.placeId) {
          const placeId = Number(params.placeId);
          // console.log("placeId : ", placeId);
          if (this.placeMap.has(placeId)) {
            const place = this.placeMap.get(placeId);
            //console.log("place : ", place);
            text += `${place.name}`;
          } else {
            console.log("not found place. id : ", placeId);
          }
        }

        if (params.type) {
          if (text.length > 0) {
            text += ", ";
          }
          text += params.type;
        }

        if (params.phone) {
          if (text.length > 0) {
            text += ", ";
          }
          text += core.utils.format.hyphenPhone(params.phone);
        }
      }

      return text;
    },
    getUrlParams(url): any {
      const pos = url.lastIndexOf("?");
      const strParams = url.substr(pos + 1, url.length);
      //console.log("strParams : ", strParams);
      const params = {};
      const items = strParams.split("&");
      items.forEach((item) => {
        const temp = item.split("=");
        params[temp[0]] = decodeURIComponent(temp[1]);
      });
      return params;
    },
  },
});
