
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import { CommentDisplayModel, CommentListItemProperties } from "@/models/comment/comment.model";
import UpdateEvent from "@/models";
import { CommentType, UpdateEventType } from "@/types";
import CommentListItemParentComponent from "@/components/comment/ListItemParentComponent.vue";

export default mixins(MixinsCommon).extend({
  name: "CommentListItemComponent",
  components: { CommentListItemParentComponent },
  computed: {
    CommentType() {
      return CommentType;
    },
  },

  props: {
    properties: {
      type: Object as () => CommentListItemProperties,
    },
    item: {
      type: Object as () => CommentDisplayModel,
      default: () => {
        return null as any;
      },
    },
  },
  methods: {
    toggleItem(item: CommentDisplayModel) {
      item.selected = !item.selected;
    },
    getChatClass(comment: CommentDisplayModel) {
      let content = "";

      if (comment.isSelfComment) {
        content += (content.length > 0 ? " " : "") + "self-comment";
      }
      if (comment.hasParent) {
        content += (content.length > 0 ? " " : "") + "chat-has-parent";
      }
      if (comment.hasChild) {
        content += (content.length > 0 ? " " : "") + "chat-has-child";
      }
      return content;
    },
    onViewImage(item: CommentDisplayModel) {
      if (item.type === CommentType.IMAGE && item.fileDetail) {
        this.properties.updateEvent = new UpdateEvent(UpdateEventType.EVENT, "component", {
          type: "viewImage",
          item: item,
        });
      } else if (item.type === CommentType.LINK && item.link && item.link.fileDetail) {
        this.properties.updateEvent = new UpdateEvent(UpdateEventType.EVENT, "component", {
          type: "viewImage",
          item: item,
        });
      }
    },
    deleteItem(item: CommentDisplayModel) {
      this.properties.updateEvent = new UpdateEvent(UpdateEventType.EVENT, "component", {
        type: "delete",
        item: item,
      });
    },
  },
});
