
import mixins from "vue-typed-mixins";
import MixinsComponent, { ComponentProperties } from "@/mixins/component";
import UpdateEvent from "@/models";
import EventService from "@/services/event/event.service";
import * as Winwheel from "@/plugins/roulette/Winwheel.js";
import FlowerFall from "@/plugins/roulette/flower-fall";
import store from "@/store";
import { UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";

export default mixins(MixinsComponent).extend({
  name: "RouletteComponent",
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {
            showCategory: false,
            rouletteList: [] as any,
            eventCategory: {} as any,
            eventId: 0,
            categoryId: 0,
            winner: null as any,
          } as any,
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  components: {},

  data: () => ({
    processing: false,
    loadingPrize: false,
    theWheel: null as any,
    wheelPower: 1,
    wheelSpinning: false,
    winWheelOptions: {
      canvasId: "roulette_",
      textFontSize: 14,
      outterRadius: 410,
      innerRadius: 25,
      lineWidth: 8,
      animation: {
        type: "spinOngoing",
        duration: 0.5,
      },
    },
    segments: [] as any,
    winner: null as any,
    app: store.state.app,
    flowerFallStart: false,
    flowerFallWidth: 310,
    flowerFallHeight: 460,
    rouletteWidth: 310,
    rouletteHeight: 310,
  }),
  watch: {
    "app.size"() {
      this.resizeWindow();
    },
  },
  created() {
    const params = this.properties.params;
    const eventId = params.eventId;
    const categoryId = params.categoryId;
    const id = eventId + "_" + categoryId;
    this.winWheelOptions.canvasId = "roulette_" + id;
    if (this.app.size.width < 440) {
      // 425 - 310
      this.rouletteWidth = this.app.size.width - 100;
      this.rouletteHeight = this.rouletteWidth;
      document.documentElement.style.setProperty("--roulette-width", `${this.rouletteWidth}px`);
    } else {
      this.rouletteWidth = 310;
      this.rouletteHeight = this.rouletteWidth;
      document.documentElement.style.setProperty("--roulette-width", `${this.rouletteWidth}px`);
    }
    this.resizeWindow();
  },
  mounted() {
    this.$nextTick(async () => {
      // console.log("mounted");
      //console.log("properties : ", this.properties);
      this.segments = this.properties.params.rouletteList;
      // console.log("segments : ", this.segments);

      this.initSpin();

      this.resizeWindow();

      const params = this.properties.params;
      const eventId = params.eventId;
      const categoryId = params.categoryId;

      if (params.winner != null) {
        //console.log("winner : ", params.winner);
        this.winner = params.winner;

        let num = 0;
        this.segments.some((segment, index) => {
          const roulette = segment.data;
          // console.log("segment : ", segment);
          if (roulette.id === this.winner.eventRoulette.id) {
            num = index + 1;
            return true;
          }
        });

        this.theWheel = new Winwheel.Winwheel({
          ...this.winWheelOptions,
          numSegments: this.segments.length,
          segments: this.segments,
          animation: {
            type: "spinToStop",
            duration: 0,
            spins: 0,
            callbackFinished: this.onFinishSpin,
          },
        });
        const stopAt = this.theWheel.getRandomForSegment(num);
        // Important thing is to set the stopAngle of the animation before stating the spin.<font></font>
        this.theWheel.animation.stopAngle = stopAt;
        this.theWheel.startAnimation();
      }
    });
  },
  methods: {
    resizeWindow() {
      if (this.flowerFallStart) return;
      this.$nextTick(() => {
        if (this.$refs.card != null) {
          const $el = (this.$refs.card as any).$el;
          //console.log("width : ", $el.clientWidth);
          this.flowerFallWidth = $el.clientWidth;
          this.flowerFallHeight = $el.clientHeight;
          //console.log("width : ", this.flowerFallWidth, ", height : ", this.flowerFallHeight);
        }
      });
    },
    async startSpin() {
      if (!this.wheelSpinning) {
        this.processing = true;

        const params = this.properties.params;
        //console.log("params: ", params);
        const form = {} as any;
        form.eventId = params.eventId;
        form.categoryId = params.categoryId;

        try {
          const winner = (await EventService.rouletteTest(params)) as any;
          this.properties.params.winner = winner;
          this.winner = winner;
          //console.log("winner : ", winner);
        } catch (e) {
          console.log(e);
          this.processing = false;
          return;
        }
        let num = 0;
        this.segments.some((segment, index) => {
          const roulette = segment.data;
          if (roulette.id === this.winner.eventRoulette.id) {
            num = index + 1;
            return true;
          }
        });
        //console.log("winner : ", winner);

        this.theWheel.startAnimation();
        this.wheelSpinning = true;
        this.theWheel = new Winwheel.Winwheel({
          ...this.winWheelOptions,
          numSegments: this.segments.length,
          segments: this.segments,
          animation: {
            type: "spinToStop",
            duration: 5,
            spins: 5,
            callbackFinished: this.onFinishSpin,
          },
        });

        const stopAt = this.theWheel.getRandomForSegment(num);
        // Important thing is to set the stopAngle of the animation before stating the spin.<font></font>
        this.theWheel.animation.stopAngle = stopAt;

        // example input prize number get from Backend
        // Important thing is to set the stopAngle of the animation before stating the spin.
        // const prizeNumber = Math.floor(Math.random() * this.segments.length); // or just get from Backend
        // const stopAt = (360 / this.segments.length) * prizeNumber - 360 / this.segments.length / 2; // center pin
        // // var stopAt = 360 / this.segments.length * prizeNumber - Math.floor(Math.random() * 60) //random location
        // this.theWheel.animation.stopAngle = stopAt;
        this.theWheel.startAnimation();
        this.wheelSpinning = false;
      }
    },
    resetWheel() {
      this.theWheel = new Winwheel.Winwheel({
        ...this.winWheelOptions,
        numSegments: this.segments.length,
        segments: this.segments,
      });

      if (this.wheelSpinning) {
        this.theWheel.stopAnimation(false); // Stop the animation, false as param so does not call callback function.
      }

      this.theWheel.rotationAngle = 0; // Re-set the wheel angle to 0 degrees.
      this.theWheel.draw(); // Call draw to render changes to the wheel.
      this.wheelSpinning = false; // Reset to false to power buttons and spin can be clicked again.
    },
    initSpin() {
      this.loadingPrize = true;
      this.resetWheel();
      this.loadingPrize = false;
    },
    onFinishSpin(indicatedSegment) {
      //console.log("indicatedSegment : ", indicatedSegment);
      this.processing = false;
      const winner = this.winner;
      //console.log("winner : ", winner);
      this.setPropsUpdateEvent(UpdateEventType.CONFIRM);
      if (!winner.already) {
        setTimeout(() => {
          this.resizeWindow();
          setTimeout(() => {
            this.startFlowerFall();
          }, 100);
        }, 100);
      }
    },
    startFlowerFall() {
      const flowerFall = new FlowerFall(this.$refs.flowerFall as HTMLCanvasElement);
      flowerFall.start();
      this.flowerFallStart = true;
      setTimeout(() => {
        flowerFall.stop();
        this.flowerFallStart = false;
      }, 5000);
    },
  },
});
