
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsStorage from "@/mixins/single/storage";
import MixinsTable from "@/mixins/single/table";
import ScheduleService from "@/services/work/schedule.service";
import core from "@/core";
import PlaceService from "@/services/work/place.service";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import store from "@/store";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import ScheduleUtils from "@/models/schedule";
import CategoryService from "@/services/work/category.service";
import EstimateService from "@/services/work/estimate.service";
import MixinsScroll from "@/mixins/single/scroll";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    placeId: "아파트 ",
    dong: "동 ",
    ho: "호 ",
    customerPhone: "휴대전화 ",
    customerName: "고객명 ",
    scheduleYn: "일정상태 ",
    contractStatus: "계약상태 ",
    paymentYn: "결제상태 ",
  },
});

export default mixins(MixinsPageForm, MixinsStorage, MixinsScroll, MixinsTable).extend({
  name: "ScheduleSearch",
  data: () => ({
    history: "search",
    storageItems: {
      type: "schedule-search",
    },
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    historyItems: [] as any,
    historyFilterItems: [] as any,
    submitSearchKeyword: null as string | null,
    searchKeyword: null as string | null,
    placeList: [] as any,
    categoryList: [] as any,
    filterCategoryList: [] as any,
    searchKeywordFocus: false,
    searchKeywordFocusTime: new Date().getTime(),
    searchType: "keyword", // keyword, detail
    preViewType: "",
    viewType: "history", // history, detail, result
    keywordPromise: {
      wait: null as any,
      resolve: null as any,
    },
    detailSearch: {
      placeName: "",
      placeId: "",
      dong: "",
      ho: "",
      customerPhoneLike: "",
      title: "",
    },
    defaultSearchColumns: {} as any, // 기본 검색 컬럼
    modal: {
      place: {
        visible: false,
      },
    },
  }),
  computed: {
    tableDataList() {
      return this.table.response.data;
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },

  mounted() {
    this.$nextTick(async () => {
      this.table.request.orderColumnName = "startAt";
      this.table.request.order = "desc";

      const urlParams = core.utils.getUrlParams();
      if (urlParams.teamId != null) {
        this.defaultSearchColumns.teamId = urlParams.teamId;
      }
      if (urlParams.categoryId != null) {
        this.defaultSearchColumns.categoryId = urlParams.categoryId;
      }

      await this.storageWait();
      const items = this.getStorageItems(this.history);
      if (items != null) {
        this.historyItems = items;
      } else {
        this.setStorageItem(this.history, this.historyItems);
      }

      this.placeList = await this.$store.getters["app/getPlaceList"]();
      this.categoryList = await this.$store.getters["app/getCategoryList"]();
      this.filterCategoryList = core.utils.deepCopy(this.categoryList);
      this.filterCategoryList.splice(0, 0, { name: "없음", id: "null" });

      // 테이블 초기화
      this.initTable({
        service: ScheduleService,
        serviceFunctionKey: "getTable",
        itemHeight: 48,
      });

      this.changedSearchKeyword(this.searchKeyword);
      this.keywordPromise.wait = new Promise((resolve: any, reject) => {
        this.keywordPromise.resolve = resolve;
      });

      const vm = this as any;
      const routerBeforeTask = store.state.app.routerBeforeTask;
      const routerBeforeTaskItem = new RouterBeforeTaskItem(vm.$vnode.tag, () => {
        //console.log("viewType : ", vm.viewType, ", searchType : ", this.searchType);
        if (vm.searchType === "detail") {
          if (vm.preViewType === "result") {
            vm.viewType = "result";
          } else {
            vm.searchType = "keyword";
          }
          return false;
        }
        return true;
      });
      routerBeforeTask.pushPage(routerBeforeTaskItem);
    });
  },
  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      this.updatePreVmEvent(event.result, event.item);
      this.clearTable();
      this.reloadTable();
    }
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    searchKeyword(val) {
      this.changedSearchKeyword(val);
      //console.log("changed searchKeyword");
      this.keywordPromise.resolve();
    },
    "detailSearch.customerPhoneLike"(val, prev) {
      this.detailSearch.customerPhoneLike = core.utils.format.hyphenPhone(val);
    },
    searchType(type) {
      if (type === "keyword") {
        this.viewType = "history";
      } else if (type === "detail") {
        this.viewType = "detail";
      } else {
        console.log("Unknown Type : ", type);
      }
    },
    viewType(val, prev) {
      this.preViewType = prev;

      // viewType 변경시, 스크롤바 위로 이동
      const $el = this.$refs.body as any;
      if ($el != null) {
        $el.scrollTop = 0;
      }
    },
    "detailSearch.placeName"(val) {
      if (this.isBlank(val)) {
        this.detailSearch.placeId = "";
      }
    },
  },
  methods: {
    getScheduleDate(item: any) {
      return ScheduleUtils.getScheduleDate(item);
    },
    getHistoryDate(item: any) {
      return this.dateElapsedTimeFormat(
        core.date.instance(new Date(item.date)).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    showViewPage(item: any) {
      const query = {} as any;

      if (this.isNotBlank(this.$route.query.viewType)) {
        query.viewType = this.$route.query.viewType;
      }

      if (this.isNotBlank(item.estimateId)) {
        query.estimateId = item.estimateId;
      }

      if (item.category != null) {
        query.categoryId = item.category.id;
      }

      if (this.isNotBlank(item.teamId)) {
        query.teamId = item.teamId;
      }

      this.$router.push({ path: `/schedule/${item.id}`, query: query });
    },
    scheduleText(yn: string) {
      if (yn === "Y") {
        return "<span class='green--text'>일정 확정</span>";
      } else {
        return "<span class='orange--text'>일정 미정</span>";
      }
    },
    contractStatusText(value: string) {
      if (value === "WAIT") return "<span class='red--text'>계약 대기</span>";
      else if (value === "COMPLETE") return "<span class='green--text'>계약 완료</span>";
      else if (value === "CANCEL") return "<span class='black--text'>계약 취소</span>";
      return "알 수 없음";
    },
    isSearch() {
      return this.table.request.draw > 0;
    },
    isResultEmpty() {
      return this.table.request.draw > 0 && this.table.response.data.length == 0;
    },
    changedSearchKeyword(searchKeyword) {
      const isBlank = this.isBlank(searchKeyword);
      const filterItems = [] as any;
      this.historyItems.some((item: any) => {
        if (isBlank || item.keyword.indexOf(searchKeyword) > -1) {
          filterItems.push(item);
        }
        if (filterItems.length === 20) {
          return true;
        }
      });
      this.historyFilterItems = filterItems;
      if (filterItems.length > 0 && this.searchKeywordFocus) {
        // TODO : 이력정보 표시?
        //this.visibleHistory = true;
      }
    },
    focusIn() {
      this.searchKeywordFocus = true;
      this.searchKeywordFocusTime = new Date().getTime();
      this.viewType = "history";
    },
    focusOut() {
      if (new Date().getTime() - this.searchKeywordFocusTime > 500) {
        this.searchKeywordFocus = false;
      }
    },
    submit() {
      (this.$refs.searchKeyword as any).blur();

      const searchKeyword = this.searchKeyword as string;
      if (this.isNotBlank(searchKeyword)) {
        this.viewType = "result";

        this.submitSearchKeyword = searchKeyword;
        //console.log("submit searchKeyword : ", this.searchKeyword);

        // 키워드 스토리지 동일 항목 제외, 최대 100개 저장
        this.historyItems.some((item: any, index) => {
          if (item.keyword === searchKeyword) {
            this.historyItems.splice(index, 1);
            return true;
          }
        });

        const diffLength = this.historyItems.length - 99;
        if (diffLength > 0) {
          for (let i = 0; i < diffLength; i++) {
            this.historyItems.splice(this.historyItems.length - 1, 1);
          }
        }

        this.historyItems.unshift({
          keyword: searchKeyword,
          date: new Date().getTime(),
        });
        this.saveStorageItems();

        const searchColumns = this.keywordToSearchColumns(searchKeyword);
        this.clearTable();

        const defaultSearchColumns = this.defaultSearchColumns;
        // 기본 검색 컬럼 덮어쓰기
        for (const key of Object.keys(defaultSearchColumns)) {
          searchColumns[key] = defaultSearchColumns[key];
        }
        this.loadTable(searchColumns);
      } else {
        core.alert.show({
          title: "알림",
          body: "키워드를 입력하세요!",
        });
      }
    },
    async submitDetail() {
      if (await this.validate()) {
        const params = core.utils.deepCopy(this.detailSearch);
        delete params.placeName;
        for (const key of Object.keys(params)) {
          const value = params[key];
          if (this.isBlank(value)) {
            delete params[key];
          } else if (key === "customerPhoneLike") {
            params[key] = params[key].replaceAll("-", "");
          }
        }
        if (Object.keys(params).length === 0) {
          core.alert.show({
            title: "알림",
            body: "입력된 조건이 1개 이상이어야 합니다.",
          });
        } else {
          //console.log("params : ", params);
          this.viewType = "result";
          this.clearTable();
          this.loadTable(params);
        }
      }
    },
    async selectHistory(item: any) {
      //console.log("selectHistory : ", item);

      const changedSearchKeyword = this.searchKeyword !== item.keyword;

      if (changedSearchKeyword) {
        this.searchKeyword = item.keyword;
        await this.keywordPromise.wait;
      }

      if (this.submitSearchKeyword !== item.keyword) {
        if (!changedSearchKeyword) {
          this.changedSearchKeyword(this.searchKeyword);
        }
        this.submit();
      } else {
        this.changedSearchKeyword(this.searchKeyword);
        if (this.viewType === "history") {
          this.viewType = "result";
        }
      }
    },

    keywordToSearchColumns(searchKeyword: string) {
      const searchColumns = {} as any;

      // 1. 휴대전화번호 형식 분류
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const phoneResult = core.utils.format.textToPhoneNumber(searchKeyword) as any;
        // console.log("phoneResult : ", phoneResult);
        if (phoneResult != null) {
          console.log(`replace content ${searchKeyword} -> ${phoneResult.replaceContent}`);
          searchKeyword = phoneResult.replaceContent;

          if (phoneResult.list.length > 1) {
            searchColumns.customerPhoneList = phoneResult.list.join(",");
          } else {
            searchColumns.customerPhone = phoneResult.list[0];
          }
        }
      }

      // 2. 동호수 형식 분류, 101동 101호, 101-101, 101.101
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const dongHoResult = core.utils.format.textToDongHo(searchKeyword) as any;
        // console.log("dongHoResult : ", dongHoResult);
        if (dongHoResult != null) {
          console.log(`replace content ${searchKeyword} -> ${dongHoResult.replaceContent}`);
          searchKeyword = dongHoResult.replaceContent;
          if (dongHoResult.list.length > 1) {
            const dongList = [] as any;
            const hoList = [] as any;
            dongHoResult.list.forEach((dongHo) => {
              dongList.push(dongHo.dong);
              hoList.push(dongHo.ho);
            });
            searchColumns.dongList = dongList.list.join(",");
            searchColumns.hoList = hoList.list.join(",");
          } else {
            searchColumns.dong = dongHoResult.list[0].dong;
            searchColumns.hoStartLike = dongHoResult.list[0].ho;
          }
        }
      }

      // 3. 아파트 이름 검색
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const placeIdList = [] as any;
        const placeNameList = searchKeyword.split(" ");
        placeNameList.forEach((placeName) => {
          this.placeList.forEach((place) => {
            if (place.name.indexOf(placeName) > -1) {
              placeIdList.push(place.id);
              searchKeyword = searchKeyword.replaceAll(placeName, "").trim();
            }
          });
        });

        if (placeIdList.length > 0) {
          if (placeIdList.length > 1) {
            searchColumns.placeIdList = placeIdList.join(",");
          } else {
            searchColumns.placeId = placeIdList[0];
          }
        }
      }

      // 4. 품목 이름 검색
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const categoryIdList = [] as any;
        const categoryNameList = searchKeyword.split(" ");
        categoryNameList.forEach((name) => {
          this.categoryList.forEach((item) => {
            if (item.name.indexOf(name) > -1) {
              categoryIdList.push(item.id);
              searchKeyword = searchKeyword.replaceAll(name, "").trim();
            }
          });
        });

        if (categoryIdList.length > 0) {
          if (categoryIdList.length > 1) {
            searchColumns.categoryIdList = categoryIdList.join(",");
          } else {
            searchColumns.categoryId = categoryIdList[0];
          }
        }
      }

      // 5. 제목 검색
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const titleList = searchKeyword.split(" ");
        if (titleList.length > 0) {
          if (titleList.length > 1) {
            searchColumns.titleList = titleList.join(",");
          } else if (this.isNotBlank(titleList[0])) {
            searchColumns.title = titleList[0];
          }
        }
      }
      return searchColumns;
    },
  },
});
