import Vue from "vue";

export default Vue.extend({
  data: () => ({
    sessionItems: {
      loading: true,
      _promise: {
        loading: {} as any,
        loadingResolve: {} as any,
      },
      type: "", // 세션 타입
      items: {} as any,
    },
  }),
  async created() {
    const sessionItems = this.sessionItems;
    if (sessionItems.type == null || sessionItems.type.length === 0) {
      if ((this.$route.meta as any).sessionType == null) {
        console.log("not found meta, sessionType");
        sessionItems.loading = false;
        return;
      }
      sessionItems.type = (this.$route.meta as any).sessionType;
    }
    // console.log("session type : ", sessionItems.type);

    sessionItems._promise.loading = new Promise((_resolve) => {
      sessionItems._promise.loadingResolve = _resolve;
    });

    sessionItems.items = await this.$store.dispatch("storage/getSessionItem", sessionItems.type);

    sessionItems.loading = false;
    sessionItems._promise.loadingResolve();
  },
  methods: {
    sessionWait() {
      const sessionItems = this.sessionItems;
      if (!sessionItems.loading) {
        return new Promise((resolve: any, reject) => {
          resolve();
        });
      }
      return sessionItems._promise.loading;
    },
    saveSessionItems() {
      const vm = this as any;
      vm.$store.dispatch("storage/setSessionItem", {
        type: vm.sessionItems.type,
        items: vm.sessionItems.items,
      });
    },
    getSessionItems() {
      return this.sessionItems.items;
    },
    setSessionItem(key: string, value: any) {
      //console.log("addSessionItem key : " + key + ", value : ", value);
      const vm = this as any;
      const sessionItems = vm.sessionItems;
      const items = sessionItems.items;
      items[key] = value;
      this.saveSessionItems();
    },
    removeSessionItems(key?: string | null) {
      if (key == null) key = null;
      //console.log("removeSessionItem key : ", key);
      const vm = this as any;
      const sessionItems = vm.sessionItems;
      if (key == null) {
        sessionItems.items = {};
      } else {
        delete sessionItems.items[key];
      }
      this.saveSessionItems();
    },
  },
});
